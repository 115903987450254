import {afterNextRender,Component,effect,ElementRef,Inject,inject,OnInit,PLATFORM_ID,REQUEST,signal,viewChild} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {HeaderComponent} from './misc/components/header/header.component';
import {DrawerComponent} from './misc/components/drawer/drawer.component';
import {AppService} from './app.service';
import {DOCUMENT,isPlatformServer} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer,Meta,Title} from '@angular/platform-browser';
import {FooterComponent} from './misc/components/footer/footer.component';
import {LocalStorageService} from 'ngx-webstorage';

@Component({
	selector:'app-root',
	imports:[
		SharedModule,
		HeaderComponent,
		DrawerComponent,
		FooterComponent
	],
	templateUrl:'./app.component.html',
	styleUrl:'./app.component.scss'
})
export class AppComponent implements OnInit{
	private localStorageService=inject(LocalStorageService);
	private document=inject(DOCUMENT);
	appService=inject(AppService);
	matIconRegistry=inject(MatIconRegistry);
	domSanitizer=inject(DomSanitizer);
	topElement=viewChild<ElementRef>('topElement');
	theme=signal<string|null>(this.localStorageService.retrieve('theme-id') || null);
	
	constructor(
		title:Title,
		meta:Meta,
		@Inject(PLATFORM_ID) platformId:object,
		@Inject(REQUEST) request?:Request
	){
		title.setTitle('Bendita');
		meta.updateTag({content:'Bendita'},'property="og:title"');
		meta.updateTag(
			{
				content:(isPlatformServer(platformId) && request) ? request.url : this.document.location.href
			},
			'property="og:url"'
		);
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'name="description"');
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'property="og:description"');
		meta.updateTag({content:'bendita, grow, shop, semillas, plantas'},'name="keywords"');
		meta.updateTag({content:'https://www.benditagrow.com/icons/icon-512x512.png'},'property="og:image"');
		
		// effect(()=>{
		// 	const topElement=this.topElement();
		// 	const scrollToTop=this.appService.scrollToTop();
		// 	if(!isPlatformServer(platformId) && scrollToTop!=null && topElement?.nativeElement?.scrollIntoView) topElement.nativeElement.scrollIntoView();
		// });
		
		effect(()=>{
			this.document.documentElement.classList.remove('app-dark-theme');
			this.document.documentElement.classList.remove('app-light-theme');
			const theme=this.theme();
			if(theme){
				this.localStorageService.store('theme-id',theme);
				this.document.documentElement.classList.add(theme);
			}
		});
		
		// effect(()=>{
		// 	const toggleDarkTheme=this.appService.toggleDarkTheme();
		// 	if(!isPlatformServer(platformId)) this.toggleDarkTheme();
		// });
		
		afterNextRender(()=>{
			this.document.defaultView?.scrollTo(0,0);
		});
	}
	
	ngOnInit():void{
		this.matIconRegistry.addSvgIcon('app-facebook',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-facebook.svg'));
		this.matIconRegistry.addSvgIcon('app-twitter',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-twitter.svg'));
		this.matIconRegistry.addSvgIcon('app-instagram',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-instagram.svg'));
		this.matIconRegistry.addSvgIcon('app-whatsapp',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-whatsapp.svg'));
		
	}
	
	toggleDarkTheme():void{
		switch(this.theme()){
			case 'app-light-theme':
				this.theme.set('app-dark-theme');
				break;
			case 'app-dark-theme':
				this.theme.set('app-light-theme');
				break;
			case null:
				this.theme.set(this.document.defaultView?.matchMedia('(prefers-color-scheme: dark)').matches ? 'app-light-theme' : 'app-dark-theme');
				break;
		}
	}
	
}
