import {Injectable} from '@angular/core';
import {Apollo,gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {GetItemsRequestDto} from '../common/dto/get-items-request.dto';
import {GetItemRequestDto} from '../common/dto/get-item-request.dto';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn:'root'
})
export class BlogService{
	private readonly apollo:Apollo;
	
	constructor(apollo:Apollo){
		this.apollo=apollo;
		
	}
	
	getBlogPosts(variables:GetItemsRequestDto):Observable<any[]>{
		return this.apollo
		.query({
			variables,
			query:gql`
          query getBlogPosts($limit: Float!,$skip: Float!){
              getBlogPosts(limit: $limit, skip: $skip) {
                  createdAt
									updatedAt
                  id
                  title
                  cover {
                      large
                      small
                  }
                  intro
									author
              }
          }
			`
		}).pipe(map((result:any):any[]=>result.data?.getBlogPosts));
	}
	
	getBlogPost(variables:GetItemRequestDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`
          query getBlogPost($id: String!){
              getBlogPost(id: $id) {
                  createdAt
                  updatedAt
                  id
                  title
                  cover {
                      large
                      small
                  }
                  intro
									content
									author
									keywordsSeo
									introSeo
									titleSeo
              }
          }
			`
		}).pipe(map((result:any):any=>result.data?.getBlogPost));
	}
	
}
