import * as Types from '../../common/graphql/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Float']['input'];
  skip: Types.Scalars['Float']['input'];
  sortOrder: Types.Scalars['Float']['input'];
  category?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter: Types.GetProductsFilter;
  sortFactor: Types.GetProductsSortFactor;
}>;


export type GetProductsQuery = { __typename?: 'Query', getProducts: Array<{ __typename?: 'Product', createdAt: any, id: string, name: string, price: number, quantity: number, slug: string, updatedAt: any, discountPercent?: number | null, hidden: boolean, deletedAt?: any | null, pictures: Array<{ __typename?: 'ProductPicture', large: string, small: string }> }> };

export const GetProductsDocument = gql`
    query getProducts($limit: Float!, $skip: Float!, $sortOrder: Float!, $category: String, $filter: GetProductsFilter!, $sortFactor: GetProductsSortFactor!) {
  getProducts(
    sortFactor: $sortFactor
    sortOrder: $sortOrder
    category: $category
    filter: $filter
    limit: $limit
    skip: $skip
  ) {
    createdAt
    id
    name
    pictures {
      large
      small
    }
    price
    quantity
    slug
    updatedAt
    discountPercent
    hidden
    deletedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsGQL extends Apollo.Query<GetProductsQuery, GetProductsQueryVariables> {
    document = GetProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }