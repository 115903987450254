import {Injectable} from '@angular/core';
import {AppService} from '../app.service';
import {Apollo,gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {UpdateCartItemRequestDto} from './dto/update-cart-item-request.dto';
import {DeleteCartItemRequestDto} from './dto/delete-cart-item-request.dto';

@Injectable({
	providedIn:'root'
})
export class CartService{
	appService:AppService;
	private readonly apollo:Apollo;
	
	constructor(appService:AppService,apollo:Apollo){
		this.appService=appService;
		this.apollo=apollo;
		
	}
	
	updateCartItem(variables:UpdateCartItemRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation updateCartItem($id: String!,$product: String!,$quantity: Float!){
              updateCartItem(id: $id, product: $product, quantity: $quantity) {
                  createdAt
                  id
                  product {
											id
											quantity
                  }
                  quantity
              }
          }
			`
		});
	}
	
	deleteCartItem(variables:DeleteCartItemRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation deleteCartItem($id: String!,$product: String!){
              deleteCartItem(id: $id, product: $product) {
                  createdAt
                  id
                  product {
											id
                  }
                  quantity
              }
          }
			`
		});
	}
	
}
