import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {Subject,takeUntil} from 'rxjs';

@Component({
	selector:'bendita-products-list',
	templateUrl:'./products-list.component.html',
	styleUrls:['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit,OnDestroy{
	private readonly breakpointObserver:BreakpointObserver;
	@Input() productsInput?:any[];
	@Input() colsInput?:number;
	appService:AppService;
	destroyed:Subject<void>;
	
	constructor(breakpointObserver:BreakpointObserver,appService:AppService){
		this.breakpointObserver=breakpointObserver;
		this.appService=appService;
		this.destroyed=new Subject<void>();
		
	}
	
	ngOnInit():void{
		if(!this.productsInput){
			this.breakpointObserver
			.observe([
				Breakpoints.XSmall,
				Breakpoints.Small,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge
			])
			.pipe(takeUntil(this.destroyed))
			.subscribe((result:BreakpointState):void=>{
				if(this.appService.isScreenXSmall()) this.colsInput=1;
				else if(this.appService.isScreenSmall()) this.colsInput=3;
				else if(this.appService.isScreenMedium()) this.colsInput=3;
				else if(this.appService.isScreenLarge()) this.colsInput=4;
				else if(this.appService.isScreenXLarge()) this.colsInput=5;
				else this.colsInput=6;
			});
		}
	}
	
	
	ngOnDestroy():void{
		this.destroyed.next();
		this.destroyed.complete();
	}
	
}
