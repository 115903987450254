import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ResetPasswordRoutingModule} from './reset-password-routing.module';
import {ResetPasswordComponent} from './reset-password.component';
import {SharedModule} from '../shared/shared.module';
import {PasswordChangedPopupComponent} from './password-changed-popup/password-changed-popup.component';


@NgModule({
	declarations:[
		ResetPasswordComponent,
		PasswordChangedPopupComponent
	],
	imports:[
		CommonModule,
		ResetPasswordRoutingModule,
		SharedModule
	]
})
export class ResetPasswordModule{}
