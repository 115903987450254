<div></div>
<div mat-dialog-title fxLayout="row">
	<h1 fxFlex></h1>
	<div fxFlex="nogrow">
		<mat-icon (click)="close()" class="app-pointer">cancel</mat-icon>
	</div>
</div>
<div mat-dialog-content fxLayout="column" fxLayoutAlign="start center" style="max-height: initial !important;">
	<div fxFlex="nogrow">
	<qrcode
		[qrdata]="data.tokenOrderPickUp.result"
		[width]="qrWidth"
		errorCorrectionLevel="L"
		elementType="svg"
	></qrcode>
	</div>
</div>
<div mat-dialog-actions align="end">
	<button mat-button matRipple class="app-primary" (click)="close()">
		Cerrar
	</button>
</div>
