import {Component,OnDestroy,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProfileService} from '../profile.service';
import {BreakpointObserver,Breakpoints} from '@angular/cdk/layout';
import {AppService} from '../../app.service';
import {Subject,takeUntil} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector:'bendita-payment',
	templateUrl:'./payment.component.html',
	styleUrls:['./payment.component.scss']
})
export class PaymentComponent implements OnInit,OnDestroy{
	private matDialog:MatDialog;
	addresses?:any[];
	private readonly profileService:ProfileService;
	private readonly breakpointObserver:BreakpointObserver;
	gridCols:number;
	gridRowHeight:string;
	appService:AppService;
	destroyed:Subject<void>;
	private matSnackBar:MatSnackBar;
	
	constructor(
		breakpointObserver:BreakpointObserver,
		matDialog:MatDialog,
		profileService:ProfileService,
		appService:AppService,
		matSnackBar:MatSnackBar
	){
		this.matDialog=matDialog;
		this.profileService=profileService;
		this.addresses=undefined;
		this.breakpointObserver=breakpointObserver;
		this.gridCols=1;
		this.gridRowHeight='4:3';
		this.appService=appService;
		this.destroyed=new Subject<void>();
		this.matSnackBar=matSnackBar;
		
	}
	
	ngOnInit():void{
		this.breakpointObserver
		.observe([
			Breakpoints.XSmall,
			Breakpoints.Small,
			Breakpoints.Medium,
			Breakpoints.Large,
			Breakpoints.XLarge
		])
		.pipe(takeUntil(this.destroyed))
		.subscribe(result=>{
			if(this.appService.isScreenXSmall()){
				this.gridRowHeight='3:4';
				this.gridCols=1;
			}else if(this.appService.isScreenSmall()){
				this.gridRowHeight='1:1';
				this.gridCols=2;
			}else if(this.appService.isScreenMedium()){
				this.gridRowHeight='4:3';
				this.gridCols=2;
			}else if(this.appService.isScreenLarge()){
				this.gridRowHeight='4:3';
				this.gridCols=3;
			}else if(this.appService.isScreenXLarge()){
				this.gridRowHeight='4:3';
				this.gridCols=5;
			}else{
				this.gridRowHeight='4:3';
				this.gridCols=6;
			}
		});
		this.getCards();
	}
	
	ngOnDestroy(){
		this.destroyed.next();
		this.destroyed.complete();
	}
	
	getCards(){
	
	}
	
	addCard():void{
	
	}
	
	deleteCard(id:string):void{
	
	}
	
}
