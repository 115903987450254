/* eslint @typescript-eslint/explicit-function-return-type: ["off"] */
/* eslint @typescript-eslint/typedef: ["off"] */
import {NgModule} from '@angular/core';
import {Routes,RouterModule} from '@angular/router';

import {SelectivePreloadingStrategyService} from './common/selective-preloading-strategy.service';
import {ProfileGuard} from './profile/profile-guard.service';

const routes:Routes=[
	{path:'error',loadChildren:()=>import('./misc/error/error.module').then(m=>m.ErrorModule),pathMatch:'full'},
	{path:'contact',loadChildren:()=>import('./contact/contact.module').then(m=>m.ContactModule),data:{preload:true}},
	{path:'products',loadChildren:()=>import('./products/products.module').then(m=>m.ProductsModule),data:{preload:true}},
	{path:'envios',loadChildren:()=>import('./envios/envios.module').then(m=>m.EnviosModule),data:{preload:true}},
	{path:'profile',loadChildren:()=>import('./profile/profile.module').then(m=>m.ProfileModule),canLoad:[ProfileGuard]},
	{path:'cart',loadChildren:()=>import('./cart/cart.module').then(m=>m.CartModule),data:{preload:true}},
	{path:'check-out',loadChildren:()=>import('./check-out/check-out.module').then(m=>m.CheckOutModule),data:{preload:true}},
	{path:'reset-password',loadChildren:()=>import('./reset-password/reset-password.module').then(m=>m.ResetPasswordModule),data:{preload:true}},
	{path:'email-verified',loadChildren:()=>import('./email-verified/email-verified.module').then(m=>m.EmailVerifiedModule),data:{preload:true}},
	{path:'tracking',loadChildren:()=>import('./tracking/tracking.module').then(m=>m.TrackingModule),data:{preload:true}},
	{ path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
	{path:'**',loadChildren:()=>import('./misc/not-found/not-found.module').then(m=>m.NotFoundModule),data:{preload:true}}
];

@NgModule({
	imports:[RouterModule.forRoot(routes,{
		initialNavigation:'enabledNonBlocking',
		preloadingStrategy:SelectivePreloadingStrategyService
	})],
	exports:[RouterModule]
})
export class AppRoutingModule{}
