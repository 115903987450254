import {ApplicationConfig,isDevMode,inject,Provider,Injector,PLATFORM_ID,provideExperimentalZonelessChangeDetection,importProvidersFrom} from '@angular/core';
import {provideRouter,withInMemoryScrolling,withPreloading} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration,withI18nSupport,withIncrementalHydration} from '@angular/platform-browser';
import {provideServiceWorker} from '@angular/service-worker';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient,withFetch,withInterceptors,withJsonpSupport} from '@angular/common/http';
import {provideApollo} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {ApolloLink,InMemoryCache} from '@apollo/client/core';
import {getApp,initializeApp,provideFirebaseApp} from '@angular/fire/app';
import {getAuth,provideAuth} from '@angular/fire/auth';
import {getAnalytics,provideAnalytics,ScreenTrackingService,UserTrackingService} from '@angular/fire/analytics';
import {initializeAppCheck,ReCaptchaV3Provider,provideAppCheck,CustomProvider} from '@angular/fire/app-check';
import {getPerformance,providePerformance} from '@angular/fire/performance';
import {MAT_DATE_FORMATS,MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {environment} from '../environments/environment';
import {DATE_PIPE_DEFAULT_OPTIONS,isPlatformServer} from '@angular/common';
import {SelectivePreloadingStrategyService} from './common/selective-preloading-strategy.service';
import {provideNgxWebstorage,withLocalStorage,withNgxWebstorageConfig,withSessionStorage} from 'ngx-webstorage';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationService} from './authentication/authentication.service';
import {AppService} from './app.service';
import {authenticationInterceptor} from './authentication/authentication.interceptor';
import {ErrorResponse,onError} from '@apollo/client/link/error';
import {GraphQLFormattedError} from 'graphql/error';
import {getMessaging,provideMessaging} from '@angular/fire/messaging';
import {GALLERY_CONFIG,GalleryModule} from 'ng-gallery';
import {PhoneNumberUtilProvider} from './shared/phone-number-util.provider';
import {PaypalProvider} from './shared/paypal.provider';
import {GoogleMapsProvider} from './shared/google-maps.provider';
import {GooglePlacesProvider} from './shared/google-places.provider';
import {MercadoPagoProvider} from './shared/mercado-pago.provider';

const appDefaultOptions:Provider[]=[
	{
		provide:DATE_PIPE_DEFAULT_OPTIONS,
		useValue:{
			dateFormat:'d MMM y',
			timezone:'-0500'
		}
	},
	{provide:MAT_DATE_LOCALE,useValue:'es-CO'},
	{
		provide:MAT_DATE_FORMATS,
		useValue:{
			parse:{
				dateInput:['l','LL']
			},
			display:{
				dateInput:'L',
				monthYearLabel:'MMM YYYY',
				dateA11yLabel:'LL',
				monthYearA11yLabel:'MMMM YYYY'
			}
		}
	},
	{
		provide:MAT_DIALOG_DEFAULT_OPTIONS,
		useValue:{
			disableClose:false,
			hasBackdrop:true,
			autoFocus:'first-tabbable',
			closeOnNavigation:true,
			panelClass:'dialog-responsive',
			position:{top:'10px'}
		}
	},
	{
		provide:MAT_SNACK_BAR_DEFAULT_OPTIONS,
		useValue:{
			duration:3000,
			horizontalPosition:'center',
			verticalPosition:'top'
		}
	},
	{
		provide:GALLERY_CONFIG,
		useValue:{
			loop:true,
			thumbs:false,
			autoplay:true,
			autoplayInterval:10*1000,
			counter:false,
			bullets:false
		}
	}
];

export const appConfig:ApplicationConfig={
	providers:[
		provideExperimentalZonelessChangeDetection(),
		provideRouter(
			routes,
			withPreloading(SelectivePreloadingStrategyService),
			withInMemoryScrolling({
				scrollPositionRestoration:'enabled'
			})
		),
		provideClientHydration(
			withIncrementalHydration(),
			withI18nSupport()
		),
		provideServiceWorker(
			'ngsw-worker.js',
			{
				enabled:!isDevMode(),
				registrationStrategy:'registerWhenStable:30000'
			}
		),
		provideAnimationsAsync(),
		provideHttpClient(
			withFetch(),
			withJsonpSupport(),
			withInterceptors([authenticationInterceptor])
		),
		provideApollo(()=>{
			const httpLink=inject(HttpLink);
			const link:ApolloLink=httpLink.create({uri:`${environment.apiServer.url}/graphql`,withCredentials:true});
			// link=onError((error:ErrorResponse)=>{
			// 	if(error.graphQLErrors) error.graphQLErrors.forEach((value:GraphQLFormattedError,index:number,array:readonly GraphQLFormattedError[]):void=>console.error(`[GraphQL error]:`,error));
			// 	if(error.networkError) console.error('[Network error]:',error.networkError);
			// }).concat(link);
			return {
				link,
				cache:new InMemoryCache(),
				defaultOptions:{
					watchQuery:{
						fetchPolicy:'no-cache',
						errorPolicy:'all'
					},
					query:{
						fetchPolicy:'no-cache',
						errorPolicy:'all'
					},
					mutate:{
						fetchPolicy:'no-cache',
						errorPolicy:'none'
					}
				}
			};
		}),
		provideFirebaseApp(()=>initializeApp(environment.firebase)),
		provideAuth(()=>{
			const auth=getAuth();
			auth.languageCode='es';
			return auth;
		}),
		provideAnalytics(()=>getAnalytics()),
		ScreenTrackingService,
		UserTrackingService,
		provideAppCheck((injector:Injector)=>{
			if(isPlatformServer(injector.get(PLATFORM_ID))){
				const provider:CustomProvider=new CustomProvider({
					getToken:()=>new Promise((...args):void=>console.debug('args',args))
				});
				return initializeAppCheck(getApp(),{provider,isTokenAutoRefreshEnabled:false});
			}else{
				const provider:ReCaptchaV3Provider=new ReCaptchaV3Provider(environment.google.recaptcha3SiteKey);
				return initializeAppCheck(getApp(),{provider,isTokenAutoRefreshEnabled:true});
			}
		}),
		provideMessaging(()=>getMessaging()),
		providePerformance(()=>getPerformance()),
		CookieService,
		provideNgxWebstorage(
			withNgxWebstorageConfig({prefix:'app',caseSensitive:true,separator:'-'}),
			withLocalStorage(),
			withSessionStorage()
		),
		PhoneNumberUtilProvider,
		PaypalProvider,
		GoogleMapsProvider,
		GooglePlacesProvider,
		MercadoPagoProvider,
		AuthenticationService,
		AppService,
		importProvidersFrom(GalleryModule),
		...appDefaultOptions
	]
};
