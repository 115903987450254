import {loadMercadoPago} from '@mercadopago/sdk-js';
import {environment} from '../../environments/environment';
import {MERCADO_PAGO} from './constants';

export const mercadoPagoProvider={
	provide:MERCADO_PAGO,
	useFactory:async():Promise<any>=>{
		await loadMercadoPago();
		// @ts-ignore
		return new window.MercadoPago(environment.mercadopago.publicKey);
	}
};