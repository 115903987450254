import {Component,HostListener,Injector} from '@angular/core';
import {AppService} from '../../../app.service';
import {Location} from '@angular/common';

@Component({
	template:'',
	standalone:true
})
export class PopupComponent{
	private injector:Injector;
	location:Location;
	appService:AppService;
	currentUrl:string;
	
	constructor(injector:Injector){
		this.injector=injector;
		this.location=this.injector.get(Location);
		this.appService=this.injector.get(AppService);
		this.currentUrl=this.location.path();
	}
	
	// @HostListener('window:popstate',['$event'])
	// closeWithBackButton():void{
	// 	this.location
	// 	.subscribe(():void=>{
	// 		setTimeout(this.close.bind(this),100);
	// 	});
	// 	this.location.forward();
	// }
	
	@HostListener('window:keyup.esc') onKeyUp():void{
		this.close();
	}
	
	
	close():void{
	}
}
