<mat-card *ngIf="(order | async) as order">
	<mat-card-header style="justify-content: left !important;">
		<mat-card-title>
			GRACIAS POR TU COMPRA
		</mat-card-title>
		<mat-card-subtitle>
			<b>Referencia Orden: {{order.slug}}</b>
		</mat-card-subtitle>
		<mat-card-subtitle>
			<b>{{order.createdAt | date:'d MMM y, h:mm a'}}</b>
		</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
			<mat-card fxFlex="60" fxFlex.lt-md>
				<mat-card-header>
					<mat-card-title fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
						<mat-icon fxFlex="nogrow" class="app-36" [color]="order.status.id===ORDER_STATUS.Cancelled ? 'warn' : 'primary'" >{{ order.status.icon }}</mat-icon>
						<span fxFlex>{{ order.status.label }}</span>
					</mat-card-title>
					<mat-card-subtitle>
						{{order.status.description}}
					</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content fxLayout="column">
					<mat-card fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<mat-card-title>Actualizaciones del pedido</mat-card-title>
							<mat-card-subtitle>Recibirás actualizaciones del envío y entrega de tu pedido por correo electrónico.</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content fxLayout="column" fxLayoutGap="10px">
							<p fxFlex="noshrink">¿Necesitas ayuda? <a [routerLink]="['/contact']">Ponte en contacto con nosotros</a></p>
							<ng-container *ngIf="(appService.userObservable | async)!=null && (appService.userObservable | async).id===order.user?.id;else trackGuestTemplate">
								<a fxFlex="noshrink" [routerLink]="['/profile','orders']">
									<button mat-button matRipple class="app-primary">
										<mat-icon>shopping_basket</mat-icon>
										Ver mis ordenes
									</button>
								</a>
								<a fxFlex="noshrink" [routerLink]="['/profile','orders',order.slug]">
									<button mat-button matRipple class="app-primary">
										<mat-icon>shopping_bag</mat-icon>
										Ver estado de mi orden
									</button>
								</a>
							</ng-container>
							<ng-template #trackGuestTemplate>
								<a fxFlex="noshrink" [routerLink]="['/tracking',order.slug]" [queryParams]="{token:order.guestToken}">
									<button mat-button matRipple class="app-primary">
										<mat-icon>shopping_bag</mat-icon>
										Ver estado de mi orden
									</button>
								</a>
							</ng-template>
						</mat-card-content>
					</mat-card>
					<mat-card fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<div mat-card-avatar>
								<img fxFlex="36px" fxFlexAlign="center" src="/assets/images/{{order.paymentType.icon}}">
							</div>
							<mat-card-title style="white-space: break-spaces;word-break: break-word;">
								{{ order.paymentType.label }}
							</mat-card-title>
							<mat-card-subtitle *ngIf="order.paymentType.descriptionLong" style="white-space: break-spaces;word-break: break-word;">
								{{ order.paymentType.descriptionLong }}
							</mat-card-subtitle>
							<mat-card-subtitle *ngIf="order.paymentType.paymentAccount" style="white-space: break-spaces;word-break: break-word;">
								Enviar el pago a la cuenta: <b>{{order.paymentType.paymentAccount}}</b>
							</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content>
							<ng-container [ngSwitch]="order.paymentType.id">
								<ng-container *ngSwitchCase="PAYMENT_TYPE.PayPal">
									<ng-container *ngTemplateOutlet="paypalPaymentTemplate"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="PAYMENT_TYPE.MercadoPago">
									<ng-container *ngTemplateOutlet="mercadoPagoPaymentTemplate"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="PAYMENT_TYPE.Bitcoin">
									<ng-container *ngTemplateOutlet="bitcoinPaymentTemplate"></ng-container>
								</ng-container>
								
								<ng-template #paypalPaymentTemplate>
									<p *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment">Continua el proceso de pago usando el boton de PayPal</p>
									<p *ngIf="order.status.id===ORDER_STATUS.Packaging">Tu pago ha sido aprovado por Paypal</p>
								</ng-template>
								<ng-template #mercadoPagoPaymentTemplate>
									<p *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment">Continua el proceso de pago usando el boton de Mercado Pago</p>
									<p *ngIf="order.status.id===ORDER_STATUS.Packaging">Tu pago ha sido aprovado por Mercado Pago</p>
								</ng-template>
								<ng-template #bitcoinPaymentTemplate>
									<p>Ver detalle: <a href="https://checkout{{environment.production ? '' : '.dev'}}.opennode.com/{{order.openNodeCharge.id}}" target="_blank">{{order.openNodeCharge.id}}</a></p>
									<p *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment">Continua el proceso de pago usando el boton de OpenNode</p>
									<p *ngIf="order.status.id===ORDER_STATUS.Packaging">Tu pago ha sido aprovado por OpenNode</p>
								</ng-template>
							</ng-container>
						</mat-card-content>
					</mat-card>
					
					<mat-card fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<div mat-card-avatar>
								<mat-icon class="app-36">{{ order.shippingType.icon }}</mat-icon>
							</div>
							<mat-card-title>
								{{ order.shippingType.label }}
							</mat-card-title>
						</mat-card-header>
						<mat-card-content fxLayout="column">
							<ng-container *ngIf="order.shippingType.id===SHIPPING_TYPE.Delivery;else pickupTemplate">
								<div fxFlex="grow">
									<mat-card-title>Gratis</mat-card-title>
									<mat-card-subtitle>Envios locales aprox. 24 horas, envios nacionales aprox. 72 horas.</mat-card-subtitle>
								</div>
								<p fxFlex="nogrow">{{ order.shippingAddress.name }}</p>
								<p fxFlex="nogrow">{{ order.shippingAddress.line1 }}</p>
								<p *ngIf="order.shippingAddress.line2" fxFlex="nogrow">{{ order.shippingAddress.line2 }}</p>
								<p fxFlex="nogrow">{{ order.shippingAddress.city }}, {{ order.shippingAddress.state }}, {{ order.shippingAddress.country }}</p>
								<p fxFlex="nogrow">Cod. postal: {{ order.shippingAddress.zip }}</p>
								<p fxFlex="nogrow">Telefono:
									+{{ appService.phoneNumberUtil.parse(order.shippingAddress.phone).getCountryCode() }} {{ appService.phoneNumberUtil.parse(order.shippingAddress.phone).getNationalNumber() }}</p>
							</ng-container>
							<ng-template #pickupTemplate>
								<div fxFlex="grow">
									<mat-card-title>Gratis</mat-card-title>
									<mat-card-subtitle>Recojer en tienda, los productos seran agrupados en el punto de entrega para su recoleccion</mat-card-subtitle>
								</div>
								<p fxFlex="nogrow">{{ order.store.name }}</p>
								<p fxFlex="nogrow">{{ order.store.line1 }}<span *ngIf="order.store.line2">, {{ order.store.line2 }}</span></p>
								<p fxFlex="nogrow">{{ order.store.city }}, {{ order.store.state }}, {{ order.store.country }}</p>
								<p fxFlex="nogrow">Telefono:
									+{{ appService.phoneNumberUtil.parse(order.store.phone).getCountryCode() }} {{ appService.phoneNumberUtil.parse(order.store.phone).getNationalNumber() }}</p>
								<mat-divider style="position: unset;"></mat-divider>
								<h2 fxFlex="nogrow">Informacion del cliente</h2>
								<p fxFlex="nogrow">Nombre: {{ order.name }}</p>
								<p fxFlex="nogrow">Telefono: +{{ appService.phoneNumberUtil.parse(order.phone).getCountryCode() }} {{ appService.phoneNumberUtil.parse(order.phone).getNationalNumber() }}</p>
								<p fxFlex="nogrow">Email: {{ order.email }}</p>
							</ng-template>
						</mat-card-content>
					</mat-card>
				</mat-card-content>
			</mat-card>
			<mat-card fxFlex>
				<mat-card-content fxLayout="column">
					<div fxFlex fxLayout="column" fxLayoutGap="10px">
						<ng-container *ngFor="let item of order.items">
							<div fxFlex fxLayout="row" fxLayoutAlign="space-between">
								<div fxFlex="nogrow" style="height: 65px;width:80px;background-position: center; background-size: contain;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+item.product.picture+')'}" matBadge="{{item.quantity}}" matBadgeColor="primary" matBadgePosition="above after"></div>
								<span fxFlex fxFlexAlign="center">{{item.product.name}}</span>
								<div fxFlex="nogrow" fxLayout="column" fxFlexAlign="center">
									<ng-container *ngIf="item.product.discountPercent;else noDiscountTotalTemplate">
										<div fxFlex="nogrow" matBadge="{{item.product.discountPercent}}%" [matBadgeHidden]="!item.product.discountPercent" matBadgeColor="warn" matBadgePosition="above after" class="stretch-badge cart-item-badge">
											{{(item.quantity*(item.product.price-(item.product.price*(item.product.discountPercent/100)))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
										</div>
										<div fxFlex="nogrow"><span class="discount-chip">{{(item.quantity*item.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span></div>
									</ng-container>
									<ng-template #noDiscountTotalTemplate>
										{{(item.quantity*item.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
									</ng-template>
								</div>
							</div>
						</ng-container>
					</div>
					<mat-divider style="position: unset;margin:30px 0px"></mat-divider>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Subtotal</b></h3></div>
						<div fxFlex style="text-align: right"><h3>{{order.subtotal.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Descuento</b></h3></div>
						<div fxFlex style="text-align: right;" class="discount-total"><h3>-{{order.discount.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Envio</b></h3></div>
						<div fxFlex style="text-align: right;"><h3>{{order.shipping.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Total</b></h3></div>
						<div fxFlex style="text-align: right"><h3>{{order.total.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div *ngIf="order.paymentType.id===PAYMENT_TYPE.PayPal && order.status.id===ORDER_STATUS.AwaitingPayment" fxFlex>
						<div id="paypalPayment"></div>
					</div>
					<div *ngIf="order.paymentType.id===PAYMENT_TYPE.MercadoPago && order.status.id===ORDER_STATUS.AwaitingPayment" fxFlex>
						<div id="mercadoPagoPayment"></div>
					</div>
					<div *ngIf="order.paymentType.id===PAYMENT_TYPE.Bitcoin && order.status.id===ORDER_STATUS.AwaitingPayment" fxFlex>
						<a href="https://checkout{{environment.production ? '' : '.dev'}}.opennode.com/{{order.openNodeCharge.id}}" target="_blank">
							<img style="width:100%;" src="https://app.opennode.com/pay-with-bitcoin.svg"/>
						</a>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</mat-card-content>
	<mat-card-footer>

	</mat-card-footer>
</mat-card>