import {AfterViewInit,ChangeDetectorRef,Component,ElementRef,OnInit,ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {ProductsService} from './products.service';
import {BehaviorSubject,combineLatest,firstValueFrom,Observable,scan,startWith,Subject} from 'rxjs';
import {ActivatedRoute,Params,Router} from '@angular/router';
import {GetProductsSortFactor} from './dto/get-products.dto';
import {map,switchMap} from 'rxjs/operators';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';

@Component({
	selector:'bendita-products',
	templateUrl:'./products.component.html',
	styleUrls:['./products.component.scss']
})
export class ProductsComponent implements OnInit,AfterViewInit{
	title:string;
	private router:Router;
	private activatedRoute:ActivatedRoute;
	private readonly productsService:ProductsService;
	appService:AppService;
	category?:Observable<any>;
	private fetchProducts:BehaviorSubject<void>;
	public products?:Observable<any[]>;
	@ViewChild('autoFetchElement') autoFetchElement?:ElementRef;
	destroyed:Subject<void>;
	private readonly formBuilder:FormBuilder;
	form:FormGroup;
	GetProductsSortFactor:typeof GetProductsSortFactor;
	@ViewChild('productFilters') productFilters?:ElementRef;
	@ViewChild('preProductFilters') preProductFilters?:ElementRef;
	private readonly changeDetectorRef:ChangeDetectorRef;
	
	constructor(
		router:Router,
		activatedRoute:ActivatedRoute,
		productsService:ProductsService,
		appService:AppService,
		formBuilder:FormBuilder,
		changeDetectorRef:ChangeDetectorRef
	){
		this.title='Bendita - Productos';
		this.router=router;
		this.activatedRoute=activatedRoute;
		this.productsService=productsService;
		this.appService=appService;
		this.category=this.appService.productCategoriesObservable.pipe(
			switchMap((categories:any[]):Observable<any>=>this.activatedRoute.queryParams.pipe(
				map((params:Params):string=>categories.find((element:any):boolean=>(element.id===params['category'] || element.slug===params['category'])))
			))
		);
		this.fetchProducts=new BehaviorSubject<void>(undefined);
		this.products=undefined;
		this.destroyed=new Subject<void>();
		this.formBuilder=formBuilder;
		this.form=this.formBuilder.group({
			limit:[10,[Validators.required]],
			sort:[`${GetProductsSortFactor.CREATED_AT}*-1`,[Validators.required]],
			filter:this.formBuilder.group({
				discount:[false,[]],
				freeShipping:[false,[]]
			})
		});
		this.GetProductsSortFactor=GetProductsSortFactor;
		this.changeDetectorRef=changeDetectorRef;
		
		this.form.get('filter')?.get('discount')?.valueChanges.subscribe(
			(value:boolean):void=>{
				this.router.navigate([],{queryParams:{'discount':!value ? null : '1'},queryParamsHandling:'merge'});
			}
		);
		this.form.get('filter')?.get('freeShipping')?.valueChanges.subscribe(
			(value:boolean):void=>{
				this.router.navigate([],{queryParams:{'free-shipping':!value ? null : '1'},queryParamsHandling:'merge'});
			}
		);
		this.form.get('sort')?.valueChanges.subscribe(
			(value:boolean):void=>{
				this.router.navigate([],{queryParams:{'sort':!value ? null : value},queryParamsHandling:'merge'});
			}
		);
		
	}
	
	async ngOnInit():Promise<void>{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
		this.activatedRoute.queryParams.subscribe(
			(params:Params):void=>{
				console.log('params',params);
				this.appService.window.scrollTo(0,0);
			}
		);
		try{
			let params:Params=await firstValueFrom(this.activatedRoute.queryParams);
			let value:any={
				filter:{
					discount:Boolean(params['discount']),
					freeShipping:Boolean(params['free-shipping'])
				}
			};
			if(params['sort']){
				value.sort=params['sort'];
				if(parseInt(value.sort.split('*')[1])!==1 && parseInt(value.sort.split('*')[1])!==-1) throw new Error('no sort order');
				if(!(value.sort.split('*')[0] in GetProductsSortFactor)) throw new Error('no sort factor');
			}
			this.form.patchValue(value);
		}catch(error:any){
			console.error(error);
			this.router.navigate([],{queryParams:{}});
		}
		
		this.products=combineLatest([
			this.activatedRoute.queryParams.pipe(
				map((params:Params):string=>params['category'])
			),
			this.form.valueChanges.pipe(
				startWith(this.form.value)
			)
		])
		.pipe(
			switchMap(([category,formValue]:any[]):Observable<any[]>=>this.fetchProducts.pipe(
				switchMap(
					(currentValue2:void,index2:number):Observable<any[]>=>this.productsService.getProducts(
						{
							limit:formValue.limit,
							skip:index2*formValue.limit,
							category:category,
							sortFactor:formValue.sort.split('*')[0],
							sortOrder:parseInt(formValue.sort.split('*')[1]),
							filter:formValue.filter
						}
					)
				),
				scan((all:any[],page:any[]):any[]=>{
					// if(page.length===0 || page.length!==this.itemsPerPage) this.fetchProducts.complete();
					return all.concat(page);
				},[])
			))
		);
		
	}
	
	ngAfterViewInit():void{
		if(this.autoFetchElement){
			const observer:IntersectionObserver=new IntersectionObserver(
				(entries:IntersectionObserverEntry[]):void=>{
					for(let entry of entries){
						if(entry.isIntersecting){
							this.fetchMore();
							break;
						}
					}
				},
				{threshold:1}
			);
			setTimeout(():void=>{observer.observe(this.autoFetchElement?.nativeElement);},3000);
		}
		if(this.preProductFilters){
			const observer:IntersectionObserver=new IntersectionObserver(
				(entries:IntersectionObserverEntry[]):void=>{
					for(let entry of entries){
						if(entry.isIntersecting){
							this.productFilters?.nativeElement?.classList?.remove('products-filter-fixed');
							break;
						}else{
							this.productFilters?.nativeElement?.classList?.add('products-filter-fixed');
							break;
						}
					}
				},
				{threshold:1}
			);
			observer.observe(this.preProductFilters?.nativeElement);
		}
		this.changeDetectorRef.detectChanges();
	}
	
	fetchMore():void{
		this.fetchProducts.next();
	}
	
	productsFilterIsFixed():boolean{
		return this.productFilters?.nativeElement?.classList?.contains('products-filter-fixed');
	}
}
