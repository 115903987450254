import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResetPasswordRequestDto} from './dto/reset-password-request.dto';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn:'root'
})
export class ResetPasswordService{
	private httpClient:HttpClient;
	
	
	constructor(httpClient:HttpClient){
		this.httpClient=httpClient;
		
	}
	
	resetPassword(data:ResetPasswordRequestDto):Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/reset-password`,data,{withCredentials:true});
	}
	
}
