import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {BlogComponent} from './blog.component';
import {BlogPostDetailComponent} from './blog-post-detail/blog-post-detail.component';

const routes:Routes=[
	{path:'',component:BlogComponent},
	{path:':p1',component:BlogPostDetailComponent}
];

@NgModule({
	imports:[RouterModule.forChild(routes)],
	exports:[RouterModule]
})
export class BlogRoutingModule{}
