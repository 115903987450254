import {AfterViewInit,ChangeDetectorRef,Component,ElementRef,Inject,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CheckOutService} from './check-out.service';
import {BehaviorSubject,firstValueFrom,Observable,scan,Subject,takeUntil} from 'rxjs';
import {filter,map,switchMap} from 'rxjs/operators';
import {ProfileService} from '../profile/profile.service';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {AddAddressPopupComponent} from '../profile/add-address-popup/add-address-popup.component';
import {SnackMessageComponent} from '../shared/snack-message/snack-message.component';
import {LoginPopupComponent} from '../authentication/login-popup/login-popup.component';
import {SignupPopupComponent} from '../authentication/signup-popup/signup-popup.component';
import {CreateOrderRequestDto} from './dto/create-order-request.dto';
import {Params,Router} from '@angular/router';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {PHONE_NUMBER_UTIL} from '../shared/constants';
import {environment} from '../../environments/environment';
import {PAYMENT_TYPE} from '../common/enums/payment-type.enum';
import {SHIPPING_TYPE} from '../common/enums/shipping-type.enum';

@Component({
	selector:'bendita-check-out',
	templateUrl:'./check-out.component.html',
	styleUrls:['./check-out.component.scss']
})
export class CheckOutComponent implements OnInit,OnDestroy,AfterViewInit{
	SHIPPING_TYPE:typeof SHIPPING_TYPE;
	title:string;
	appService:AppService;
	private readonly checkOutService:CheckOutService;
	private matSnackBar:MatSnackBar;
	private matDialog:MatDialog;
	shippingTypes?:any[];
	paymentTypes?:any[];
	private readonly profileService:ProfileService;
	private readonly formBuilder:FormBuilder;
	form:FormGroup;
	submitButtonActive:boolean;
	private readonly changeDetectorRef:ChangeDetectorRef;
	destroyed:Subject<void>;
	private router:Router;
	@ViewChild('autoScrollElementUserAddresses') autoScrollElementUserAddresses?:ElementRef;
	private fetchAddresses:BehaviorSubject<void>;
	itemsPerPage:number;
	userAddresses?:Observable<any[]>;
	private resetAddresses:BehaviorSubject<void>;
	readonly phoneNumberUtil:PhoneNumberUtil;
	readonly environment:any;
	
	constructor(
		appService:AppService,
		checkOutService:CheckOutService,
		matSnackBar:MatSnackBar,
		matDialog:MatDialog,
		profileService:ProfileService,
		formBuilder:FormBuilder,
		changeDetectorRef:ChangeDetectorRef,
		router:Router,
		@Inject(PHONE_NUMBER_UTIL) phoneNumberUtil:PhoneNumberUtil,
	){
		this.SHIPPING_TYPE=SHIPPING_TYPE;
		this.title='Bendita - Confirmar orden';
		this.appService=appService;
		this.checkOutService=checkOutService;
		this.submitButtonActive=true;
		this.matSnackBar=matSnackBar;
		this.matDialog=matDialog;
		this.shippingTypes=undefined;
		this.paymentTypes=undefined;
		this.profileService=profileService;
		this.formBuilder=formBuilder;
		this.environment=environment;
		this.form=this.formBuilder.group({
			shippingType:[SHIPPING_TYPE.Delivery,[Validators.required]],
			paymentType:[undefined,[Validators.required]],
			address:this.formBuilder.group({
				name:[undefined,[Validators.required]],
				phone:[undefined,[Validators.required]],
				phoneCountryCode:[undefined,[Validators.required]],
				line1:[undefined,[Validators.required]],
				line2:[undefined,[]],
				city:[undefined,[Validators.required]],
				state:[undefined,[Validators.required]],
				country:[undefined,[Validators.required]],
				zip:[undefined,[Validators.required]],
				location:this.formBuilder.group({
					longitude:[undefined,[Validators.required]],
					latitude:[undefined,[Validators.required]]
				})
			}),
			name:[undefined,[Validators.required]],
			email:[undefined,[Validators.required]],
			phone:[undefined,[Validators.required]],
			phoneCountryCode:['+57',[Validators.required]],
			store:[undefined]
		});
		this.changeDetectorRef=changeDetectorRef;
		this.destroyed=new Subject<void>();
		this.router=router;
		this.fetchAddresses=new BehaviorSubject<void>(undefined);
		this.resetAddresses=new BehaviorSubject<void>(undefined);
		this.itemsPerPage=5;
		this.phoneNumberUtil=phoneNumberUtil;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
		this.getShippingTypes();
		this.getPaymentTypes();
		this.subscribeToUserChanges();
		
	}
	
	async ngAfterViewInit():Promise<void>{
		this.appService.userObservable
		.pipe(
			filter((item:any):boolean=>item!==null),
			takeUntil(this.destroyed)
		)
		.subscribe((user:any):void=>{
			if(user){
				setTimeout(():void=>{
					if(this.autoScrollElementUserAddresses){
						const observer:IntersectionObserver=new IntersectionObserver(
							(entries:IntersectionObserverEntry[]):void=>{
								for(let entry of entries){
									if(entry.isIntersecting){
										this.fetchMore();
										break;
									}
								}
							},
							{threshold:1}
						);
						setTimeout(():void=>{
							observer.observe(this.autoScrollElementUserAddresses?.nativeElement);
						},3000);
					}
					
				},1000);
				
			}
		});
		
		
		
	}
	
	ngOnDestroy():void{
		this.destroyed.next();
		this.destroyed.complete();
		
	}
	
	openLogin():void{
		this.matDialog.open(
			LoginPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
	}
	
	openSignup():void{
		this.matDialog.open(
			SignupPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
	}
	
	subscribeToUserChanges():void{
		this.appService.userObservable
		.pipe(
			filter((item:any):boolean=>item!==null),
			takeUntil(this.destroyed)
		)
		.subscribe((user:any):void=>{
			if(user){
				this.form.get('name')?.setValue(`${user.firstName} ${user.lastName}`);
				this.form.get('email')?.setValue(user.email);
				if(user.phone){
					this.form.get('phone')?.setValue(this.phoneNumberUtil.parseAndKeepRawInput(user.phone).getNationalNumber()?.toString());
					this.form.get('phoneCountryCode')?.setValue('+'+this.phoneNumberUtil.parseAndKeepRawInput(user.phone).getCountryCode()?.toString());
				}
				this.userAddresses=this.resetAddresses.pipe(
					switchMap(():Observable<any>=>this.fetchAddresses.pipe(
						switchMap((currentValue:void,index:number):Observable<any[]>=>this.profileService.getAddressesShipping({limit:this.itemsPerPage,skip:index*this.itemsPerPage})),
						scan((all:any[],page:any[]):any[]=>{
							return all.concat(page);
						},[])
					))
				);
				
			}
		});
	}
	
	fetchMore():void{
		this.fetchAddresses.next();
	}
	
	getShippingTypes():void{
		this.checkOutService.getShippingTypes()
		.subscribe((result:any):void=>{
			this.shippingTypes=result.data.getShippingTypes;
		});
	}
	
	getPaymentTypes():void{
		this.checkOutService.getPaymentTypes()
		.subscribe((result:any):void=>{
			this.paymentTypes=result.data.getPaymentTypes;
		});
	}
	
	getPaymentTypeSelected():string{
		return this.paymentTypes?.find((element:any):boolean=>element.id===this.form.get('paymentType')?.value).label;
	}
	
	getShippingTypeSelected():string{
		return this.shippingTypes?.find((element:any):boolean=>element.id===this.form.get('shippingType')?.value).label;
	}
	
	getCartSubtotal():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number,currentValue:any):number=>{
					return previousValue+(currentValue.quantity*currentValue.product.price);
				},0);
			})
		);
	}
	
	getCartDiscount():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number,currentValue:any):number=>{
					if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price*(currentValue.product.discountPercent/100)));
					else return previousValue;
				},0);
			})
		);
	}
	
	getCartShipping():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number):number=>{
					return previousValue+0;
				},0);
			})
		);
	}
	
	getCartTotal():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number,currentValue:any):number=>{
					if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price-(currentValue.product.price*(currentValue.product.discountPercent/100))));
					else return previousValue+(currentValue.quantity*currentValue.product.price);
				},0);
			})
		);
	}
	
	getAddress(event:any):void{
		if(!event.types.some((element:string):boolean=>(element==='premise' || element==='street_address'))){
			this.matSnackBar.openFromComponent(SnackMessageComponent,{
				data:{
					serverErrorInput:'Error: seleccione una direccion de envio valida'
				}
			});
			this.changeDetectorRef.detectChanges();
		}else{
			this.form.get('address')?.get('line1')?.setValue(event.name);
			this.form.get('address')?.get('city')?.setValue(event.address_components.find((element:any):boolean=>element.types[0]==='locality')?.long_name);
			this.form.get('address')?.get('state')?.setValue(event.address_components.find((element:any):boolean=>element.types[0]==='administrative_area_level_1')?.long_name);
			this.form.get('address')?.get('country')?.setValue(event.address_components.find((element:any):boolean=>element.types[0]==='country')?.long_name);
			this.form.get('address')?.get('zip')?.setValue(event.address_components.find((element:any):boolean=>element.types[0]==='postal_code')?.long_name);
			this.form.get('address')?.get('location')?.get('longitude')?.setValue(event.geometry.location.lng());
			this.form.get('address')?.get('location')?.get('latitude')?.setValue(event.geometry.location.lat());
			this.form.get('address')?.get('phoneCountryCode')?.setValue('+'+this.phoneNumberUtil.getCountryCodeForRegion(event.address_components.find((element:any):boolean=>element.types[0]==='country')?.short_name));
			this.changeDetectorRef.detectChanges();
		}
	}
	
	addAddress():void{
		let dialogRef:MatDialogRef<AddAddressPopupComponent>=this.matDialog.open(
			AddAddressPopupComponent,
			{
				panelClass:'dialog-responsive',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe(():void=>{
			this.resetAddresses.next();
		});
	}
	
	selectUserAddress(address:any):void{
		this.form.get('address')?.reset();
		this.form.get('address')?.get('name')?.setValue(address.name);
		if(address.phone){
			this.form.get('address')?.get('phone')?.setValue(this.phoneNumberUtil.parseAndKeepRawInput(address.phone).getNationalNumber()?.toString());
			this.form.get('address')?.get('phoneCountryCode')?.setValue('+'+this.phoneNumberUtil.parseAndKeepRawInput(address.phone).getCountryCode()?.toString());
		}
		this.form.get('address')?.get('line1')?.setValue(address.line1);
		if(address.line2) this.form.get('address')?.get('line2')?.setValue(address.line2);
		this.form.get('address')?.get('city')?.setValue(address.city);
		this.form.get('address')?.get('state')?.setValue(address.state);
		this.form.get('address')?.get('country')?.setValue(address.country);
		this.form.get('address')?.get('zip')?.setValue(address.zip);
		this.form.get('address')?.get('location')?.get('longitude')?.setValue(address.location.coordinates[0]);
		this.form.get('address')?.get('location')?.get('latitude')?.setValue(address.location.coordinates[1]);
		
	}
	
	async shippingTypeChanged():Promise<void>{
		this.form.removeControl('address');
		this.form.get('store')?.reset();
		if(this.form.get('shippingType')?.value===SHIPPING_TYPE.Delivery){
			this.form.addControl('address',this.formBuilder.group({
				name:[undefined,[Validators.required]],
				phone:[undefined,[Validators.required]],
				phoneCountryCode:[undefined,[Validators.required]],
				line1:[undefined,[Validators.required]],
				line2:[undefined,[]],
				city:[undefined,[Validators.required]],
				state:[undefined,[Validators.required]],
				country:[undefined,[Validators.required]],
				zip:[undefined,[Validators.required]],
				location:this.formBuilder.group({
					longitude:[undefined,[Validators.required]],
					latitude:[undefined,[Validators.required]]
				})
			}));
			
		}
	}
	
	async createOrder():Promise<void>{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive=false;
				let data:CreateOrderRequestDto={
					cart:(await firstValueFrom(this.appService.cartObservable)).id,
					paymentType:this.form.get('paymentType')?.value,
					shippingType:this.form.get('shippingType')?.value,
					name:this.form.get('name')?.value,
					email:this.form.get('email')?.value,
					phone:`${this.form.get('phoneCountryCode')?.value}${this.form.get('phone')?.value}`
				};
				if(this.form.get('address')){
					data.shippingAddress={
						...this.form.get('address')!.value,
						phone:`${this.form.get('address')!.value.phoneCountryCode}${this.form.get('address')!.value.phone}`,
						phoneCountryCode:undefined
					};
				}
				if(this.form.get('store')?.value) data.store=this.form.get('store')?.value;
				this.checkOutService.createOrder(data)
				.subscribe({
					next:(result:any):void=>{
						this.submitButtonActive=true;
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverResponseInput:'Por favor activa las ventanas emergentes para completar el pago'
							},
							duration:5000
						});
						this.appService.getCart();
						let queryParams:Params={};
						if(result.data.createOrder.guestToken) queryParams['token']=result.data.createOrder.guestToken;
						if(result.data?.createOrder){
							switch(result.data.createOrder.paymentType.id){
								case(PAYMENT_TYPE.PayPal):{
									this.router.navigate(['/check-out',result.data.createOrder.slug],{queryParams});
									break;
								}
								case(PAYMENT_TYPE.Bitcoin):{
									if(result.data.createOrder.openNodeCharge?.id){
										// this.appService.window.open(`https://checkout.opennode.com/${result.data.createOrder.openNodeCharge?.id}`,'_blank');
										this.router.navigate(['/check-out',result.data.createOrder.slug],{queryParams});
									}else{
										console.error('no opennode charge');
										this.router.navigate(['/check-out',result.data.createOrder.slug],{queryParams});
									}
									break;
								}
								default:{
									this.router.navigate(['/check-out',result.data.createOrder.slug],{queryParams});
								}
							}
						}
					},
					error:(error:any):void=>{
						this.submitButtonActive=true;
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverErrorInput:error
							}
						});
					}
				});
			}
		}
	}
}
