<mat-card>
	<mat-card-header style="justify-content: left !important;">
		<mat-card-title>
			MI CARRITO
		</mat-card-title>
		<mat-card-subtitle *ngIf="appService.cartObservable | async;">
			<b>{{(appService.cartObservable | async).itemsCount}} Producto{{(appService.cartObservable | async).itemsCount===1 ? '' : 's'}}</b>
		</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<ng-container *ngIf="appService.cartObservable | async">
			<ng-container *ngIf="(appService.cartObservable | async).items.length===0;else cartHasItems">
				<div fxLayout="column" fxLayoutAlign="center center">
					<h3 fxFlex="nogrow">Tu Bolsa de Compras está vacía. Agrega productos ahora</h3>
					<button fxFlex="nogrow" mat-raised-button color="primary" class="buy-button" [routerLink]="['/products']">
						Ir a comprar
					</button>
				</div>
			</ng-container>
			<ng-template #cartHasItems>
				<div fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
					<mat-card fxFlex="70" fxLayout="column">
						<div class="mat-table-container">
							<table fxFlexFill mat-table [dataSource]="(appService.cartObservable | async).items">
								<ng-container matColumnDef="product">
									<th mat-header-cell *matHeaderCellDef>Producto</th>
									<td mat-cell *matCellDef="let element">
										<div fxLayout="row">
											<a fxFlex="nogrow" [routerLink]="['/products',element.product.slug || element.product.id]">
												<div style="height: 85px;width:100px;background-position: center; background-size: contain;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+element.product.pictures[0].small+')'}"></div>
											</a>
											<div fxFlex="grow" fxLayout="column" fxLayoutAlign="start start">
												<a fxFlex="nogrow" [routerLink]="['/products',element.product.slug || element.product.id]" class="no-decoration">
													<h3>{{element.product.name}}</h3>
												</a>
												<ng-container *ngIf="element.product.discountPercent;else noDiscountTemplate">
													<div fxFlex matBadge="{{element.product.discountPercent}}%" [matBadgeHidden]="!element.product.discountPercent" matBadgeColor="warn" matBadgePosition="above after" class="stretch-badge cart-item-badge">
														{{(element.product.price-(element.product.price*(element.product.discountPercent/100))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
													</div>
													<div fxFlex><span class="discount-chip">{{element.product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span></div>
												</ng-container>
												<ng-template #noDiscountTemplate>
													<div fxFlex>{{element.product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</div>
												</ng-template>
											</div>
										</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="quantity">
									<th mat-header-cell *matHeaderCellDef style="text-align: center">Cantidad</th>
									<td mat-cell *matCellDef="let element" style="text-align: center" fxLayout="column" fxLayoutAlign="center center">
										<mat-form-field fxFlex="nogrow" class="quantity-form-field">
											<input #productQuantity matInput type="number" min="1" [value]="element.quantity" benditaInputGreaterThan="1" [max]="element.product.quantity" benditaInputLessThan="{{element.product.quantity}}" (change)="updateCartItem(element.product.id,productQuantity.value)" [disabled]="!submitButtonActive" aria-label="Quantity">
											<button mat-icon-button matSuffix (click)="productQuantity.stepUp(1);updateCartItem(element.product.id,productQuantity.value)" aria-label="Increase quantity" [disabled]="!submitButtonActive">
												<mat-icon>add</mat-icon>
											</button>
											<button mat-icon-button matPrefix (click)="productQuantity.stepDown(1);updateCartItem(element.product.id,productQuantity.value)" aria-label="Decrease quantity" [disabled]="!submitButtonActive">
												<mat-icon>remove</mat-icon>
											</button>
										</mat-form-field>
										<p fxFlex="nogrow" class="app-warn-color app-pointer" (click)="deleteCartItem(element.product.id)">Eliminar</p>
									</td>
								</ng-container>
								<ng-container matColumnDef="total">
									<th mat-header-cell *matHeaderCellDef>Total</th>
									<td mat-cell *matCellDef="let element">
										<div fxLayout="column">
											<ng-container *ngIf="element.product.discountPercent;else noDiscountTotalTemplate">
												<div fxFlex matBadge="{{element.product.discountPercent}}%" [matBadgeHidden]="!element.product.discountPercent" matBadgeColor="warn" matBadgePosition="above after" class="stretch-badge cart-item-badge">
													{{(element.quantity*(element.product.price-(element.product.price*(element.product.discountPercent/100)))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
												</div>
												<div fxFlex><span class="discount-chip">{{(element.quantity*element.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span></div>
											</ng-container>
											<ng-template #noDiscountTotalTemplate>
												{{(element.quantity*element.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
											</ng-template>
										</div>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="['product','quantity','total']"></tr>
								<tr mat-row *matRowDef="let row; columns: ['product','quantity','total'];"></tr>
							</table>
						</div>
					</mat-card>
					<mat-card fxFlex>
						<mat-card-header style="justify-content: left !important;">
							<mat-card-title>
								Resumen de tu orden
							</mat-card-title>
						</mat-card-header>
						<mat-card-content fxLayout="column">
							<div fxFlex fxLayout="row">
								<div fxFlex><h3><b>Subtotal</b></h3></div>
								<div fxFlex style="text-align: right"><h3>{{(getCartSubtotal() | async)?.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
							</div>
							<div *ngIf="getCartDiscount() | async" fxFlex fxLayout="row">
								<div fxFlex><h3><b>Descuento</b></h3></div>
								<div fxFlex style="text-align: right;" class="discount-total"><h3>-{{(getCartDiscount() | async)?.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
							</div>
							<div fxFlex fxLayout="row">
								<div fxFlex><h3><b>Total</b></h3></div>
								<div fxFlex style="text-align: right"><h3>{{(getCartTotal() | async)?.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
							</div>
							<div fxFlex>
								<p>Los <a [routerLink]="['/envios']">Costos del despacho</a> no están incluidos en el precio.</p>
							</div>
							<div fxFlex fxLayout="column">
								<button fxFlex mat-raised-button color="primary" class="buy-button" [routerLink]="['/check-out']">
									<mat-icon>lock</mat-icon>
									Comprar
								</button>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</ng-template>
		</ng-container>
	</mat-card-content>
</mat-card>