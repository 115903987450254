<footer style="padding: 20px" >
	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
		<div fxFlex fxLayout="column" style="text-align: center">
			<div fxFlex fxLayout="column" fxLayoutAlign="center center">
				<h2 fxFlex>Subscríbete y disfruta los resultados</h2>
				<p fxFlex class="newsletter-subscription-text">
					Todo lo que necesitas saber sobre la forma más eficiente de cultivar en un solo lugar, suscríbete a nuestro
					boletín informativo.
				</p>
				<iframe fxFlex="300px" fxFlexAlign="stretch" src="https://cdn.forms-content.sg-form.com/b42b0ba0-aba7-11ed-84e9-eaea2d7a5942" style="border: none;"></iframe>
			</div>
		</div>
		<div fxFlex fxLayout="column" fxFlexOffset.lt-md="25px" fxLayoutAlign="start center">
			<div fxFlex="nogrow">
				<h2>Siguenos</h2>
			</div>
			<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlexAlign="center center"  fxFlexOffset="20px">
				<a fxFlex="nogrow" href="https://wa.me/{{environment.contact.phone.replace('+','')}}" fxFlexAlign="center center" target="_blank" aria-label="Whatsapp">
					<mat-icon aria-label="whatsapp" [svgIcon]="'app-whatsapp'" class="app-48 mat-elevation-z2" style="border-radius: 100%;" mat-ripple></mat-icon>
				</a>
				<a fxFlex="nogrow" [href]="environment.contact.instagram" fxFlexAlign="center" target="_blank" aria-label="Instagram">
					<mat-icon aria-label="instagram" [svgIcon]="'app-instagram'" class="app-48 mat-elevation-z2" style="border-radius: 100%;" mat-ripple></mat-icon>
				</a>
				<a fxFlex="nogrow" [href]="environment.contact.facebook" fxFlexAlign="center" target="_blank" aria-label="Facebook">
					<mat-icon aria-label="facebook" [svgIcon]="'app-facebook'" class="app-48 mat-elevation-z2" style="border-radius: 100%;" mat-ripple></mat-icon>
				</a>
				<a fxFlex="nogrow" [href]="environment.contact.twitter" fxFlexAlign="center" target="_blank" aria-label="Twitter">
					<mat-icon aria-label="twitter" [svgIcon]="'app-twitter'" class="app-48 mat-elevation-z2" style="border-radius: 100%;" mat-ripple></mat-icon>
				</a>
			</div>
		</div>
	</div>
</footer>
