<mat-card>
	<mat-card-header style="justify-content: left !important;">
		<mat-card-title>
			ELIGE TUS OPCIONES DE DESPACHO
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<ng-container *ngIf="appService.cartObservable | async">
			<ng-container *ngIf="(appService.cartObservable | async).items.length===0;else cartHasItems">
				<div fxLayout="column" fxLayoutAlign="center center">
					<h3 fxFlex="nogrow">Tu Bolsa de Compras está vacía. Agrega productos ahora</h3>
					<button fxFlex="nogrow" mat-raised-button color="primary" class="buy-button" [routerLink]="['/products']">
						Ir a comprar
					</button>
				</div>
			</ng-container>
			<ng-template #cartHasItems>
				<form [formGroup]="form">
					<div fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
						<mat-card fxFlex="60" fxFlex.lt-md>
							<mat-card-content fxLayout="column">
								<mat-card fxFlex fxFlexOffset="10px">
									<mat-card-header>
										<mat-card-title>
											Datos de facturacion
										</mat-card-title>
									</mat-card-header>
									<mat-card-content fxLayout="column">
										<div *ngIf="!(appService.userObservable | async)" fxFlex>
											<p>Ya tienes cuenta? Ingresa <a (click)="openLogin()" href="javascript:void(0)">aqui</a> o registrate <a (click)="openSignup()" href="javascript:void(0)">aqui</a></p>
										</div>
										<div fxFlex="nogrow" fxLayout="column" fxLayoutGap="20px">
											<mat-form-field fxFlex floatLabel="auto">
												<input matInput type="text" placeholder="Nombre" name="name" formControlName="name" benditaInputEmptyToNull>
											</mat-form-field>
											<div fxFlex fxLayout="row" fxLayout.lt-lg="column">
												<mat-form-field fxFlex="100px" fxFlex.lt-md="" floatLabel="auto">
													<mat-label>Codigo del pais</mat-label>
													<mat-select value="+57" formControlName="phoneCountryCode" matTooltip="Codigo del pais" [required]="true">
														<mat-option value="+57">+57</mat-option>
														<mat-option value="+1">+1</mat-option>
													</mat-select>
												</mat-form-field>
												<mat-form-field fxFlex floatLabel="auto" fxFlexOffset="5px" fxFlexOffset.lt-lg="0">
													<input matInput type="tel" placeholder="Telefono" name="phone" formControlName="phone" benditaInputEmptyToNull required>
												</mat-form-field>
											</div>
											<mat-form-field fxFlex floatLabel="auto">
												<input matInput type="email" placeholder="Email" name="email" formControlName="email" benditaInputEmptyToNull required>
											</mat-form-field>
										</div>
									</mat-card-content>
								</mat-card>
								<mat-card fxFlex fxFlexOffset="10px">
									<mat-card-header>
										<mat-card-title>
											Forma de entrega
										</mat-card-title>
									</mat-card-header>
									<mat-card-content fxLayout="column">
										<mat-radio-group *ngIf="shippingTypes" fxFlex="nogrow" fxLayout="column" formControlName="shippingType" color="primary" style="padding:10px" (change)="shippingTypeChanged()">
											<ng-container *ngFor="let shippingType of shippingTypes">
												<mat-radio-button fxFlex [value]="shippingType.id" fxLayout="row" style="padding:10px">
													<mat-icon fxFlex="nogrow">{{ shippingType.icon }}</mat-icon>
													<span fxFlex fxFlexOffset="10px">{{ shippingType.label }}</span>
												</mat-radio-button>
											</ng-container>
										</mat-radio-group>
									</mat-card-content>
								</mat-card>
								<mat-card fxFlex fxFlexOffset="10px">
									<mat-card-header>
										<mat-card-title>
											Direccion de entrega
										</mat-card-title>
									</mat-card-header>
									<mat-card-content fxLayout="column">
										<ng-container [ngSwitch]="form.get('shippingType')?.value">
											<div *ngSwitchCase="SHIPPING_TYPE.Delivery" fxFlex="nogrow" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between">
												<ng-container *ngIf="appService.userObservable | async;else noUserTemplate">
													<div fxFlex="nogrow" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start" formGroupName="address">
														<mat-error fxFlex="nogrow" *ngIf="form.get('address')?.invalid && form.get('address')?.touched">Seleccione una opcion</mat-error>
														<button fxFlex="nogrow" fxFlexAlign="center" mat-button matRipple class="app-primary" (click)="addAddress()">
															<mat-icon>add</mat-icon>
															Agregar direccion
														</button>
														<mat-radio-group *ngIf="userAddresses && (userAddresses | async) as userAddresses" fxFlex="nogrow" fxLayout="column" color="primary" style="padding:10px" [ngClass]="{'ng-invalid':form.get('address')?.invalid,'ng-touched':form.get('address')?.touched}" class="addresses-list">
															<ng-container *ngFor="let address of userAddresses">
																<mat-radio-button fxFlex fxLayout="row" (change)="selectUserAddress(address)" [value]="address.id" style="padding:10px;overflow: auto">
																	<mat-icon fxFlex="nogrow">home</mat-icon>
																	<span fxFlex fxFlexOffset="10px">{{ address.line1 }}, {{ address.line2 }}, {{ address.city }}, {{ address.state }}, {{ address.country }}</span>
																</mat-radio-button>
															</ng-container>
															<div #autoScrollElementUserAddresses></div>
														</mat-radio-group>
													</div>
												</ng-container>
												<ng-template #noUserTemplate>
													<div fxFlex fxLayout="column" formGroupName="address">
														<div fxFlex fxLayout="column">
															<div fxFlex>
																<p>Ya tienes cuenta? Ingresa <a (click)="openLogin()" href="javascript:void(0)">aqui</a> o registrate <a (click)="openSignup()" href="javascript:void(0)">aqui</a></p>
															</div>
															<div fxFlex>
																<bendita-google-places-autocomplete (setAddress)="getAddress($event)"></bendita-google-places-autocomplete>
															</div>
															<mat-form-field fxFlex floatLabel="auto">
																<input matInput type="text" placeholder="Referencia adicional Ej. Apt 201" name="line2" formControlName="line2" benditaInputEmptyToNull>
															</mat-form-field>
														</div>
														<mat-form-field fxFlex floatLabel="auto">
															<input matInput type="text" placeholder="Nombre" name="name" formControlName="name" benditaInputEmptyToNull>
														</mat-form-field>
														<div fxFlex fxLayout="row" fxLayout.lt-lg="column">
															<mat-form-field fxFlex="100px" fxFlex.lt-md="" floatLabel="auto">
																<mat-label>Codigo del pais</mat-label>
																<mat-select formControlName="phoneCountryCode" matTooltip="Codigo del pais" [required]="true">
																	<mat-option value="+57">+57</mat-option>
																	<mat-option value="+1">+1</mat-option>
																</mat-select>
															</mat-form-field>
															<mat-form-field fxFlex floatLabel="auto" fxFlexOffset="5px">
																<input matInput type="tel" placeholder="Telefono" name="phone" formControlName="phone" benditaInputEmptyToNull required>
															</mat-form-field>
														</div>
													</div>
												</ng-template>
												<mat-card *ngIf="form.get('address')?.valid" fxFlex="grow" fxLayout="column" class="shipping-address-card mat-elevation-z2">
													<mat-card-title fxLayout="row" fxLayoutAlign="end">
														<mat-icon fxFlex="nogrow" color="primary">local_shipping</mat-icon>
													</mat-card-title>
													<mat-card-content fxFlex="grow" fxLayout="column">
														<p fxFlex="nogrow"><b>{{ form.get('address')?.get('name')?.value }}</b></p>
														<p fxFlex="nogrow">{{ form.get('address')?.get('line1')?.value }}</p>
														<p *ngIf="form.get('address')?.get('line2')?.value" fxFlex="nogrow">{{ form.get('address')?.get('line2')?.value }}</p>
														<p fxFlex="nogrow">{{ form.get('address')?.get('city')?.value }}, {{ form.get('address')?.get('state')?.value }}, {{ form.get('address')?.get('country')?.value }}</p>
														<p fxFlex="nogrow">Cod. postal: {{ form.get('address')?.get('zip')?.value }}</p>
														<p fxFlex="nogrow">Telefono: {{ form.get('address')?.get('phoneCountryCode')?.value }} {{ form.get('address')?.get('phone')?.value }}</p>
													</mat-card-content>
												</mat-card>
											</div>
											<div *ngSwitchCase="SHIPPING_TYPE.PickUp" fxFlex="nogrow" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between">
												<mat-radio-group fxFlex fxLayout="column" color="primary" style="padding:10px" formControlName="store" class="addresses-list">
													<ng-container *ngFor="let store of appService.storesObservable | async">
														<mat-radio-button fxFlex fxLayout="row" [value]="store.id" style="padding:10px;overflow: auto">
															<mat-icon fxFlex="nogrow" fxFlexAlign="center">place</mat-icon>
															<span fxFlex fxFlexOffset="10px">{{ store.name }}, {{ store.city }}, {{ store.country }}</span>
														</mat-radio-button>
													</ng-container>
												</mat-radio-group>
												<ng-container *ngIf="form.get('store')?.valid && form.get('store')?.dirty && (appService.storesObservable | async) as stores">
													<ng-container *ngFor="let store of stores | filter : 'id' : form.get('store')?.value">
														<mat-card fxLayout="column" class="shipping-address-card mat-elevation-z2">
															<mat-card-title fxLayout="row" fxLayoutAlign="end">
																<div fxFlex="grow">
																	<mat-card-title>Gratis</mat-card-title>
																	<mat-card-subtitle>Normalmente está listo en 1 hora</mat-card-subtitle>
																</div>
																<mat-icon fxFlex="nogrow" color="primary">storefront</mat-icon>
															</mat-card-title>
															<mat-card-content fxFlex="grow" fxLayout="column">
																<p fxFlex="nogrow"><a href="https://www.google.com/maps/place/?q=place_id:{{store.placeId}}" target="_blank" class="wrapped">{{ store.name }}</a></p>
																<p fxFlex="nogrow">{{ store.line1 }}<span *ngIf="store.line2">, {{ store.line2 }}</span></p>
																<p fxFlex="nogrow">{{ store.city }}, {{ store.state }}, {{ store.country }}</p>
																<p fxFlex="nogrow">Telefono: +{{appService.phoneNumberUtil.parse(store.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(store.phone).getNationalNumber()}}</p>
															</mat-card-content>
														</mat-card>
													</ng-container>
												</ng-container>
											</div>
										</ng-container>
									</mat-card-content>
								</mat-card>
								<mat-card fxFlex fxFlexOffset="10px">
									<mat-card-header>
										<mat-card-title>
											Metodo de pago
										</mat-card-title>
									</mat-card-header>
									<mat-card-content fxLayout="column">
										<mat-error fxFlex="nogrow" *ngIf="form.get('paymentType')?.invalid && form.get('paymentType')?.touched">Seleccione una opcion</mat-error>
										<div fxFlex="nogrow" fxLayout="column" class="payment-methods-list">
											<mat-radio-group *ngIf="paymentTypes" fxFlex="grow" fxLayout="row wrap" formControlName="paymentType" color="primary" style="padding:10px">
												<ng-container *ngFor="let paymentType of paymentTypes">
													<mat-radio-button fxFlex="50" fxFlex.lt-md="100" [value]="paymentType.id" fxLayout="row" class="payment-method-radio">
														<img fxFlex="40px" fxFlexAlign="center" src="/assets/images/{{paymentType.icon}}" width="40px" height="40px">
														&nbsp;
														&nbsp;
														<div fxFlex fxFlexAlign="center" fxLayout="column">
															<ng-container *ngIf="form.get('paymentType')?.value===paymentType.id;else paymentTypeNotSelectedTemplate">
																<ng-container *ngIf="paymentType.description;else paymentTypeDescriptionlessTemplate">
																	<h3 fxFlex style="white-space: break-spaces;word-break: break-word;">{{ paymentType.label }}</h3>
																	<span fxFlex style="white-space: break-spaces;word-break: break-word;">{{ paymentType.description }}</span>
																</ng-container>
																<ng-template #paymentTypeDescriptionlessTemplate>
																	<span fxFlex class="app-16" style="white-space: break-spaces;word-break: break-word;">{{ paymentType.label }}</span>
																</ng-template>
															</ng-container>
															<ng-template #paymentTypeNotSelectedTemplate>
																<span fxFlex class="app-16" style="white-space: break-spaces;word-break: break-word;">{{ paymentType.label }}</span>
															</ng-template>
														</div>
													</mat-radio-button>
												</ng-container>
											</mat-radio-group>
										</div>
									</mat-card-content>
								</mat-card>
							</mat-card-content>
						</mat-card>
						<mat-card fxFlex>
							<mat-card-header style="justify-content: left !important;">
								<div fxLayout="column">
									<mat-card-title fxFlex>Resumen de tu orden</mat-card-title>
									<mat-card-subtitle fxFlex fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="10px" fxLayoutGap.lt-xl="0px">
										<ng-container *ngIf="appService.cartObservable | async;">
											<div fxFlex="grow" style="white-space: nowrap;">
												<mat-icon class="app-14" color="primary">shopping_cart</mat-icon>
												<span>{{ (appService.cartObservable | async).itemsCount }} Producto{{ (appService.cartObservable | async).itemsCount===1 ? '' : 's' }}</span>
											</div>
										</ng-container>
										<ng-container *ngIf="(appService.cartObservable | async) && form.get('shippingType')?.value">
											<div fxFlex="grow" style="white-space: nowrap;">
												<mat-icon class="app-14" color="primary">local_shipping</mat-icon>
												<span>{{ getShippingTypeSelected() }}</span>
											</div>
										</ng-container>
										<ng-container *ngIf="(appService.cartObservable | async) && form.get('paymentType')?.value">
											<div fxFlex="grow" style="white-space: nowrap;">
												<mat-icon class="app-14" color="primary">paid</mat-icon>
												<span>Pago con {{ getPaymentTypeSelected() }}</span>
											</div>
										</ng-container>
									</mat-card-subtitle>
								</div>
							</mat-card-header>
							<mat-card-content fxLayout="column">
								<mat-divider style="position: unset;margin-bottom:30px"></mat-divider>
								<div fxFlex fxLayout="column" fxLayoutGap="10px">
									<ng-container *ngFor="let item of (appService.cartObservable | async).items">
										<div fxFlex fxLayout="row" fxLayoutAlign="space-between">
											<div fxFlex="nogrow" style="height: 65px;width:80px;background-position: center; background-size: contain;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+item.product.pictures[0].small+')'}" matBadge="{{item.quantity}}" matBadgeColor="primary" matBadgePosition="above after"></div>
											<span fxFlex fxFlexAlign="center">{{ item.product.name }}</span>
											<div fxFlex="nogrow" fxLayout="column" fxFlexAlign="center">
												<ng-container *ngIf="item.product.discountPercent;else noDiscountTotalTemplate">
													<div fxFlex="nogrow" matBadge="{{item.product.discountPercent}}%" [matBadgeHidden]="!item.product.discountPercent" matBadgeColor="warn" matBadgePosition="above after" class="stretch-badge cart-item-badge">
														{{
															(item.quantity*(item.product.price-(item.product.price*(item.product.discountPercent/100)))).toLocaleString("en-US",{
																style:"currency",
																currency:"USD",
																minimumFractionDigits:0,
																maximumFractionDigits:0
															})
														}}
													</div>
													<div fxFlex="nogrow"><span class="discount-chip">{{
															(item.quantity*item.product.price).toLocaleString("en-US",{
																style:"currency",
																currency:"USD",
																minimumFractionDigits:0,
																maximumFractionDigits:0
															})
														}}</span></div>
												</ng-container>
												<ng-template #noDiscountTotalTemplate>
													{{ (item.quantity*item.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0}) }}
												</ng-template>
											</div>
										</div>
									</ng-container>
								</div>
								<mat-divider style="position: unset;margin:30px 0px"></mat-divider>
								<div fxFlex fxLayout="row">
									<div fxFlex><h3><b>Subtotal</b></h3></div>
									<div fxFlex style="text-align: right"><h3>{{
											(getCartSubtotal() | async)?.toLocaleString("en-US",{
												style:"currency",
												currency:"USD",
												minimumFractionDigits:0,
												maximumFractionDigits:0
											})
										}}</h3></div>
								</div>
								<div *ngIf="getCartDiscount() | async" fxFlex fxLayout="row">
									<div fxFlex><h3><b>Descuento</b></h3></div>
									<div fxFlex style="text-align: right;" class="discount-total"><h3>-{{
											(getCartDiscount() | async)?.toLocaleString("en-US",{
												style:"currency",
												currency:"USD",
												minimumFractionDigits:0,
												maximumFractionDigits:0
											})
										}}</h3></div>
								</div>
								<div *ngIf="(getCartShipping() | async)!=null" fxFlex fxLayout="row">
									<div fxFlex><h3><b>Envio</b></h3></div>
									<div fxFlex style="text-align: right;"><h3>{{
											(getCartShipping() | async)?.toLocaleString("en-US",{
												style:"currency",
												currency:"USD",
												minimumFractionDigits:0,
												maximumFractionDigits:0
											})
										}}</h3></div>
								</div>
								<div fxFlex fxLayout="row">
									<div fxFlex><h3><b>Total</b></h3></div>
									<div fxFlex style="text-align: right"><h3>{{
											(getCartTotal() | async)?.toLocaleString("en-US",{
												style:"currency",
												currency:"USD",
												minimumFractionDigits:0,
												maximumFractionDigits:0
											})
										}}</h3></div>
								</div>
								<div fxFlex fxLayout=column>
									<button fxFlex mat-raised-button color="primary" class="buy-button" (click)="createOrder()" [disabled]="!submitButtonActive">
										<mat-icon>lock</mat-icon>
										Comprar
									</button>
									<div fxFlex id="paypalPayment">
									
									</div>
								</div>
							</mat-card-content>
						</mat-card>
					</div>
				</form>
			</ng-template>
		</ng-container>
	</mat-card-content>
</mat-card>