import {afterNextRender,Component,effect,Inject,inject,Injector,OnInit,PLATFORM_ID,REQUEST,signal} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {HeaderComponent} from './misc/components/header/header.component';
import {DrawerComponent} from './misc/components/drawer/drawer.component';
import {AppService} from './app.service';
import {DOCUMENT,isPlatformServer,ViewportScroller} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer,Meta,Title} from '@angular/platform-browser';
import {FooterComponent} from './misc/components/footer/footer.component';
import {LocalStorageService} from 'ngx-webstorage';

@Component({
	selector:'app-root',
	imports:[
		SharedModule,
		HeaderComponent,
		DrawerComponent,
		FooterComponent
	],
	templateUrl:'./app.component.html',
	styleUrl:'./app.component.scss'
})
export class AppComponent implements OnInit{
	private injector=inject(Injector);
	private viewportScroller=inject(ViewportScroller);
	private localStorageService=inject(LocalStorageService);
	private document=inject(DOCUMENT);
	appService=inject(AppService);
	matIconRegistry=inject(MatIconRegistry);
	domSanitizer=inject(DomSanitizer);
	
	constructor(
		title:Title,
		meta:Meta,
		@Inject(PLATFORM_ID) platformId:object,
		@Inject(REQUEST) request?:Request
	){
		title.setTitle('Bendita');
		meta.updateTag({content:'Bendita'},'property="og:title"');
		meta.updateTag(
			{
				content:(isPlatformServer(platformId) && request) ? request.url : this.document.location.href
			},
			'property="og:url"'
		);
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'name="description"');
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'property="og:description"');
		meta.updateTag({content:'bendita, grow, shop, semillas, plantas'},'name="keywords"');
		meta.updateTag({content:'https://www.benditagrow.com/icons/icon-512x512.png'},'property="og:image"');
		
		afterNextRender(()=>{
			this.viewportScroller.scrollToPosition([0,0]);
			
			if(this.document.defaultView){
				const darkColorScheme=this.document.defaultView.matchMedia('(prefers-color-scheme: dark)');
				darkColorScheme.addEventListener('change',()=>{
					this.appService.matchesDarkTheme.set(darkColorScheme.matches);
				});
				setTimeout(()=>darkColorScheme.dispatchEvent(new MediaQueryListEvent('change')),200);
			}
			
			effect(()=>{
				const theme=this.appService.theme();
				this.document.documentElement.classList.remove('app-dark-theme');
				this.document.documentElement.classList.remove('app-light-theme');
				if(theme){
					this.localStorageService.store('theme-id',theme);
					this.document.documentElement.classList.add(theme);
				}
			},{injector:this.injector});
		});
	}
	
	ngOnInit():void{
		this.matIconRegistry.addSvgIcon('app-facebook',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-facebook.svg'));
		this.matIconRegistry.addSvgIcon('app-twitter',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-twitter.svg'));
		this.matIconRegistry.addSvgIcon('app-instagram',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-instagram.svg'));
		this.matIconRegistry.addSvgIcon('app-whatsapp',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-whatsapp.svg'));
		
	}
	
}
