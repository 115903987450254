import {Component,OnInit} from '@angular/core';
import {ProductsService} from '../products.service';
import {AppService} from '../../app.service';
import {ActivatedRoute,Params} from '@angular/router';
import {firstValueFrom,Observable} from 'rxjs';
import {ImageItem,VideoItem} from 'ng-gallery';
import {DomSanitizer} from '@angular/platform-browser';
import {map,switchMap} from 'rxjs/operators';

@Component({
	selector:'bendita-product-detail',
	templateUrl:'./product-detail.component.html',
	styleUrls:['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit{
	title:string;
	private domSanitizer:DomSanitizer;
	private activatedRoute:ActivatedRoute;
	private readonly productsService:ProductsService;
	appService:AppService;
	product:Observable<any>;
	
	constructor(domSanitizer:DomSanitizer,activatedRoute:ActivatedRoute,productsService:ProductsService,appService:AppService){
		this.title='Bendita - Producto';
		this.domSanitizer=domSanitizer;
		this.activatedRoute=activatedRoute;
		this.productsService=productsService;
		this.appService=appService;
		this.product=this.activatedRoute.params.pipe(
			switchMap((currentValue:Params,index:number):Observable<any>=>{
				return this.productsService.getProduct({id:currentValue['p1']});
			}),
			map((product:any):any=>{
				this.appService.updateSeo(
					`Bendita - ${product.nameSeo}`,
					product.descriptionSeo,
					product.keywordsSeo,
					product.pictures[0].small
				);
				return {
					...product,
					descriptionSanitized:this.domSanitizer.bypassSecurityTrustHtml(product.description),
					pictures:product.pictures.map((element:any):ImageItem=>(new ImageItem({
						src:element.large,
						thumb:element.small
					}))),
					videos:product.videos.map((element:any):VideoItem=>(new VideoItem({
						src:element.original,
						poster:'/assets/images/video-poster.png',
						thumb:'/assets/images/video-poster.png',
						controls:true,
						autoplay:true,
						preload:'auto',
						controlsList:'nodownload'
					})))
				};
			})
		);
		
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
	}
	
	async createCartItem(quantity:string):Promise<void>{
		this.appService.createCartItem(
			{
				id:(await firstValueFrom(this.appService.cartObservable)).id,
				product:(await firstValueFrom(this.product)).id,
				quantity:Number(quantity)
			}
		)
		.subscribe(():void=>{
			this.appService.getCart();
		});
	}
	
	
}
