import {Component,Input,OnInit} from '@angular/core';

@Component({
	selector:'bendita-form-message',
	templateUrl:'./form-message.component.html',
	styleUrls:['./form-message.component.scss']
})
export class FormMessageComponent implements OnInit{
	@Input() serverErrorInput?:any;
	@Input() serverResponseInput?:any;
	
	ngOnInit():void{
	}
	
}
