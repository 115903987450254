import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginPopupComponent} from './login-popup/login-popup.component';
import {SharedModule} from '../shared/shared.module';
import {SignupPopupComponent} from './signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from './forgot-password-popup/forgot-password-popup.component';

@NgModule({
	declarations:[
		LoginPopupComponent,
		SignupPopupComponent,
		ForgotPasswordPopupComponent
	],
	imports:[
		CommonModule,
		SharedModule
	],
	exports:[
		LoginPopupComponent,
		SignupPopupComponent,
		ForgotPasswordPopupComponent
	]
})
export class AuthenticationModule{}
