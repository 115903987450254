import {Component} from '@angular/core';

@Component({
	selector:'app-check-out',
	imports:[],
	templateUrl:'./check-out.component.html',
	styleUrl:'./check-out.component.scss'
})
export class CheckOutComponent{

}
