import {Component} from '@angular/core';

@Component({
	selector:'app-email-verified',
	imports:[],
	templateUrl:'./email-verified.component.html',
	styleUrl:'./email-verified.component.scss'
})
export class EmailVerifiedComponent{

}
