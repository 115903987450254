import {AfterViewInit,Component,ElementRef,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {AddAddressPopupComponent} from '../add-address-popup/add-address-popup.component';
import {BehaviorSubject,Observable,scan,Subject,takeUntil} from 'rxjs';
import {ProfileService} from '../profile.service';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {AppService} from '../../app.service';
import {SnackMessageComponent} from '../../shared/snack-message/snack-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {switchMap} from 'rxjs/operators';

@Component({
	selector:'bendita-addresses',
	templateUrl:'./addresses.component.html',
	styleUrls:['./addresses.component.scss']
})
export class AddressesComponent implements OnInit,OnDestroy,AfterViewInit{
	title:string;
	private matDialog:MatDialog;
	private readonly profileService:ProfileService;
	private readonly breakpointObserver:BreakpointObserver;
	gridCols:number;
	gridRowHeight:string;
	appService:AppService;
	destroyed:Subject<void>;
	private matSnackBar:MatSnackBar;
	@ViewChild('autoScrollElement') autoScrollElement?:ElementRef;
	private fetchAddresses:BehaviorSubject<void>;
	itemsPerPage:number;
	addresses:Observable<any[]>;
	private resetAddresses:BehaviorSubject<void>;
	
	constructor(
		breakpointObserver:BreakpointObserver,
		matDialog:MatDialog,
		profileService:ProfileService,
		appService:AppService,
		matSnackBar:MatSnackBar
	){
		this.title='Bendita - Mis direcciones de envio';
		this.matDialog=matDialog;
		this.profileService=profileService;
		this.breakpointObserver=breakpointObserver;
		this.gridCols=1;
		this.gridRowHeight='4:3';
		this.appService=appService;
		this.destroyed=new Subject<void>();
		this.matSnackBar=matSnackBar;
		this.fetchAddresses=new BehaviorSubject<void>(undefined);
		this.resetAddresses=new BehaviorSubject<void>(undefined);
		this.itemsPerPage=10;
		this.addresses=this.resetAddresses.pipe(
			switchMap(():Observable<any>=>this.fetchAddresses.pipe(
				switchMap((currentValue:void,index:number):Observable<any[]>=>this.profileService.getAddressesShipping({limit:this.itemsPerPage,skip:index*this.itemsPerPage})),
				scan((all:any[],page:any[]):any[]=>{
					return all.concat(page);
				},[])
			))
		);
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
		this.breakpointObserver
		.observe([
			Breakpoints.XSmall,
			Breakpoints.Small,
			Breakpoints.Medium,
			Breakpoints.Large,
			Breakpoints.XLarge
		])
		.pipe(takeUntil(this.destroyed))
		.subscribe((result:BreakpointState):void=>{
			if(this.appService.isScreenXSmall()){
				this.gridRowHeight='3:4';
				this.gridCols=1;
			}else if(this.appService.isScreenSmall()){
				this.gridRowHeight='1:1';
				this.gridCols=2;
			}else if(this.appService.isScreenMedium()){
				this.gridRowHeight='4:3';
				this.gridCols=2;
			}else if(this.appService.isScreenLarge()){
				this.gridRowHeight='4:3';
				this.gridCols=3;
			}else if(this.appService.isScreenXLarge()){
				this.gridRowHeight='4:3';
				this.gridCols=5;
			}else{
				this.gridRowHeight='4:3';
				this.gridCols=6;
			}
		});
	}
	
	ngAfterViewInit():void{
		if(this.autoScrollElement){
			const observer:IntersectionObserver=new IntersectionObserver(
				(entries:IntersectionObserverEntry[]):void=>{
					for(let entry of entries){
						if(entry.isIntersecting){
							this.fetchMore();
							break;
						}
					}
				},
				{threshold:1}
			);
			setTimeout(():void=>{observer.observe(this.autoScrollElement?.nativeElement);},3000);
		}
	}
	
	fetchMore():void{
		this.fetchAddresses.next();
	}
	
	ngOnDestroy():void{
		this.destroyed.next();
		this.destroyed.complete();
	}
	
	addAddress():void{
		let dialogRef:MatDialogRef<AddAddressPopupComponent>=this.matDialog.open(
			AddAddressPopupComponent,
			{
				panelClass:'dialog-responsive',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe(():void=>{
			this.resetAddresses.next();
		});
	}
	
	editAddress(address:any):void{
		let dialogRef:MatDialogRef<AddAddressPopupComponent>=this.matDialog.open(
			AddAddressPopupComponent,
			{
				panelClass:'dialog-responsive',
				data:{address}
			}
		);
		dialogRef.afterClosed().subscribe(():void=>{
			this.resetAddresses.next();
		});
	}
	
	deleteAddress(id:string):void{
		if(this.appService.window.confirm('Desea borrar la direccion?'))
			this.profileService.deleteAddress({id})
			.subscribe({
				next:():void=>{
					this.resetAddresses.next();
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverResponseInput:'Direccion borrada'
						}
					});
				},
				error:(error:any):void=>{
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverErrorInput:error
						}
					});
				}
			});
	}
	
}
