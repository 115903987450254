import {Component,Injector,OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PopupComponent} from '../../shared/popups/popup.component';

@Component({
	selector:'bendita-password-changed-popup',
	templateUrl:'./password-changed-popup.component.html',
	styleUrls:['./password-changed-popup.component.scss']
})
export class PasswordChangedPopupComponent extends PopupComponent implements OnInit{
	private matDialogRef:MatDialogRef<PasswordChangedPopupComponent>;
	
	constructor(
		injector:Injector,
		matDialogRef:MatDialogRef<PasswordChangedPopupComponent>
	){
		super(injector);
		this.matDialogRef=matDialogRef;
		this.matDialogRef.backdropClick().subscribe(():void=>{
			this.close();
		});
	}
	
	ngOnInit():void{
	}
	
	override close():void{
		this.matDialogRef.close();
	}
}
