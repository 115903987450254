import {Routes} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {authenticationGuardActivate} from './authentication/authentication.guard';
import {profileGuardMatch} from './profile/profile.guard';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {EmailVerifiedComponent} from './email-verified/email-verified.component';
import {ContactComponent} from './contact/contact.component';
import {ProductsComponent} from './products/products.component';
import {EnviosComponent} from './envios/envios.component';
import {CartComponent} from './cart/cart.component';
import {CheckOutComponent} from './check-out/check-out.component';
import {TrackingComponent} from './tracking/tracking.component';
import {BlogComponent} from './blog/blog.component';

export const routes:Routes=[
	{
		path:'error',
		loadComponent:()=>import('./misc/error/error.component').then(m=>m.ErrorComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'',
		component:IndexComponent,
		pathMatch:'full',
		canActivate:[authenticationGuardActivate],
		data:{preload:true}
	},
	{
		path:'login',
		component:IndexComponent,
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'signup',
		component:IndexComponent,
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'forgot-password',
		component:IndexComponent,
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'reset-password',
		component:ResetPasswordComponent,
		data:{preload:true}
	},
	{
		path:'email-verified',
		component:EmailVerifiedComponent,
		data:{preload:true}
	},
	{
		path:'profile',
		loadChildren:()=>import('./profile/profile.routes').then(m=>m.routes),
		canMatch:[profileGuardMatch]
	},
	{
		path:'contact',
		component:ContactComponent,
		data:{preload:true}
	},
	{
		path:'products',
		component:ProductsComponent,
		data:{preload:true}
	},
	{
		path:'envios',
		component:EnviosComponent,
		data:{preload:true}
	},
	{
		path:'envios',
		component:EnviosComponent,
		data:{preload:true}
	},
	{
		path:'cart',
		component:CartComponent,
		data:{preload:true}
	},
	{
		path:'check-out',
		component:CheckOutComponent,
		data:{preload:true}
	},
	{
		path:'tracking',
		component:TrackingComponent,
		data:{preload:true}
	},
	{
		path:'blog',
		component:BlogComponent,
		data:{preload:true}
	},
	{
		path:'**',
		loadComponent:()=>import('./misc/not-found/not-found.component').then(m=>m.NotFoundComponent),
		pathMatch:'full',
		data:{preload:true}
	}
];
