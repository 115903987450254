<div mat-dialog-title class="app-dialog-title">
	Conectarme a mi cuenta
	<button mat-icon-button class="close-button" (click)="close()">
		<mat-icon>cancel</mat-icon>
	</button>
</div>
<mat-dialog-content>
	<p class="app-dialog-content-title">
		Para continuar ingresa tu e-mail y contraseña.
	</p>
	<form [formGroup]="form" (ngSubmit)="login()">
		<mat-form-field floatLabel="auto">
			<input matInput type="email" placeholder="Email" name="email" formControlName="email" appInputEmptyToNull required>
		</mat-form-field>
		<mat-form-field floatLabel="auto">
			<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" appInputEmptyToNull required>
		</mat-form-field>
		<div>
			<app-form-message [serverError]="serverError()" [serverResponse]="serverResponse()"></app-form-message>
		</div>
		<div>
			<button type="submit" mat-raised-button [disabled]="!submitButtonActive()">Log in</button>
		</div>
	</form>
	<br>
	<mat-divider></mat-divider>
	<br>
	<p>Nuevo cliente? <a [routerLink]="['/signup']" (click)="close(true)">Crear cuenta</a></p>
	<p>Contraseña olvidada? <a [routerLink]="['/forgot-password']" (click)="close(true)">Recuperar contraseña</a></p>
</mat-dialog-content>
