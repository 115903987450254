import {AfterViewInit,ChangeDetectorRef,Component,Inject,OnInit} from '@angular/core';
import {ActivatedRoute,Params,Router} from '@angular/router';
import {BehaviorSubject,combineLatest,firstValueFrom,Observable,tap} from 'rxjs';
import {CheckOutService} from '../check-out.service';
import {AppService} from '../../app.service';
import {GetOrderRequestDto} from '../dto/get-order-request.dto';
import {map,switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {OnApproveData,PayPalButtonsComponent} from '@paypal/paypal-js';
import {PHONE_NUMBER_UTIL} from '../../shared/constants';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {SnackMessageComponent} from '../../shared/snack-message/snack-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ORDER_STATUS} from '../../common/enums/order-status.enum';
import {PAYMENT_TYPE} from '../../common/enums/payment-type.enum';
import {SHIPPING_TYPE} from '../../common/enums/shipping-type.enum';

@Component({
	selector:'bendita-confirmation',
	templateUrl:'./confirmation.component.html',
	styleUrls:['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit,AfterViewInit{
	ORDER_STATUS:typeof ORDER_STATUS;
	PAYMENT_TYPE:typeof PAYMENT_TYPE;
	SHIPPING_TYPE:typeof SHIPPING_TYPE;
	title:string;
	private activatedRoute:ActivatedRoute;
	private router:Router;
	order:Observable<any>;
	private readonly checkOutService:CheckOutService;
	readonly appService:AppService;
	protected readonly environment:any=environment;
	private matSnackBar:MatSnackBar;
	private reloadOrder:BehaviorSubject<void>;
	private readonly changeDetectorRef:ChangeDetectorRef;
	
	constructor(
		activatedRoute:ActivatedRoute,
		checkOutService:CheckOutService,
		router:Router,
		appService:AppService,
		@Inject(PHONE_NUMBER_UTIL) phoneNumberUtil:PhoneNumberUtil,
		matSnackBar:MatSnackBar,
		changeDetectorRef:ChangeDetectorRef
	){
		this.ORDER_STATUS=ORDER_STATUS;
		this.PAYMENT_TYPE=PAYMENT_TYPE;
		this.SHIPPING_TYPE=SHIPPING_TYPE;
		this.title='Bendita - Orden confirmada';
		this.activatedRoute=activatedRoute;
		this.matSnackBar=matSnackBar;
		this.reloadOrder=new BehaviorSubject<void>(undefined);
		this.order=this.reloadOrder.pipe(
			switchMap(
				():Observable<any>=>combineLatest([
					this.activatedRoute.params.pipe(
						map((params:Params):string=>params['p1'])
					),
					this.activatedRoute.queryParams.pipe(
						map((params:Params):string=>params['token'])
					)
				])
				.pipe(
					switchMap(([id,guestToken]:any[]):Observable<any>=>{
						let data:GetOrderRequestDto={id};
						if(guestToken) data.guestToken=guestToken;
						return this.checkOutService.getOrder(data);
					}),
					tap(async(order:any):Promise<void>=>{
						console.log('order',order);
						if(!order) this.router.navigate(['/error'],{fragment:'Error_Order not found'});
					})
				)
			)
		);
		this.checkOutService=checkOutService;
		this.router=router;
		this.appService=appService;
		this.changeDetectorRef=changeDetectorRef;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
		
	}
	
	async ngAfterViewInit():Promise<void>{
		let order:any=(await firstValueFrom(this.order));
		setTimeout(async():Promise<void>=>{
			switch(order.paymentType.id){
				case(PAYMENT_TYPE.PayPal):{
					if(this.appService.paypal && this.appService.paypal.Buttons){
						if(order.status.id===ORDER_STATUS.AwaitingPayment && order.transactionId){
							let paypalButtons:PayPalButtonsComponent=this.appService.paypal.Buttons({
								style:{
									shape:'rect',
									color:'gold',
									layout:'vertical',
									label:'paypal'
								},
								createOrder:async():Promise<string>=>{
									return order.transactionId;
								},
								onApprove:async(data:OnApproveData):Promise<void>=>{
									this.checkOutService.approvePaypalOrder({id:data.orderID})
									.subscribe({
										next:(res:any):void=>{
											console.log(res);
											paypalButtons.close();
											this.reloadOrder.next();
											this.changeDetectorRef.detectChanges();
										},
										error:(error:any):void=>{
											console.error(error);
											this.matSnackBar.openFromComponent(SnackMessageComponent,{
												data:{
													serverErrorInput:error
												}
											});
										}
									});
								},
								onCancel:():void=>{
									this.matSnackBar.openFromComponent(SnackMessageComponent,{
										data:{
											serverErrorInput:'Pago cancelado'
										}
									});
								},
								onError:(error:Record<string,any>):void=>{
									console.error(error);
									this.matSnackBar.openFromComponent(SnackMessageComponent,{
										data:{
											serverErrorInput:error
										}
									});
								}
							});
							paypalButtons
							.render('#paypalPayment')
							.catch(console.error);
						}
					}
					break;
				}
				case(PAYMENT_TYPE.MercadoPago):{
					if(this.appService.mercadoPago){
						if(order.status.id===ORDER_STATUS.AwaitingPayment && order.transactionId){
							const settings:any={
								initialization:{
									amount:order.total,
									preferenceId:order.transactionId,
									payer:{
										entityType:'individual',
										first_name:order.name.split(' ')[0],
										last_name:order.name.split(' ')[1] || '',
										email:order.email
									}
								},
								customization:{
									visual:{
										style:{
											theme:'default'
										}
									},
									paymentMethods:{
										creditCard:'all',
										mercadoPago:'all',
										minInstallments:1,
										maxInstallments:1
									}
								},
								callbacks:{
									onReady:():void=>{
										console.log('mercadopago onReady');
									},
									onSubmit:async(data:any):Promise<void>=>{
										console.log('data',data);
										switch(data.paymentType){
											case('wallet_purchase'):{
												
												break;
											}
											case('credit_card'):{
												this.checkOutService.createMercadoPagoPayment({
													id:order.id,
													issuer_id:data.formData.issuer_id,
													payer:{
														email:data.formData.payer.email,
														identification:{
															number:data.formData.payer.identification.number,
															type:data.formData.payer.identification.type
														}
													},
													payment_method_id:data.formData.payment_method_id,
													token:data.formData.token
												})
												.subscribe({
													next:(res:any):void=>{
														console.log('res',res);
														brickController.unmount();
														this.reloadOrder.next();
														this.changeDetectorRef.detectChanges();
													},
													error:(error:any):void=>{
														console.error(error);
														this.matSnackBar.openFromComponent(SnackMessageComponent,{
															data:{
																serverErrorInput:error
															}
														});
													}
												});
												break;
											}
										}
									},
									onError:console.error
								}
							};
							const brickController:any=await this.appService.mercadoPago.bricks().create(
								'payment',
								'mercadoPagoPayment',
								settings
							);
						}
					}
					break;
				}
			}
			
		},500);
		
	}
	
}
