import {NgModule} from '@angular/core';
import {Routes,RouterModule} from '@angular/router';
import {IndexComponent} from './index.component';
import {AuthenticationGuard} from '../authentication/authentication-guard.service';

const routes:Routes=[
	{path:'',component:IndexComponent,pathMatch:'full'},
	{path:'login',component:IndexComponent,canActivate:[AuthenticationGuard],canLoad:[AuthenticationGuard]},
	{path:'signup',component:IndexComponent,canActivate:[AuthenticationGuard],canLoad:[AuthenticationGuard]},
	{path:'forgot-password',component:IndexComponent,canActivate:[AuthenticationGuard],canLoad:[AuthenticationGuard]}
];

@NgModule({
	imports:[RouterModule.forChild(routes)],
	exports:[RouterModule]
})
export class IndexRoutingModule{}
