import {Component,OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute,Params} from '@angular/router';
import {ProductsService} from '../../products/products.service';
import {AppService} from '../../app.service';
import {BlogService} from '../blog.service';
import {BehaviorSubject,firstValueFrom,Observable,tap} from 'rxjs';
import {ImageItem} from 'ng-gallery';
import {catchError,map,switchMap} from 'rxjs/operators';

@Component({
	selector:'bendita-blog-post-detail',
	templateUrl:'./blog-post-detail.component.html',
	styleUrls:['./blog-post-detail.component.scss']
})
export class BlogPostDetailComponent implements OnInit{
	private domSanitizer:DomSanitizer;
	private activatedRoute:ActivatedRoute;
	private readonly blogService:BlogService;
	blogPost:Observable<any>;
	appService:AppService;
	title:string;
	
	constructor(
		domSanitizer:DomSanitizer,
		activatedRoute:ActivatedRoute,
		blogService:BlogService,
		appService:AppService
	){
		this.title='Bendita - Blog post';
		this.domSanitizer=domSanitizer;
		this.activatedRoute=activatedRoute;
		this.appService=appService;
		this.blogService=blogService;
		this.blogPost=this.activatedRoute.params.pipe(
			switchMap((currentValue:Params,index:number):Observable<any>=>{
				return this.blogService.getBlogPost({id:currentValue['p1']});
			}),
			map((blogPost:any):any=>{
				this.appService.updateSeo(
					`Bendita - ${blogPost.titleSeo}`,
					blogPost.introSeo,
					blogPost.keywordsSeo,
					blogPost.cover.small
				);
				return {
					...blogPost,
					contentSanitized:this.domSanitizer.bypassSecurityTrustHtml(blogPost.content)
				};
			})
		);
		
		
	}
	
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
	}
	
	
}
