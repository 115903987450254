<mat-card *ngIf="(order | async) as order">
	<mat-card-header>
		<div mat-card-avatar>
			<button mat-mini-fab color="primary" [routerLink]="['/profile','orders']">
				<mat-icon>navigate_before</mat-icon>
			</button>
		</div>
		<mat-card-title>
			Referencia de orden: {{order.slug}}
		</mat-card-title>
		<mat-card-subtitle>
			<b>{{order.createdAt | date:'d MMM y, h:mm a'}}</b>
		</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content fxLayout="column" fxLayoutGap="15px">
		<div fxFlex fxLayout="column" fxLayoutAlign="center stretch">
			<bendita-order-status-tracker fxFlex="nogrow" fxLayout="column" [statusInput]="order.status.id" [shippingTypeInput]="order.shippingType.id"></bendita-order-status-tracker>
		</div>
		<div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
			<mat-card fxFlex="60" fxFlex.lt-md>
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon class="app-36" [color]="order.status.id===ORDER_STATUS.Cancelled ? 'warn' : 'primary'">{{order.status.icon}}</mat-icon>
					</div>
					<mat-card-title>
						{{order.status.label}}
					</mat-card-title>
					<mat-card-subtitle>
						{{order.status.description}}
					</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content fxLayout="column" fxLayoutGap="10px">
					<mat-card *ngIf="order.status.id===ORDER_STATUS.AwaitingPickUp" fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<mat-card-title>
								Presenta el siguiente codigo QR para reclamar tu orden en la tienda
							</mat-card-title>
							<mat-card-subtitle>
								Click en la imagen para agrandar
							</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content fxFlex fxLayout="column" fxLayoutAlign="start center">
							<ng-container *ngIf="tokenOrderPickUp;else tokenNotExists">
								<div fxFlex (click)="openQR()" class="app-pointer">
									<qrcode
										[qrdata]="tokenOrderPickUp.result"
										[width]="250"
										errorCorrectionLevel="L"
										elementType="svg"
									></qrcode>
								</div>
							</ng-container>
							<ng-template #tokenNotExists>
								<div fxFlex fxLayout="row" fxLayoutGap="15px">
									<mat-icon fxFlex color="warn">cancel</mat-icon>
									<p>Token de validacion de identidad no existe, comunicate con soporte para recojer tu paquete</p>
								</div>
							</ng-template>
						</mat-card-content>
					</mat-card>
					<mat-card fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<div mat-card-avatar>
								<img fxFlex="36px" fxFlexAlign="center" src="/assets/images/{{order.paymentType.icon}}">
							</div>
							<mat-card-title style="white-space: break-spaces;word-break: break-word;">
								{{order.paymentType.label}}
							</mat-card-title>
							<mat-card-subtitle *ngIf="order.paymentType.descriptionLong" style="white-space: break-spaces;word-break: break-word;">
								{{order.paymentType.descriptionLong}}
							</mat-card-subtitle>
							<mat-card-subtitle *ngIf="order.paymentType.paymentAccount" style="white-space: break-spaces;word-break: break-word;">
								Enviar el pago a la cuenta: <b>{{order.paymentType.paymentAccount}}</b>
							</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content fxLayout="column">
							
							
							<ng-container [ngSwitch]="order.paymentType.id">
								<ng-container *ngSwitchCase="PAYMENT_TYPE.Daviplata">
									<ng-container *ngTemplateOutlet="proofOfPaymentTemplate"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="PAYMENT_TYPE.Nequi">
									<ng-container *ngTemplateOutlet="proofOfPaymentTemplate"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="PAYMENT_TYPE.Bitcoin">
									<ng-container *ngTemplateOutlet="bitcoinPaymentTemplate"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="PAYMENT_TYPE.PayPal">
									<ng-container *ngTemplateOutlet="paypalPaymentTemplate"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="PAYMENT_TYPE.MercadoPago">
									<ng-container *ngTemplateOutlet="mercadoPagoPaymentTemplate"></ng-container>
								</ng-container>
								
								
								<ng-template #bitcoinPaymentTemplate>
									<ng-container *ngIf="order.openNodeCharge;else openNodeChargeNotExists">
										<p fxFlex fxFlexOffset="15px">Ver pago: <a href="https://checkout{{environment.production ? '' : '.dev'}}.opennode.com/{{order.openNodeCharge.id}}" target="_blank">{{order.openNodeCharge.id}}</a></p>
										<p fxFlex>Status: <b>{{order.openNodeCharge.status}}</b></p>
										<p fxFlex>Total: {{(order.openNodeCharge.fiat_value/100).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</p>
										<p fxFlex>Total BTC: {{(order.openNodeCharge.amount/100000000).toLocaleString("en-US",{style:"currency",currency:"BTC",minimumFractionDigits:8,maximumFractionDigits:8})}}</p>
										<ng-container *ngIf="order.openNodeCharge.missing_amt">
											<p fxFlex>Cantidad faltante: {{(order.openNodeCharge.missing_amt/100000000).toLocaleString("en-US",{style:"currency",currency:"BTC",minimumFractionDigits:8,maximumFractionDigits:8})}}</p>
										</ng-container>
										<ng-container *ngIf="order.openNodeCharge.expires_at">
											<p fxFlex>Expiracion: {{order.openNodeCharge.expires_at*1000 | date : 'd/MM/yy, HH:mm'}}</p>
										</ng-container>
										<ng-container *ngIf="order.openNodeCharge.metadata">
											<p fxFlex>Pagador: {{order.openNodeCharge.metadata.name}}, {{order.openNodeCharge.metadata.email}}</p>
										</ng-container>
										<br>
										<p fxFlex><b>Transacciones:</b></p>
										<ng-container *ngFor="let transaction of order.openNodeCharge.transactions">
											<mat-card fxFlex>
												<mat-card-content>
													<p>Id: <a href="https://mempool.space{{environment.production ? '' : '/testnet'}}/tx/{{transaction.tx}}" target="_blank">{{transaction.tx}}</a></p>
													<ng-container *ngIf="transaction.status">
														<p>Status: {{transaction.status}}</p>
													</ng-container>
													<p>Valor: <b>{{(transaction.amount/100000000).toLocaleString("en-US",{style:"currency",currency:"BTC",minimumFractionDigits:8,maximumFractionDigits:8})}}</b></p>
													<p>Fecha creacion: {{transaction.created_at*1000 | date : 'd/MM/yy, HH:mm'}}</p>
													<ng-container *ngIf="transaction.settled_at">
														<p>Fecha liquidacion: {{transaction.settled_at*1000 | date : 'd/MM/yy, HH:mm'}}</p>
													</ng-container>
												</mat-card-content>
											</mat-card>
										</ng-container>
									</ng-container>
									<ng-template #openNodeChargeNotExists>
										<h1>Pago No Encontrado</h1>
										<p *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment"><a [routerLink]="['/check-out',order.slug]" [queryParams]="{'token':order.guestToken || null}">Ir a pagar</a></p>
									</ng-template>
								</ng-template>
								
								<ng-template #proofOfPaymentTemplate>
									<ng-container *ngIf="!order.proofOfPayment && order.status.id===ORDER_STATUS.AwaitingPayment">
										<div fxFlex fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-evenly start">
											<ngx-file-drop (onFileDrop)="onFileDrop($event)">
												<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
													&nbsp;
													<span style="text-align: center"> Arrastra o selecciona el archivo </span>
													&nbsp;
													<button mat-mini-fab color="primary" class="upload-btn" (click)="openFileSelector()">
														<mat-icon>attach_file</mat-icon>
													</button>
													&nbsp;
												</ng-template>
											</ngx-file-drop>
											<div fxFlex fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-evenly start">
												<ng-container *ngFor="let item of files; let i=index">
													<p fxFlex><strong>{{item.relativePath}}</strong></p>
												</ng-container>
												<ng-container *ngIf="!files[0];else proofOfPaymentSelectedTemplate">
													<p fxFlex><strong>Completa el pago en {{order.paymentType.label}} y envia el comprobante para continuar con el pedido.</strong></p>
												</ng-container>
												<ng-template #proofOfPaymentSelectedTemplate>
													<button fxFlex mat-button matRipple class="app-primary" (click)="uploadProofOfPayment()">
														Subir archivo
													</button>
												</ng-template>
											</div>
										</div>
									</ng-container>
									<ng-container *ngIf="order.proofOfPayment">
										<div fxFlex fxLayout="column">
											<p fxFlex fxLayout="row" fxLayoutGap="10px">
												<mat-icon fxFlex="nogrow" fxFlexAlign="center" class="app-30">pending_actions</mat-icon>
												<strong fxFlex fxFlexAlign="center">
													Enviaste 1 comprobante de pago
													<ng-container *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment">, la aprobacion del pago puede tomar hasta 72 horas</ng-container>
												</strong>
											</p>
										</div>
									</ng-container>
								</ng-template>
								
								<ng-template #paypalPaymentTemplate>
									<ng-container *ngIf="order.paypalOrder;else paypalOrderNotExists">
										<div fxFlex fxLayout="column">
											<p fxFlex>Referencia de pago: {{order.paypalOrder.id}}</p>
											<p fxFlex>Status: {{order.paypalOrder.status}}</p>
											<ng-container *ngIf="order.paypalOrder.payer">
												<br>
												<p fxFlex><b>Pagador</b></p>
												<p fxFlex>Nombre: {{order.paypalOrder.payer.name.given_name}} {{order.paypalOrder.payer.name.surname}}</p>
												<p fxFlex>Email: {{order.paypalOrder.payer.email_address}}</p>
											</ng-container>
											<ng-container *ngIf="order.paypalOrder.payment_source">
												<br>
												<p fxFlex><b>Metodo de pago</b></p>
												<ng-container *ngIf="order.paypalOrder.payment_source.paypal">
													<p fxFlex><b>Paypal wallet</b></p>
													<p fxFlex>Nombre: {{order.paypalOrder.payment_source.paypal.name.given_name}} {{order.paypalOrder.payment_source.paypal.name.surname}}</p>
													<p fxFlex>Email: {{order.paypalOrder.payment_source.paypal.email_address}}</p>
												</ng-container>
											</ng-container>
										</div>
									</ng-container>
									<ng-template #paypalOrderNotExists>
										<h1>Orden No Encontrada o Expirada</h1>
										<p *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment"><a [routerLink]="['/check-out',order.slug]" [queryParams]="{'token':order.guestToken || null}">Ir a pagar</a></p>
									</ng-template>
								</ng-template>
								
								<ng-template #mercadoPagoPaymentTemplate>
									<ng-container *ngIf="order.mercadoPagoPreference">
										<div fxFlex fxLayout="column">
											<p fxFlex>Pago Pendiente - {{order.mercadoPagoPreference.purpose}}</p>
											<p fxFlex>Pago iniciado: {{order.mercadoPagoPreference.date_created | date:'d MMM y, HH:mm'}}</p>
											<ng-container *ngIf="order.mercadoPagoPreference.date_of_expiration">
												<p fxFlex>Pago expira: {{order.mercadoPagoPreference.date_of_expiration | date:'d MMM y, HH:mm'}}</p>
											</ng-container>
											<p *ngIf="order.status.id===ORDER_STATUS.AwaitingPayment"><a [routerLink]="['/check-out',order.slug]" [queryParams]="{'token':order.guestToken || null}">Ir a pagar</a></p>
										</div>
									</ng-container>
									<ng-container *ngIf="order.mercadoPagoPayment">
										<div fxFlex fxLayout="column">
											<p fxFlex>Referencia de pago: {{order.mercadoPagoPayment.id}}</p>
											<p fxFlex>Status: {{order.mercadoPagoPayment.status}}, {{order.mercadoPagoPayment.status_detail}}</p>
											<p fxFlex>Valor: {{order.mercadoPagoPayment.net_amount.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</p>
											<br>
											<p fxFlex><b>Metodo de pago</b></p>
											<p fxFlex>{{order.mercadoPagoPayment.payment_method.type}} {{order.mercadoPagoPayment.payment_method.id}}</p>
											<ng-container *ngIf="order.mercadoPagoPayment.card">
												<p fxFlex>Tarjeta: ****{{order.mercadoPagoPayment.card.last_four_digits}} exp:{{order.mercadoPagoPayment.card.expiration_month}}/{{order.mercadoPagoPayment.card.expiration_year}}</p>
												<p fxFlex>Titular de la tarjeta: {{order.mercadoPagoPayment.card.cardholder.name}}, {{order.mercadoPagoPayment.card.cardholder.identification.type}} {{order.mercadoPagoPayment.card.cardholder.identification.number}}</p>
											</ng-container>
											<ng-container *ngIf="order.mercadoPagoPayment.payer">
												<br>
												<p fxFlex><b>Pagador</b></p>
												<p fxFlex>Email: {{order.mercadoPagoPayment.payer.email}}</p>
												<p fxFlex>Identificacion: {{order.mercadoPagoPayment.payer.identification.type}} {{order.mercadoPagoPayment.payer.identification.number}}</p>
											</ng-container>
										</div>
									</ng-container>
								</ng-template>
							
							
							</ng-container>
						</mat-card-content>
					</mat-card>
					<mat-card fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<mat-card-title>Actualizaciones del pedido</mat-card-title>
							<mat-card-subtitle>Recibirás actualizaciones del envío y entrega de tu pedido por correo electrónico.</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content></mat-card-content>
					</mat-card>
					<mat-card fxFlex fxFlexOffset="10px">
						<mat-card-header>
							<div mat-card-avatar>
								<mat-icon class="app-36">{{order.shippingType.icon}}</mat-icon>
							</div>
							<mat-card-title>
								{{order.shippingType.label}}
							</mat-card-title>
							<mat-card-subtitle>
								<ng-container *ngIf="order.shippingType.id===SHIPPING_TYPE.Delivery;else pickupTemplateHeader">
									Envios locales aprox. 24 horas, envios nacionales aprox. 72 horas.
								</ng-container>
								<ng-template #pickupTemplateHeader>
									Recojer en tienda, los productos seran agrupados en el punto de entrega para su recoleccion
								</ng-template>
							</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content fxFlex="grow" fxLayout="column">
							<ng-container *ngIf="order.shippingType.id===SHIPPING_TYPE.Delivery;else pickupTemplate">
								<p fxFlex="nogrow">{{order.shippingAddress.name}}</p>
								<p fxFlex="nogrow">{{order.shippingAddress.line1}}</p>
								<p *ngIf="order.shippingAddress.line2" fxFlex="nogrow">{{order.shippingAddress.line2}}</p>
								<p fxFlex="nogrow">{{order.shippingAddress.city}}, {{order.shippingAddress.state}}, {{order.shippingAddress.country}}</p>
								<p fxFlex="nogrow">Cod. postal: {{order.shippingAddress.zip}}</p>
								<p fxFlex="nogrow">Telefono:
									+{{appService.phoneNumberUtil.parse(order.shippingAddress.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(order.shippingAddress.phone).getNationalNumber()}}</p>
							</ng-container>
							<ng-template #pickupTemplate>
								<p fxFlex="nogrow">{{order.store.name}}</p>
								<p fxFlex="nogrow">{{order.store.line1}}<span *ngIf="order.store.line2">, {{order.store.line2}}</span></p>
								<p fxFlex="nogrow">{{order.store.city}}, {{order.store.state}}, {{order.store.country}}</p>
								<p fxFlex="nogrow">Telefono:
									+{{appService.phoneNumberUtil.parse(order.store.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(order.store.phone).getNationalNumber()}}</p>
								<mat-divider style="position: unset;"></mat-divider>
								<h2 fxFlex="nogrow">Informacion del cliente</h2>
								<p fxFlex="nogrow">Nombre: {{order.name}}</p>
								<p fxFlex="nogrow">Telefono: +{{appService.phoneNumberUtil.parse(order.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(order.phone).getNationalNumber()}}</p>
								<p fxFlex="nogrow">Email: {{order.email}}</p>
							</ng-template>
						</mat-card-content>
					</mat-card>
				</mat-card-content>
			</mat-card>
			<mat-card fxFlex>
				<mat-card-content fxLayout="column">
					<div fxFlex fxLayout="column" fxLayoutGap="10px">
						<ng-container *ngFor="let item of order.items">
							<div fxFlex fxLayout="row" fxLayoutAlign="space-between">
								<div fxFlex="nogrow" style="height: 65px;width:80px;background-position: center; background-size: contain;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+item.product.picture+')'}" matBadge="{{item.quantity}}" matBadgeColor="primary" matBadgePosition="above after"></div>
								<span fxFlex fxFlexAlign="center">{{item.product.name}}</span>
								<div fxFlex="nogrow" fxLayout="column" fxFlexAlign="center">
									<ng-container *ngIf="item.product.discountPercent;else noDiscountTotalTemplate">
										<div fxFlex="nogrow" matBadge="{{item.product.discountPercent}}%" [matBadgeHidden]="!item.product.discountPercent" matBadgeColor="warn" matBadgePosition="above after" class="stretch-badge cart-item-badge">
											{{(item.quantity*(item.product.price-(item.product.price*(item.product.discountPercent/100)))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
										</div>
										<div fxFlex="nogrow"><span class="discount-chip">{{(item.quantity*item.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span></div>
									</ng-container>
									<ng-template #noDiscountTotalTemplate>
										{{(item.quantity*item.product.price).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
									</ng-template>
								</div>
							</div>
						</ng-container>
					</div>
					<mat-divider style="position: unset;margin:30px 0px"></mat-divider>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Subtotal</b></h3></div>
						<div fxFlex style="text-align: right"><h3>{{order.subtotal.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Descuento</b></h3></div>
						<div fxFlex style="text-align: right;" class="discount-total"><h3>-{{order.discount.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Envio</b></h3></div>
						<div fxFlex style="text-align: right;"><h3>{{order.shipping.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<div fxFlex fxLayout="row">
						<div fxFlex><h3><b>Total</b></h3></div>
						<div fxFlex style="text-align: right"><h3>{{order.total.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3></div>
					</div>
					<mat-divider style="position: unset;margin:30px 0px"></mat-divider>
					<mat-card fxFlex>
						<mat-card-header>
							<div mat-card-avatar>
								<mat-icon>expand_more</mat-icon>
							</div>
							<mat-card-title>Seguimiento</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<div [ngStyle]="{'overflow':'auto'}">
								<mat-chip-set role="list">
									<ng-container *ngFor="let event of orderEvents | async;index as i">
										<mat-chip role="listitem" color="primary" style="max-width: unset">
											<b>{{event.createdAt | date:'d MMM y, h:mm a'}}</b>
											<span> - {{event.description}}</span>
										</mat-chip>
									</ng-container>
								</mat-chip-set>
							</div>
							<div #autoScrollElement></div>
						</mat-card-content>
					</mat-card>
				</mat-card-content>
			</mat-card>
		</div>
	</mat-card-content>
</mat-card>