<mat-card>
	<nav mat-tab-nav-bar backgroundColor="accent" [tabPanel]="tabPanel">
		<a mat-tab-link [routerLink]="['account']" routerLinkActive #accountTab="routerLinkActive" [active]="accountTab.isActive">
			<mat-icon class="">account_circle</mat-icon>
			Mi cuenta
		</a>
		<a mat-tab-link [routerLink]="['addresses']" routerLinkActive #addressesTab="routerLinkActive" [active]="addressesTab.isActive">
			<mat-icon class="">house</mat-icon>
			Mis direcciones
		</a>
<!--		<a mat-tab-link [routerLink]="['payment']" routerLinkActive #paymentTab="routerLinkActive" [active]="paymentTab.isActive">-->
<!--			<mat-icon class="">attach_money</mat-icon>-->
<!--			Mis metodos de pago-->
<!--		</a>-->
		<a mat-tab-link [routerLink]="['orders']" routerLinkActive #ordersTab="routerLinkActive" [active]="ordersTab.isActive">
			<mat-icon class="">shopping_bag</mat-icon>
			Mis ordenes
		</a>
	</nav>
	<mat-tab-nav-panel #tabPanel>
		<router-outlet></router-outlet>
	</mat-tab-nav-panel>
</mat-card>
