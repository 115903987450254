<mat-toolbar>
	<button mat-icon-button type="button" (click)="drawer().toggle()" aria-label="Close">
		<mat-icon class="app-24">close</mat-icon>
	</button>
	<div class="spacer"></div>
	@if (appService.theme(); as theme) {
		<button mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="theme==='app-dark-theme' ? 'Light mode' : 'Dark mode'">
			<mat-icon>{{theme==='app-dark-theme' ? 'light_mode' : 'dark_mode'}}</mat-icon>
		</button>
	} @else {
		@if (appService.matchesDarkTheme(); as matchesDarkTheme) {
			<button mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="matchesDarkTheme ? 'Light mode' : 'Dark mode'">
				<mat-icon>{{matchesDarkTheme ? 'light_mode' : 'dark_mode'}}</mat-icon>
			</button>
		}
	}
</mat-toolbar>
<div class="drawer-content">
	<mat-nav-list class="drawer-nav-list">
		<a mat-list-item [routerLink]="['/']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
			<span>Inicio</span>
		</a>
		<a *ngIf="authenticationService.user()" mat-list-item [routerLink]="['/profile']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
			<span>Mi perfil</span>
		</a>
		<a mat-list-item [routerLink]="['/blog']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
			<span>Blog</span>
		</a>
		<mat-expansion-panel class="app-elevation0" [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<b>Productos</b>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-nav-list>
				<a mat-list-item [routerLink]="['/products']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
					<span class="app-14">Todos los productos</span>
				</a>
				@for (category of appService.productCategories(); track category.id) {
					<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
						<span>{{category.label}}</span>
					</a>
				}
			</mat-nav-list>
		</mat-expansion-panel>
		<a mat-list-item [routerLink]="['/envios']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
			<span>Politica de envios</span>
		</a>
		<a mat-list-item [routerLink]="['/contact']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()">
			<span>Contacto</span>
		</a>
	</mat-nav-list>
	<div class="drawer-footer-container">
		<div class="drawer-footer">
			<a mat-icon-button class="drawer-footer-item app-elevation1" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" href="https://wa.me/{{environment.contact.phone.replace('+','')}}" target="_blank" aria-label="Whatsapp">
				<mat-icon aria-label="whatsapp" [svgIcon]="'app-whatsapp'"></mat-icon>
			</a>
			<a mat-icon-button class="drawer-footer-item app-elevation1" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" [href]="environment.contact.instagram" target="_blank" aria-label="Intagram">
				<mat-icon aria-label="instagram" [svgIcon]="'app-instagram'"></mat-icon>
			</a>
			<a mat-icon-button class="drawer-footer-item app-elevation1" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" [href]="environment.contact.facebook" target="_blank" aria-label="Facebook">
				<mat-icon aria-label="facebook" [svgIcon]="'app-facebook'"></mat-icon>
			</a>
			<a mat-icon-button class="drawer-footer-item app-elevation1" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" [href]="environment.contact.twitter" target="_blank" aria-label="Twitter">
				<mat-icon aria-label="twitter" [svgIcon]="'app-twitter'"></mat-icon>
			</a>
		</div>
	</div>
</div>
