<mat-card *ngIf="(blogPost | async) as blogPost">
	<mat-card-header>
		<div mat-card-avatar>
			<button mat-mini-fab color="primary" [routerLink]="['/blog']">
				<mat-icon>navigate_before</mat-icon>
			</button>
		</div>
		<mat-card-title>
			<b>{{blogPost.title}}</b>
		</mat-card-title>
		<mat-card-subtitle>
			<b>{{blogPost.author}} - {{blogPost.createdAt | date:'d MMM y'}}</b>
		</mat-card-subtitle>
		<mat-card-subtitle *ngIf="blogPost.updatedAt!==blogPost.createdAt">
			<b>Actualizado: {{blogPost.updatedAt | date:'d MMM y'}}</b>
		</mat-card-subtitle>
	</mat-card-header>
	<img mat-card-image [src]="blogPost.cover.large" [alt]="blogPost.title" style="max-height: 500px;object-fit: cover;">
	<mat-card-content fxLayout="column" fxLayoutGap="20px">
		<h3 fxFlex class="blog-post-intro"><b>{{blogPost.intro}}</b></h3>
		<div fxFlex [innerHtml]="blogPost.contentSanitized" class="ck-content"></div>
	</mat-card-content>
</mat-card>
