import {Component,ElementRef,OnInit,ViewChild} from '@angular/core';
import {ActivatedRoute,Params,Router} from '@angular/router';
import {BehaviorSubject,firstValueFrom,Observable,scan,tap} from 'rxjs';
import {ProfileService} from '../profile.service';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {SnackMessageComponent} from '../../shared/snack-message/snack-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {switchMap} from 'rxjs/operators';
import {AppService} from '../../app.service';
import {environment} from '../../../environments/environment';
import {TokenOrderPickUpPopupComponent} from '../../shared/popups/token-order-pick-up-popup/token-order-pick-up-popup.component';
import {ORDER_STATUS} from '../../common/enums/order-status.enum';
import { PAYMENT_TYPE } from 'src/app/common/enums/payment-type.enum';
import {SHIPPING_TYPE} from '../../common/enums/shipping-type.enum';

@Component({
	selector:'bendita-order-detail',
	templateUrl:'./order-detail.component.html',
	styleUrls:['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit{
	ORDER_STATUS:typeof ORDER_STATUS;
	PAYMENT_TYPE:typeof PAYMENT_TYPE;
	SHIPPING_TYPE:typeof SHIPPING_TYPE;
	title:string;
	private activatedRoute:ActivatedRoute;
	private router:Router;
	order:Observable<any>;
	private readonly profileService:ProfileService;
	public files:NgxFileDropEntry[];
	private matSnackBar:MatSnackBar;
	private matDialog:MatDialog;
	private reloadOrder:BehaviorSubject<void>;
	appService:AppService;
	itemsPerPage:number;
	orderEvents?:Observable<any[]>;
	private resetOrderEvents:BehaviorSubject<void>;
	private fetchOrderEvents:BehaviorSubject<void>;
	@ViewChild('autoScrollElement') autoScrollElement?:ElementRef;
	protected readonly environment=environment;
	tokenOrderPickUp:any;
	
	constructor(
		activatedRoute:ActivatedRoute,
		profileService:ProfileService,
		matSnackBar:MatSnackBar,
		matDialog:MatDialog,
		router:Router,
		appService:AppService
	){
		this.ORDER_STATUS=ORDER_STATUS;
		this.PAYMENT_TYPE=PAYMENT_TYPE;
		this.SHIPPING_TYPE=SHIPPING_TYPE;
		this.title='Bendita - Detalle de orden';
		this.appService=appService;
		this.activatedRoute=activatedRoute;
		this.profileService=profileService;
		this.router=router;
		this.files=[];
		this.matSnackBar=matSnackBar;
		this.matDialog=matDialog;
		this.reloadOrder=new BehaviorSubject<void>(undefined);
		this.order=this.reloadOrder.pipe(
			switchMap(
				():Observable<any>=>this.activatedRoute.params.pipe(
					switchMap((currentValue:Params,index:number):Observable<any>=>{
						return this.profileService.getOrder({id:currentValue['id']});
					}),
					tap((order:any):void=>{
						if(!order) this.router.navigate(['/error'],{fragment:'Error_Order not found'});
						else{
							this.appService.updateSeo(
								`Bendita - Orden ${order.slug}`,
								'',
								''
							);
						}
					})
				)
			)
		);
		this.itemsPerPage=20;
		this.resetOrderEvents=new BehaviorSubject<void>(undefined);
		this.fetchOrderEvents=new BehaviorSubject<void>(undefined);
		this.orderEvents=undefined;
		this.tokenOrderPickUp=undefined;
		
		this.getTokenOrderPickUp();
		
	}
	
	async ngOnInit():Promise<void>{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
		
		const orderId:string=(await firstValueFrom(this.activatedRoute.params))['id'];
		this.orderEvents=this.resetOrderEvents.pipe(
			switchMap(():Observable<any>=>this.fetchOrderEvents.pipe(
				switchMap(
					(currentValue:void,index:number):Observable<any[]>=>this.profileService.getOrderEvents(
						{
							id:orderId,
							limit:this.itemsPerPage,
							skip:index*this.itemsPerPage
						}
					)
				),
				scan((all:any[],page:any[]):any[]=>{
					return all.concat(page);
				},[])
			))
		);
	}
	
	onFileDrop(files:NgxFileDropEntry[]):void{
		this.files=files;
	}
	
	async uploadProofOfPayment():Promise<void>{
		if(this.files[0]){
			const fileEntry:FileSystemFileEntry=this.files[0].fileEntry as FileSystemFileEntry;
			fileEntry.file(async(file:File):Promise<void>=>{
				let next=():void=>{
					this.reloadOrder.next();
				};
				let error=(error:string):void=>{
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverErrorInput:error
						}
					});
				};
				this.profileService.createProofOfPayment((await firstValueFrom(this.activatedRoute.params))['id'],file)
				.subscribe({next,error});
			});
		}
	}
	
	
	async getTokenOrderPickUp():Promise<void>{
		this.tokenOrderPickUp=await firstValueFrom(this.profileService.getTokenOrderPickUp({
			id:(await firstValueFrom(this.activatedRoute.params))['id']
		}));
	}
	
	async openQR():Promise<void>{
		this.matDialog.open(
			TokenOrderPickUpPopupComponent,
			{
				panelClass:'dialog-responsive',
				data:{
					order:(await firstValueFrom(this.order)),
					tokenOrderPickUp:this.tokenOrderPickUp
				},
				width:'100%',
				height:'100%'
			}
		);
	}
	
}
