import {Component,OnInit} from '@angular/core';
import {AppService} from '../app.service';

@Component({
	selector:'bendita-profile',
	templateUrl:'./profile.component.html',
	styleUrls:['./profile.component.scss']
})
export class ProfileComponent implements OnInit{
	title:string;
	appService:AppService;
	
	constructor(appService:AppService){
		this.title='Bendita - Mi perfil';
		this.appService=appService;
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
	}
	
}
