import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EmailVerifiedRoutingModule} from './email-verified-routing.module';
import {EmailVerifiedComponent} from './email-verified.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
	declarations:[
		EmailVerifiedComponent
	],
	imports:[
		CommonModule,
		EmailVerifiedRoutingModule,
		SharedModule
	]
})
export class EmailVerifiedModule{}
