<mat-card>
	<mat-card-header>
		<mat-card-title>Metodos de pago</mat-card-title>
	</mat-card-header>
	<mat-card-content fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center none">
		<button fxFlex="noshrink" fxFlexAlign="center" mat-button matRipple class="app-primary" (click)="addCard()">
			<mat-icon>add</mat-icon>
			Agregar tarjeta
		</button>
		<mat-grid-list *ngIf="addresses" fxFlex [cols]="gridCols" [rowHeight]="gridRowHeight">
			<mat-grid-tile *ngFor="let address of addresses" fxLayout="row">
				<mat-card fxFlex="90" fxLayout="column" class="mat-elevation-z2">
					<mat-card-content fxFlex="grow" fxLayout="column">
						<p fxFlex="nogrow"><b>{{address.name}}</b></p>
						<p fxFlex="nogrow">{{address.line1}}</p>
						<p *ngIf="address.line2;else noAddressLine2" fxFlex="nogrow">{{address.line2}}</p>
						<ng-template #noAddressLine2><p fxFlex="nogrow">&nbsp;</p></ng-template>
						<p fxFlex="nogrow">{{address.city}}, {{address.state}}, {{address.country}}</p>
						<p fxFlex="nogrow">Cod. postal: {{address.zip}}</p>
						<p fxFlex="nogrow">Telefono: {{address.phone}}</p>
					</mat-card-content>
					<mat-card-actions align="end">
						<button mat-icon-button type="button" (click)="deleteCard(address.id)" aria-label="Delete address">
							<mat-icon color="warn">delete</mat-icon>
						</button>
					</mat-card-actions>
				</mat-card>
			</mat-grid-tile>
		</mat-grid-list>
	</mat-card-content>
</mat-card>