import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {environment} from '../../../environments/environment';
import {AppService} from '../../app.service';

@Component({
	selector:'bendita-drawer',
	templateUrl:'./drawer.component.html',
	styleUrls:['./drawer.component.scss']
})
export class DrawerComponent implements OnInit{
	@Output() public drawerClosed:EventEmitter<any>=new EventEmitter<any>();
	@Input() drawerInput?:MatDrawer;
	appService:AppService;
	environment:any;
	
	constructor(appService:AppService){
		this.appService=appService;
		this.drawerInput=undefined;
		this.environment=environment;
	}
	
	ngOnInit():void{
	}
	
	closeMenu():void{
		this.drawerClosed.emit();
	}
	
	
}
