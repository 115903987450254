import {Component,Input,OnInit} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../app.service';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {LoginPopupComponent} from '../../authentication/login-popup/login-popup.component';
import {Router} from '@angular/router';
import {SignupPopupComponent} from '../../authentication/signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from '../../authentication/forgot-password-popup/forgot-password-popup.component';
import {SearchItemsRequestDto} from '../../common/dto/search-items-request.dto';

@Component({
	selector:'bendita-header',
	templateUrl:'./header.component.html',
	styleUrls:['./header.component.scss']
})
export class HeaderComponent implements OnInit{
	private matDialog:MatDialog;
	private router:Router;
	@Input() drawerInput?:MatDrawer;
	appService:AppService;
	searchQuery?:string;
	products?:any[];
	
	constructor(matDialog:MatDialog,router:Router,appService:AppService){
		this.matDialog=matDialog;
		this.router=router;
		this.appService=appService;
		this.drawerInput=undefined;
		this.searchQuery=undefined;
		this.products=undefined;
	}
	
	ngOnInit():void{
	}
	
	openLogin():void{
		let dialogRef:MatDialogRef<LoginPopupComponent>=this.matDialog.open(
			LoginPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openSignup():void{
		let dialogRef:MatDialogRef<SignupPopupComponent>=this.matDialog.open(
			SignupPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openForgotPassword():void{
		let dialogRef:MatDialogRef<ForgotPasswordPopupComponent>=this.matDialog.open(
			ForgotPasswordPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	searchProducts():void{
		if(!this.searchQuery){
			this.products=undefined;
		}else{
			let data:SearchItemsRequestDto={limit:5,skip:0,query:this.searchQuery};
			this.appService.searchProducts(data)
			.subscribe((result:any):void=>{
				this.products=result.data?.searchProducts;
			});
		}
	}
	
	navigateToSearch():void{
		// this.router.navigate(['/search'],{queryParams:{query:this.searchQuery}});
	}
	
}
