import {AfterViewInit,Component,ElementRef,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {BlogService} from './blog.service';
import {BehaviorSubject,Observable,scan} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AppService} from '../app.service';

@Component({
	selector:'bendita-blog',
	templateUrl:'./blog.component.html',
	styleUrls:['./blog.component.scss']
})
export class BlogComponent implements OnInit,AfterViewInit,OnDestroy{
	readonly blogService:BlogService;
	private fetchBlogs:BehaviorSubject<void>;
	fetchBlogsObserver?:IntersectionObserver;
	public blogPosts:Observable<any[]>;
	@ViewChild('autoScrollElement') autoScrollElement?:ElementRef;
	itemsPerPage:number;
	appService:AppService;
	title:string;
	
	constructor(
		blogService:BlogService,
		appService:AppService
	){
		this.title='Bendita - Blog';
		this.blogService=blogService;
		this.appService=appService;
		this.fetchBlogs=new BehaviorSubject<void>(undefined);
		this.fetchBlogsObserver=undefined;
		this.itemsPerPage=10;
		this.blogPosts=this.fetchBlogs.pipe(
			switchMap((currentValue:void,index:number):Observable<any>=>this.blogService.getBlogPosts({limit:this.itemsPerPage,skip:index*this.itemsPerPage})),
			scan((all:any[],page:any[]):any[]=>{
				if(page.length===0 || page.length!==this.itemsPerPage) this.fetchBlogs.complete();
				return all.concat(page);
			},[])
		);
	}
	
	ngOnInit():void{
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
		
	}
	
	ngAfterViewInit():void{
		if(this.autoScrollElement){
			this.fetchBlogsObserver=new IntersectionObserver(
				(entries:IntersectionObserverEntry[]):void=>{
					for(let entry of entries){
						if(entry.isIntersecting){
							this.fetchMore();
							break;
						}
					}
				},
				{threshold:1}
			);
			setTimeout(():void=>{this.fetchBlogsObserver?.observe(this.autoScrollElement?.nativeElement);},3000);
		}
	}
	
	fetchMore():void{
		this.fetchBlogs.next();
	}
	
	ngOnDestroy():void{
	
	}
	
}
