<mat-card fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center">
	<mat-card fxFlex="65" fxFlex.lt-md="100">
		<mat-card-content fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between none">
			<form fxFlex [formGroup]="updateUserForm" (ngSubmit)="updateUser()" fxLayout="column">
				<mat-card class="mat-elevation-z0">
					<mat-card-header>
						<mat-card-title>
							Actualizar mis datos
						</mat-card-title>
					</mat-card-header>
					<mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
						<mat-form-field fxFlex floatLabel="auto">
							<mat-label>Nombre</mat-label>
							<input matInput type="text" placeholder="Nombre" name="firstName" formControlName="firstName" benditaInputEmptyToNull required>
						</mat-form-field>
						<mat-form-field fxFlex floatLabel="auto">
							<mat-label>Apellido</mat-label>
							<input matInput type="text" placeholder="Apellido" name="lastName" formControlName="lastName" benditaInputEmptyToNull required>
						</mat-form-field>
					</mat-card-content>
					<mat-card-actions align="start">
						<button type="submit" mat-raised-button color="primary" [disabled]="!submitButtonActive">Enviar</button>
					</mat-card-actions>
				</mat-card>
			</form>
			<form fxFlex [formGroup]="updateEmailForm" (ngSubmit)="updateEmail()" fxLayout="column">
				<mat-card class="mat-elevation-z0">
					<mat-card-header>
						<mat-card-title>
							Vincula tu email
						</mat-card-title>
					</mat-card-header>
					<mat-card-content fxLayout="column">
						<div fxFlex fxLayout="column">
							<div *ngIf="emailVerificationToken" fxFlex fxLayout="column">
								<span fxFlex fxLayout="row" fxLayoutAlign="start center">
									<mat-icon fxFlex="nogrow" fxFlex="nogrow" color="warn">closed</mat-icon>
									<span fxFlex="nogrow">Email no verificado</span>
								</span>
								<b fxFlex>{{emailVerificationToken.result}}</b>
								<span fxFlex="nogrow">Se ha enviado un correo de verificacion</span>
							</div>
							<mat-form-field fxFlex floatLabel="auto">
								<mat-label *ngIf="updateEmailForm.get('email')?.pristine && updateEmailForm.get('email')?.value">
									<mat-icon color="primary">check</mat-icon> Email verificado
								</mat-label>
								<input matInput type="email" placeholder="Email" name="email" formControlName="email" benditaInputEmptyToNull required>
							</mat-form-field>
						</div>
						<div *ngIf="updateEmailForm.get('email')?.dirty" fxFlex fxFlexOffset="5px" fxLayout="column" fxLayoutGap="5px">
							<span fxFlex="nogrow">Se enviara un link de confirmacion por Email.</span>
						</div>
						<div fxFlex>
							<bendita-form-message [serverErrorInput]="updateEmailServerError" [serverResponseInput]="updateEmailServerResponse"></bendita-form-message>
						</div>
					</mat-card-content>
					<mat-card-actions align="start">
						<button type="submit" mat-raised-button color="primary" [disabled]="!submitButtonActive">Enviar</button>
					</mat-card-actions>
				</mat-card>
			</form>
			<form fxFlex [formGroup]="addPhoneForm" fxLayout="column">
				<mat-card class="mat-elevation-z0">
					<mat-card-header>
						<mat-card-title>
							Vincula tu numero celular
						</mat-card-title>
					</mat-card-header>
					<mat-card-content fxLayout="column">
						<ng-container *ngIf="!phoneConfirmationResult">
							<div fxFlex fxLayout="row" fxLayout.lt-lg="column">
								<mat-form-field fxFlex="100px" fxFlex.lt-lg floatLabel="auto">
									<mat-label>Codigo del pais</mat-label>
									<mat-select formControlName="phoneCountryCode" matTooltip="Codigo del pais" [required]="true">
										<mat-option value="+57" >+57</mat-option>
										<mat-option value="+1" >+1</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field fxFlex="auto" fxFlexOffset="5px" floatLabel="auto">
									<mat-label *ngIf="addPhoneForm.get('phone')?.pristine && addPhoneForm.get('phone')?.value">
										<mat-icon color="primary">check</mat-icon> Telefono verificado
									</mat-label>
									<input matInput type="tel" placeholder="Telefono" name="phone" formControlName="phone" benditaInputEmptyToNull required>
								</mat-form-field>
							</div>
							<div *ngIf="addPhoneForm.get('phone')?.dirty" fxFlex fxFlexOffset="5px" fxLayout="column" fxLayoutGap="5px">
								<div id="verify-phone-recaptcha-container" fxFlex></div>
								<span fxFlex="nogrow">Se enviara un codigo de confirmacion por SMS, se aplicaran cargos estandar.</span>
								<button *ngIf="!recaptchaVerifier" fxFlex="nogrow" mat-button class="app-primary" (click)="getOtp()">Verificar telefono</button>
							</div>
						</ng-container>
						<ng-container *ngIf="phoneConfirmationResult">
							<div *ngIf="addPhoneForm.get('phone')?.dirty" fxFlex fxFlexOffset="5px" fxLayout="column" fxLayoutGap="5px">
								<p fxFlex="nogrow">Ingresa el codigo enviado al numero {{addPhoneForm.get('phoneCountryCode')?.value}} {{addPhoneForm.get('phone')?.value}}</p>
								<mat-form-field fxFlex="nogrow" floatLabel="auto">
									<mat-label>Codigo</mat-label>
									<input #PhoneVerificationCodeInput matInput type="number" placeholder="Codigo" name="PhoneVerificationCode">
								</mat-form-field>
								<div fxFlex fxLayout="column">
									<button fxFlex="nogrow" mat-button class="app-primary" (click)="addPhone(PhoneVerificationCodeInput.value)">Confirmar</button>
								</div>
							</div>
						</ng-container>
						<div fxFlex>
							<bendita-form-message [serverErrorInput]="addPhoneServerError" [serverResponseInput]="addPhoneServerResponse"></bendita-form-message>
						</div>
					</mat-card-content>
				</mat-card>
			</form>
		</mat-card-content>
	</mat-card>
	<mat-card fxFlex="35" fxFlex.lt-md="100">
		<mat-card-header>
			<mat-card-title>
				<h1>Opciones avanzadas</h1>
			</mat-card-title>
		</mat-card-header>
		<mat-card-content fxLayout="column" fxLayoutGap="20px">
			<form fxFlex [formGroup]="resetPasswordForm" (ngSubmit)="updatePassword()" fxLayout="column">
			<mat-card class="mat-elevation-z0">
				<mat-card-header>
					<mat-card-title>
						Cambiar mi contraseña
					</mat-card-title>
					<mat-card-subtitle>
						Ingresa tu contraseña actual y tu nueva contraseña
					</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content fxLayout="column" fxLayoutGap="10px">
					<mat-form-field fxFlex floatLabel="auto">
						<mat-label>Contraseña</mat-label>
						<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" benditaInputEmptyToNull required>
					</mat-form-field>
					<mat-form-field fxFlex floatLabel="auto">
						<mat-label>Nueva contraseña</mat-label>
						<input matInput type="password" placeholder="Nueva contraseña" name="newPassword" formControlName="newPassword" benditaInputEmptyToNull required>
					</mat-form-field>
				</mat-card-content>
				<mat-card-actions align="start">
					<button type="submit" mat-raised-button color="primary" [disabled]="!submitButtonActive">Enviar</button>
				</mat-card-actions>
			</mat-card>
			</form>
			<mat-card fxFlex class="mat-elevation-z0">
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon class="app-36" color="primary">download</mat-icon>
					</div>
					<mat-card-title>
						Exportar mis datos
					</mat-card-title>
					<mat-card-subtitle>
						Exporta toda la informacion de tu cuenta.
					</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
					<ng-container *ngIf="!personalFile;else fileExists">
						<button fxFlex="nogrow" fxFlexOffset="10px" mat-button matRipple class="app-primary" (click)="createPersonalFile()" style="overflow: hidden;">Exportar</button>
					</ng-container>
					<ng-template #fileExists>
						<p fxFlex fxFlexOffset="10px">Descarga tu informacion personal <a [href]="personalFile.result" target="_blank">aqui</a></p>
						<p fxFlex>El archivo se eliminara automaticamente despues de 3 dias</p>
					</ng-template>
				</mat-card-content>
			</mat-card>
			<mat-card fxFlex class="mat-elevation-z0">
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon class="app-36" color="warn">warning</mat-icon>
					</div>
					<mat-card-title>
						Borrar cuenta
					</mat-card-title>
					<mat-card-subtitle>
						Elimina toda la informacion de tu cuenta.
					</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content fxLayout="column" fxLayoutAlign="center center">
					<button fxFlex="nogrow" mat-button matRipple class="app-warn" (click)="deleteUser()" style="overflow: hidden;">Borrar mi cuenta</button>
				</mat-card-content>
			</mat-card>
		</mat-card-content>
	</mat-card>
</mat-card>