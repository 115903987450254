import {Component,Inject,OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
	selector:'bendita-cart-item-created-snack',
	templateUrl:'./cart-item-created-snack.component.html',
	styleUrls:['./cart-item-created-snack.component.scss']
})
export class CartItemCreatedSnackComponent implements OnInit{
	data?:any;
	
	constructor(@Inject(MAT_SNACK_BAR_DATA) data:any){
		this.data=data;
	}
	
	ngOnInit():void{
	}
}
