<mat-sidenav-container>
	<mat-sidenav #drawer fixedInViewport role="dialog" mode="over">
		<app-drawer [drawerInput]="drawer" (drawerClosed)="drawer.close()"></app-drawer>
	</mat-sidenav>
	<mat-sidenav-content>
		<app-header [drawerInput]="drawer"></app-header>
		<router-outlet></router-outlet>
		<app-footer></app-footer>
	</mat-sidenav-content>
</mat-sidenav-container>
