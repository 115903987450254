<div mat-dialog-title fxLayout="row">
	<h1 fxFlex>Añadir direccion de envio</h1>
	<div fxFlex="nogrow">
		<mat-icon (click)="close()" class="app-pointer">cancel</mat-icon>
	</div>
</div>
<form [formGroup]="form">
	<div mat-dialog-content fxLayout="column" fxLayoutAlign="start none" fxLayoutGap="10px">
		<mat-form-field fxFlex floatLabel="auto">
			<input matInput type="text" placeholder="Nombre" name="name" formControlName="name" benditaInputEmptyToNull>
		</mat-form-field>
		<div fxFlex>
			<bendita-google-places-autocomplete (setAddress)="getAddress($event)" [addressExisting]="data.address?.line1"></bendita-google-places-autocomplete>
		</div>
		<div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
			<google-map *ngIf="place || data.address"
									height="300px"
									width="300px"
									[options]="mapOptions"
									[center]="{
									lat:this.data.address?.location?.coordinates[1] || place.geometry.location.lat(),
									lng:this.data.address?.location?.coordinates[0] || place.geometry.location.lng()
								}"
									[zoom]="17">
				<map-marker [position]="{
									lat:this.data.address?.location?.coordinates[1] || place.geometry.location.lat(),
									lng:this.data.address?.location?.coordinates[0] || place.geometry.location.lng()
								}"></map-marker>
			</google-map>
		</div>
		<div fxFlex fxLayout="column">
			<mat-form-field fxFlex floatLabel="auto">
				<input matInput type="text" placeholder="Referencia adicional Ej. Apt 201" name="line2" formControlName="line2" benditaInputEmptyToNull>
			</mat-form-field>
			<div fxFlex fxLayout="row" fxLayout.lt-lg="column">
				<mat-form-field fxFlex="100px" floatLabel="auto">
					<mat-label>Codigo del pais</mat-label>
					<mat-select  value="+57" formControlName="phoneCountryCode" matTooltip="Codigo del pais" [required]="true">
						<mat-option value="+57" >+57</mat-option>
						<mat-option value="+1" >+1</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field fxFlex floatLabel="auto" fxFlexOffset="5px">
					<input matInput type="tel" placeholder="Telefono" name="phone" formControlName="phone" benditaInputEmptyToNull required>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div mat-dialog-actions align="end">
		<button *ngIf="this.data.address;else noDataAddress" mat-button matRipple class="app-primary" (click)="updateAddress()">
			Guardar
		</button>
		<ng-template #noDataAddress>
			<button mat-button matRipple class="app-primary" (click)="createAddress()">
				Crear
			</button>
		</ng-template>
	</div>
</form>
