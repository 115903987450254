<mat-card>
	<mat-card-title fxLayout="row" fxLayoutAlign="center center">
		<h1 fxFlex="nogrow">Recuperar contraseña</h1>
	</mat-card-title>
	<mat-card-content>
		<div fxLayout="column" fxLayoutAlign="space-evenly center">
			<div fxFlex fxFlexOffset="30px">
				<p>Introduce nueva contraseña:</p>
			</div>
			<div fxFlex class="form-container">
				<form [formGroup]="form" (ngSubmit)="resetPassword()" fxLayout="column">
					<mat-form-field fxFlex="grow" floatLabel="auto">
						<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" benditaInputEmptyToNull required>
					</mat-form-field>
					<mat-form-field fxFlex="grow" floatLabel="auto">
						<input matInput type="password" placeholder="Confirmar contraseña" name="password2" formControlName="password2" benditaInputEmptyToNull required>
					</mat-form-field>
					<div fxFlex>
						<bendita-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></bendita-form-message>
					</div>
					<div fxFlex fxLayout="column" fxLayoutAlign="center center">
						<button fxFlex type="submit" mat-button class="app-primary">Enviar</button>
					</div>
				</form>
			</div>
		</div>
	</mat-card-content>
</mat-card>

