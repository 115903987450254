import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo,gql} from 'apollo-angular';
import {CreateOrderRequestDto} from './dto/create-order-request.dto';
import {GetOrderRequestDto} from './dto/get-order-request.dto';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {GetItemRequestDto} from '../common/dto/get-item-request.dto';
import {ApprovePaypalOrderRequestDto} from './dto/approve-paypal-order-request.dto';
import {CreateMercadoPagoPaymentRequestDto} from './dto/create-mercado-pago-payment-request.dto';

@Injectable({
	providedIn:'root'
})
export class CheckOutService{
	private readonly apollo:Apollo;
	private httpClient:HttpClient;
	
	constructor(apollo:Apollo,httpClient:HttpClient){
		this.apollo=apollo;
		this.httpClient=httpClient;
		
	}
	
	getShippingTypes():Observable<any>{
		return this.apollo
		.query({
			query:gql`query getShippingTypes{
          getShippingTypes{
              updatedAt
              id
              createdAt
              label
              icon
          }
      }`
		});
	}
	
	getPaymentTypes():Observable<any>{
		return this.apollo
		.query({
			query:gql`query getPaymentTypes{
          getPaymentTypes{
              updatedAt
              id
              createdAt
              label
              icon
              description
          }
      }`
		});
	}
	
	createOrder(variables:CreateOrderRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`mutation createOrder(
          $cart: String!,
          $name: String!,
          $shippingAddress: CreateOrderAddressRequestDto,
          $email: String!,
          $paymentType: String!,
          $shippingType: String!,
          $phone: String!
          $store: String
      ){
          createOrder(
              cart: $cart,
              name: $name,
              shippingAddress: $shippingAddress,
              email: $email,
              paymentType: $paymentType,
              shippingType: $shippingType
              phone: $phone
              store: $store
          ){
              slug
              updatedAt
              id
              createdAt
              guestToken
							transactionId
							paymentType {
									id
									label
              }
							openNodeCharge {
									id
              }
          }
      }`
		});
	}
	
	getOrder(variables:GetOrderRequestDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`query getOrder($id:String!,$guestToken:String){
          getOrder(id:$id,guestToken:$guestToken){
              updatedAt
              id
              createdAt
              slug
              status {
                  id
                  order
                  label
                  description
                  icon
              }
              paymentType {
									id
                  label
                  icon
                  description
                  descriptionLong
              }
              shippingType {
                  label
                  id
                  icon
              }
              email
              name
              shippingAddress {
                  name
                  city
                  country
                  line2
                  phone
                  state
                  zip
                  line1
              }
              subtotal
              discount
              shipping
              total
              items {
                  product {
                      name
                      id
                      picture
                      discountPercent
                      price
                  }
                  quantity
              }
              user {
                  id
              }
              phone
              guestToken
							store {
									id
									city
									country
									createdAt
									line1
									line2
									location {
											coordinates
                  }
									name
									phone
									placeId
									state
									updatedAt
									zip
              }
							transactionId
							openNodeCharge {
									address
									amount
									auto_settle
									chain_invoice {
											address
											settled_at
											tx
                  }
									created_at
									currency
									description
									expires_at
									fiat_value
									id
									metadata {
											email
											invoice_id
                  }
									missing_amt
									order_id
									source_fiat_value
									status
									transactions {
											status
											created_at
											tx
											settled_at
											address
											amount
                  }
              }
							guestToken
          }
      }`
		}).pipe(map((result:any):any=>result.data?.getOrder));
	}
	
	approvePaypalOrder(variables:ApprovePaypalOrderRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`mutation approvePaypalOrder(
          $id: String!
      ){
          approvePaypalOrder(
              id: $id
          ){
              updatedAt
              id
							
          }
      }`
		});
	}
	
	createMercadoPagoPayment(variables:CreateMercadoPagoPaymentRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`mutation createMercadoPagoPayment(
          $id: String!
          $issuer_id: String!
          $payer: Payer!
          $payment_method_id: String!
          $token: String!
      ){
          createMercadoPagoPayment(
              id: $id
							issuer_id: $issuer_id
							payer: $payer
							payment_method_id: $payment_method_id
							token: $token
          ){
              updatedAt
              id
							
          }
      }`
		});
	}
	
}
