@defer (on immediate) {
	@if (productsError()) {
		<mat-icon class="app-error-color" matTooltip="{{productsError()}}">error</mat-icon>
		<mat-progress-bar mode="buffer" class="app-error"></mat-progress-bar>
	}
	<div class="container-fixed">
		<div class="container">
			<mat-spinner *ngIf="productsLoading()"></mat-spinner>
			@for (product of products(); track product.id) {
				<div class="product-container stretch-badge" [attr.aria-label]="product.name" matBadge="Ahorra: {{product.discountPercent}}%" [matBadgeHidden]="!product.discountPercent" matBadgeSize="large">
					<mat-card class="product-card">
						<img mat-card-image [src]="product.pictures[0].small" [alt]="product.name" class="product-image" [routerLink]="['/products',product.id]" mat-ripple>
						<mat-card-footer class="product-card-footer" [routerLink]="['/products',product.slug || product.id]" mat-ripple>
							<span class="app-pointer product-name app-14">{{product.name}}</span>
							@if (product.discountPercent) {
								<span class="product-price app-14">
									{{(product.price-(product.price*(product.discountPercent/100))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
								</span>
								<span class="discount-chip app-14">{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span>
							} @else {
								<span class="product-price app-14">{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span>
							}
						</mat-card-footer>
						<mat-card-actions class="product-card-actions">
							<app-cart-item-quantity [product]="product" [submitButtonActive]="submitButtonActive()" (submitButtonActiveO)="submitButtonActiveO.emit($event)"></app-cart-item-quantity>
						</mat-card-actions>
					</mat-card>
				</div>
			}
			<div *ngIf="products()?.length!==0" class="view-more">
				<button [routerLink]="urlTree()" class="app-120 app-primary-color" mat-icon-button>
					<mat-icon>arrow_forward</mat-icon>
				</button>
				<a [routerLink]="urlTree()">Ver mas</a>
			</div>
		</div>
	</div>
} @placeholder {
	<div class="visually-hidden">
		@for (product of products(); track product.id) {
			<a [routerLink]="['/products',product.slug || product.id]" [attr.aria-label]="product.name" matBadge="Ahorra: {{product.discountPercent}}%" [matBadgeHidden]="!product.discountPercent">
				<img [src]="product.pictures[0].small" [alt]="product.name">
				<h3>{{product.name}}</h3>
				@if (product.discountPercent) {
					<h3>
						{{(product.price-(product.price*(product.discountPercent/100))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
						<span class="discount-chip">{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span>
					</h3>
				} @else {
					<h3>{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3>
				}
			</a>
		}
	</div>
}