<mat-grid-list *ngIf="productsInput" [cols]="colsInput" rowHeight="1:1" gutterSize="15">
	<mat-grid-tile *ngFor="let product of productsInput" matBadge="Ahorra: {{product.discountPercent}}%" [matBadgeHidden]="!product.discountPercent" matBadgeColor="warn" class="stretch-badge">
		<a [routerLink]="[product.slug || product.id]" style="width: 100%;height: 100%" class="no-decoration" attr.aria-label="{{product.name}}">
			<mat-card fxLayout="column" style="width: 100%;height: 100%" class="mat-elevation-z3" mat-ripple>
				<mat-card-content fxFlex="grow" class="app-pointer">
					<div style="height:100%;background-position: center; background-size: cover;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+product.pictures[0].small+')'}"></div>
				</mat-card-content>
				<mat-card-footer fxFlex="noshrink">
					<div fxLayout="column" fxLayoutAlign="start center">
						<div fxFlex>
							<h3 class="app-pointer" style="font-weight: bold">{{product.name}}</h3>
						</div>
						<div fxFlex style="text-align: center">
							<ng-container *ngIf="product.discountPercent;else noDiscountTemplate">
								<h3 style="font-weight: bold">
									{{(product.price-(product.price*(product.discountPercent/100))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
									&nbsp;
									<span class="discount-chip">{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span>
								</h3>
							</ng-container>
							<ng-template #noDiscountTemplate>
								<h3 style="font-weight: bold">{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h3>
							</ng-template>
						</div>
					</div>
				</mat-card-footer>
			</mat-card>
		</a>
		
	</mat-grid-tile>
</mat-grid-list>