<div mat-dialog-title fxLayout="row">
	<h1 fxFlex>Crear mi cuenta</h1>
	<div fxFlex="nogrow">
		<mat-icon (click)="close()" class="app-pointer">cancel</mat-icon>
	</div>
</div>
<mat-dialog-content>
	<div fxLayout="column">
		<div fxFlex>
			<p>Por favor complete la información a continuación:</p>
		</div>
		<div fxFlex class="form-container" fxFlexOffset="30px">
			<form [formGroup]="form" (ngSubmit)="signUp()" fxLayout="column">
				<mat-form-field fxFlex floatLabel="auto">
					<input matInput type="text" placeholder="Nombre" name="firstName" formControlName="firstName" benditaInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field fxFlex floatLabel="auto">
					<input matInput type="text" placeholder="Apellido" name="lastName" formControlName="lastName" benditaInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field fxFlex floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" benditaInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field fxFlex floatLabel="auto">
					<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" benditaInputEmptyToNull required>
				</mat-form-field>
				<div fxFlex>
					<bendita-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></bendita-form-message>
				</div>
				<div fxFlex fxLayout="column" fxLayoutAlign="center center">
					<button fxFlex type="submit" mat-button class="app-primary">Crear cuenta</button>
				</div>
			</form>
		</div>
		<div fxFlex fxLayout="column" fxFlexAlign="center" fxFlexOffset="30px">
			<p fxFlex fxFlexAlign="center">Ya tienes una cuenta? <a [routerLink]="['/login']" (click)="close(true)">Entra aqui</a></p>
			<p fxFlex fxFlexAlign="center">Contraseña olvidada? <a [routerLink]="['/forgot-password']" (click)="close(true)">Recuperar contraseña</a></p>
		</div>
	</div>
</mat-dialog-content>
