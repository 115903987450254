import {Component,Injector} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute,Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {firstValueFrom} from 'rxjs';
import {SnackMessageComponent} from '../../misc/components/snack-message/snack-message.component';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';

@Component({
	selector:'app-login-popup',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./login-popup.component.html',
	styleUrl:'./login-popup.component.scss'
})
export class LoginPopupComponent extends PopupComponent{
	private matDialogRef:MatDialogRef<LoginPopupComponent>;
	private activatedRoute:ActivatedRoute;
	private router:Router;
	private authenticationService:AuthenticationService;
	private matSnackBar:MatSnackBar;
	email:FormControl;
	password:FormControl;
	form:FormGroup;
	serverError?:any;
	serverResponse?:string;
	submitButtonActive:boolean;
	
	constructor(
		injector:Injector,
		matDialogRef:MatDialogRef<LoginPopupComponent>,
		activatedRoute:ActivatedRoute,
		router:Router,
		authenticationService:AuthenticationService,
		matSnackBar:MatSnackBar
	){
		super(injector);
		this.matDialogRef=matDialogRef;
		this.activatedRoute=activatedRoute;
		this.router=router;
		this.authenticationService=authenticationService;
		this.matSnackBar=matSnackBar;
		this.submitButtonActive=true;
		this.serverError=undefined;
		this.serverResponse=undefined;
		this.email=new FormControl(undefined,[
			Validators.required,
			Validators.email
		]);
		this.password=new FormControl(undefined,[
			Validators.required
		]);
		this.form=new FormGroup({
			email:this.email,
			password:this.password
		});
		
		this.matDialogRef.backdropClick().subscribe(():void=>{
			this.close();
		});
		
	}
	
	override async close(timeout?:boolean):Promise<void>{
		let redirectUrl:string|undefined;
		if(!timeout)
			if(this.activatedRoute.firstChild)
				if((await firstValueFrom(this.activatedRoute.firstChild.url))[0]?.path==='login') redirectUrl='/';
		if(timeout) setTimeout(():void=>this.matDialogRef.close({redirectUrl}),1000);
		else this.matDialogRef.close({redirectUrl});
	}
	
	login():void{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive=false;
				this.serverError=undefined;
				this.serverResponse=undefined;
				const next=async(user:any):Promise<void>=>{
					this.submitButtonActive=true;
					if((await firstValueFrom(this.activatedRoute.url))[0].path==='login') this.matDialogRef.close({redirectUrl:'/profile'});
					else{
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverResponseInput:'Bienvenido/a '+user.firstName
							}
						});
						this.matDialogRef.close();
					}
				};
				const error=(error:any):void=>{
					this.submitButtonActive=true;
					this.serverError=error;
				};
				this.authenticationService.login({
					email:this.form.get('email')?.value,
					password:this.form.get('password')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
	
}
