import {Component,Inject,Injector} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProfileService} from '../../profile.service';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {PopupComponent} from '../../../shared/popups/popup.component';

@Component({
	selector:'bendita-add-card-popup',
	templateUrl:'./add-card-popup.component.html',
	styleUrls:['./add-card-popup.component.scss']
})
export class AddCardPopupComponent extends PopupComponent{
	private matDialogRef:MatDialogRef<AddCardPopupComponent>;
	private matSnackBar:MatSnackBar;
	private readonly profileService:ProfileService;
	private readonly formBuilder:FormBuilder;
	form:FormGroup;
	submitButtonActive:boolean;
	public data:any;
	
	constructor(
		injector:Injector,
		matDialogRef:MatDialogRef<AddCardPopupComponent>,
		matSnackBar:MatSnackBar,
		profileService:ProfileService,
		formBuilder:FormBuilder,
		@Inject(MAT_DIALOG_DATA) data:any
	){
		super(injector);
		this.matDialogRef=matDialogRef;
		this.matSnackBar=matSnackBar;
		this.profileService=profileService;
		this.formBuilder=formBuilder;
		this.submitButtonActive=true;
		this.form=this.formBuilder.group({
			name:[data.name,[Validators.required]],
			number:[data.number,[Validators.required]],
			expirationYear:[data.expirationYear,[Validators.required]],
			expirationMonth:[data.expirationMonth,[Validators.required]],
			securityCode:[data.securityCode,[Validators.required]]
		});
		this.data=data;
		
		this.matDialogRef.backdropClick().subscribe(():void=>{
			this.close();
		});
		
	}
	
	override close():void{
		this.matDialogRef.close();
	}
	
	
}
