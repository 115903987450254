<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
	<mat-card>
		<mat-card-content fxLayout="column">
			<mat-form-field fxFlex>
				<input matInput placeholder="Nombre" formControlName="name">
				<mat-error *ngIf="form.controls['name'].hasError('required')">
					Nombre es <strong>obligatorio</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<input matInput placeholder="Ciudad" formControlName="city">
				<mat-error *ngIf="form.controls['city'].hasError('required')">
					Ciudad es <strong>obligatoria</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<input matInput placeholder="Telefono" formControlName="phone">
				<mat-error *ngIf="form.controls['phone'].hasError('required')">
					Telefono es <strong>obligatorio</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<input matInput type="email" placeholder="Email" formControlName="email">
				<mat-error *ngIf="form.controls['email'].hasError('required')">
					Email es <strong>obligatorio</strong>
				</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<textarea matInput placeholder="Mensaje" formControlName="message"></textarea>
				<mat-error *ngIf="form.controls['message'].hasError('required')">
					Mensaje es <strong>obligatorio</strong>
				</mat-error>
			</mat-form-field>
			<div id="recaptcha-container" fxFlex></div>
			<ng-container *ngIf="form.disabled">
				<h3 fxFlex>El mensaje ha sido enviado</h3>
			</ng-container>
		</mat-card-content>
		<mat-card-actions>
			<button mat-raised-button color="primary" type="submit">Enviar</button>
		</mat-card-actions>
	</mat-card>
</form>
