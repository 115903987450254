import {NgModule} from '@angular/core';
import {ApolloModule,APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions,ApolloLink,InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {ErrorResponse,onError} from '@apollo/client/link/error';
import {GraphQLError} from 'graphql';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@NgModule({
	exports:[ApolloModule],
	providers:[
		{
			provide:APOLLO_OPTIONS,
			useFactory:(
				httpLink:HttpLink
			):ApolloClientOptions<any>=>{
				const link:ApolloLink=onError((error:ErrorResponse):void=>{
					if(error.graphQLErrors){
						error.graphQLErrors.forEach((error:GraphQLError):void=>console.error(`[GraphQL error]:`,error));
					}
					if(error.networkError) console.error('[Network error]:',error.networkError);
				});
				return {
					link:link.concat(httpLink.create({uri:`${environment.apiServer.url}/graphql`,withCredentials:true})),
					cache:new InMemoryCache(),
					defaultOptions:{
						watchQuery:{
							fetchPolicy:'no-cache',
							errorPolicy:'all'
						},
						query:{
							fetchPolicy:'no-cache',
							errorPolicy:'all'
						},
						mutate:{
							fetchPolicy:'no-cache',
							errorPolicy:'none'
						}
					}
				};
			},
			deps:[HttpLink,HttpClient]
		}
	]
})
export class GraphQLModule{}
