<footer>
		<div class="newsletter-container">
			<h2>Subscríbete y disfruta los resultados</h2>
			<p class="newsletter-subscription-text">
				Todo lo que necesitas saber sobre la forma más eficiente de cultivar en un solo lugar, suscríbete a nuestro
				boletín informativo.
			</p>
			<iframe src="https://cdn.forms-content.sg-form.com/b42b0ba0-aba7-11ed-84e9-eaea2d7a5942" class="newsletter-iframe"></iframe>
		</div>
		<div class="social-links-container">
			<h2>Siguenos</h2>
			<div class="social-links">
				<a mat-icon-button class="social-links-item mat-elevation-z1" href="https://wa.me/{{environment.contact.phone.replace('+','')}}" target="_blank" aria-label="Whatsapp">
					<mat-icon aria-label="whatsapp" [svgIcon]="'app-whatsapp'" class="social-links-item-icon"></mat-icon>
				</a>
				<a mat-icon-button class="social-links-item mat-elevation-z1" [href]="environment.contact.instagram" target="_blank" aria-label="Intagram">
					<mat-icon aria-label="instagram" [svgIcon]="'app-instagram'" class="social-links-item-icon"></mat-icon>
				</a>
				<a mat-icon-button class="social-links-item mat-elevation-z1" [href]="environment.contact.facebook" target="_blank" aria-label="Facebook">
					<mat-icon aria-label="facebook" [svgIcon]="'app-facebook'" class="social-links-item-icon"></mat-icon>
				</a>
				<a mat-icon-button class="social-links-item mat-elevation-z1" [href]="environment.contact.twitter" target="_blank" aria-label="Twitter">
					<mat-icon aria-label="twitter" [svgIcon]="'app-twitter'" class="social-links-item-icon"></mat-icon>
				</a>
			</div>
		</div>
</footer>
