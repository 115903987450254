import {Component,HostListener,Injector} from '@angular/core';
import {Location} from '@angular/common';
import {AppService} from '../../app.service';

@Component({
	template:''
})
export class PopupComponent{
	private injector:Injector;
	location:Location;
	appService:AppService;
	currentUrl:string;
	
	constructor(injector:Injector){
		this.injector=injector;
		this.location=this.injector.get(Location);
		this.appService=this.injector.get(AppService);
		this.currentUrl=this.location.path();
	}
	
	@HostListener('window:popstate',['$event'])
	closeWithBackButton():void{
		this.location
		.subscribe(():void=>{
			setTimeout(this.close.bind(this),100);
		});
		this.location.forward();
	}
	
	@HostListener('window:keyup.esc') onKeyUp():void{
		this.close();
	}
	
	
	close():void{}
	
}
