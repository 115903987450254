@defer (on immediate) {
	<gallery [items]="pictures"
					 bullets
					 [autoplayInterval]="30*1000"
	></gallery>
} @placeholder {
	<div class="visually-hidden">
		@for (picture of pictures; track picture.data.src) {
			@if (picture.type==='image') {
				<img [src]="'https://www.benditagrow.com'+picture.data.src" alt="Bendita" width="500" height="500" priority>
			} @else if (picture.type==='video') {
				<video [src]="'https://www.benditagrow.com'+picture.data.src" [poster]="'https://www.benditagrow.com'+picture.data.poster"></video>
			}
		}
	</div>
}
<mat-card>
	<mat-card-content class="home-content">
		<div class="details-container">
			<mat-card class="details-card">
				<mat-card-header>
					<mat-card-title class="app-primary-color">Preguntas frecuentes sobre cultivo</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<p>Conoce las inquietudes y características más comunes sobre el cultivo y las prácticas más efectivas.</p>
					<a [routerLink]="['/blog']">Aprender más</a>
				</mat-card-content>
			</mat-card>
			<mat-card class="details-card">
				<mat-card-header>
					<mat-card-title class="app-primary-color">Reservar una asesoría personalizada</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<p>Agenda tu asesoría online para que logres todas tus expectativas evitando los errores más comunes del cultivo.</p>
					<a href="https://wa.me/{{environment.contact.phone.replace('+','')}}" target="_blank">Reservar ahora</a>
				</mat-card-content>
			</mat-card>
		</div>
		<span class="app-20">Recomendaciones:</span>
		<app-products-slider [products]="products()" [productsLoading]="productsLoading()" [productsError]="productsError()" [submitButtonActive]="submitButtonActive()" (submitButtonActiveO)="submitButtonActive.set($event)" [urlTree]="productsUrltree"></app-products-slider>
		<div class="specialty-container-fixed">
			<div class="specialty-container">
				<mat-card class="specialty-card">
					<mat-card-header>
						<mat-icon mat-card-avatar class="specialty-icon">inventory_2</mat-icon>
						<mat-card-title class="specialty-title">ENVÍOS A TODO COLOMBIA</mat-card-title>
					</mat-card-header>
					<mat-card-content class="specialty-content">Hacemos envíos a todo el país y ofrecemos todos los métodos de pago online</mat-card-content>
				</mat-card>
				<mat-card class="specialty-card">
					<mat-card-header>
						<mat-icon mat-card-avatar class="specialty-icon">shopping_cart_checkout</mat-icon>
						<mat-card-title class="specialty-title">POLÍTICAS DE GARANTÍA</mat-card-title>
					</mat-card-header>
					<mat-card-content class="specialty-content">Realiza tu proceso de garantía para darte una solución satisfactoria</mat-card-content>
				</mat-card>
				<mat-card class="specialty-card">
					<mat-card-header>
						<mat-icon mat-card-avatar class="specialty-icon">support_agent</mat-icon>
						<mat-card-title class="specialty-title">ASESORÍAS CON EXPERTOS</mat-card-title>
					</mat-card-header>
					<mat-card-content class="specialty-content">Solucionamos tus dudas y te ayudamos a tener mejores resultados</mat-card-content>
				</mat-card>
				<mat-card class="specialty-card">
					<mat-card-header>
						<mat-icon mat-card-avatar class="specialty-icon">credit_score</mat-icon>
						<mat-card-title class="specialty-title">PAGOS ONLINE INMEDIATOS Y SEGUROS</mat-card-title>
					</mat-card-header>
					<mat-card-content class="specialty-content">No almacenamos tus datos de pago, nos respalda Mercado Pago</mat-card-content>
				</mat-card>
			</div>
		</div></mat-card-content>
</mat-card>
