import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProductsRoutingModule} from './products-routing.module';
import {ProductsComponent} from './products.component';
import {SharedModule} from '../shared/shared.module';
import {ProductsListComponent} from './products-list/products-list.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';


@NgModule({
	declarations:[
		ProductsComponent,
		ProductsListComponent,
		ProductDetailComponent
	],
	imports:[
		CommonModule,
		ProductsRoutingModule,
		SharedModule
	],
	exports:[
		ProductsListComponent
	]
})
export class ProductsModule{}
