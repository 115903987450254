import {Inject,Injectable,PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable,BehaviorSubject,throwError,firstValueFrom} from 'rxjs';
import {catchError,filter,map,shareReplay,tap} from 'rxjs/operators';
import {Title,Meta,DomSanitizer} from '@angular/platform-browser';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {MediaObserver} from '@angular/flex-layout';
import {CookieService} from 'ngx-cookie-service';
import * as moment from 'moment';
import {Apollo,gql} from 'apollo-angular';
import {OverlayContainer} from '@angular/cdk/overlay';
import {ApolloQueryResult} from '@apollo/client/core';
import {environment} from '../environments/environment';
import {SearchItemsRequestDto} from './common/dto/search-items-request.dto';
import {CreateCartItemRequestDto} from './common/dto/create-cart-item-request.dto';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartItemCreatedSnackComponent} from './shared/cart-item-created-snack/cart-item-created-snack.component';
import {isPlatformServer} from '@angular/common';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {GOOGLE_MAPS,GOOGLE_PLACES,MERCADO_PAGO,PAYPAL,PHONE_NUMBER_UTIL} from './shared/constants';
import {PayPalNamespace} from '@paypal/paypal-js';

@Injectable({
	providedIn:'root'
})
export class AppService{
	private title:Title;
	private meta:Meta;
	private router:Router;
	private httpClient:HttpClient;
	private userSource:BehaviorSubject<any|undefined>;
	private productCategoriesSource:BehaviorSubject<any[]>;
	private storesSource:BehaviorSubject<any[]>;
	private cartSource:BehaviorSubject<any|undefined>;
	private mediaMatcherXSmall:MediaQueryList;
	private mediaMatcherSmall:MediaQueryList;
	private mediaMatcherMedium:MediaQueryList;
	private mediaMatcherLarge:MediaQueryList;
	private mediaMatcherXLarge:MediaQueryList;
	private mediaMatcherDarkTheme:MediaQueryList;
	private readonly apollo:Apollo;
	private overlayContainer:OverlayContainer;
	private matSnackBar:MatSnackBar;
	window:Window;
	localStorage:Storage;
	sessionStorage:Storage;
	cookieService:CookieService;
	breakpointObserver:BreakpointObserver;
	userObservable:Observable<any|undefined>;
	productCategoriesObservable:Observable<any[]>;
	storesObservable:Observable<any[]>;
	cartObservable:Observable<any|undefined>;
	moment:object;
	domSanitizer:DomSanitizer;
	phoneNumberUtil:PhoneNumberUtil;
	darkTheme?:boolean;
	isHandsetObservable:Observable<boolean>;
	isTabletObservable:Observable<boolean>;
	isMediumObservable:Observable<boolean>;
	isSmallObservable:Observable<boolean>;
	isXSmallObservable:Observable<boolean>;
	mediaObserver:MediaObserver;
	isPlatformServer:boolean;
	paypal:PayPalNamespace;
	googleMaps:google.maps.MapsLibrary;
	googlePlaces:google.maps.PlacesLibrary;
	mercadoPago:any;
	
	constructor(
		title:Title,
		meta:Meta,
		router:Router,
		httpClient:HttpClient,
		cookieService:CookieService,
		breakpointObserver:BreakpointObserver,
		mediaObserver:MediaObserver,
		apollo:Apollo,
		overlayContainer:OverlayContainer,
		matSnackBar:MatSnackBar,
		@Inject(PLATFORM_ID) private platformId:any,
		domSanitizer:DomSanitizer,
		@Inject(PHONE_NUMBER_UTIL) phoneNumberUtil:PhoneNumberUtil,
		@Inject(PAYPAL) paypal:Promise<PayPalNamespace>,
		@Inject(GOOGLE_MAPS) googleMaps:Promise<google.maps.MapsLibrary>,
		@Inject(GOOGLE_PLACES) googlePlaces:Promise<google.maps.PlacesLibrary>,
		@Inject(MERCADO_PAGO) mercadoPago:Promise<any>,
	){
		this.title=title;
		this.meta=meta;
		this.router=router;
		this.httpClient=httpClient;
		this.cookieService=cookieService;
		this.breakpointObserver=breakpointObserver;
		this.mediaObserver=mediaObserver;
		this.apollo=apollo;
		this.overlayContainer=overlayContainer;
		this.matSnackBar=matSnackBar;
		this.window=window;
		this.localStorage=localStorage;
		this.sessionStorage=sessionStorage;
		this.moment=moment;
		this.domSanitizer=domSanitizer;
		this.phoneNumberUtil=phoneNumberUtil;
		this.userSource=new BehaviorSubject<any|undefined>(null);
		this.userObservable=this.userSource.asObservable();
		this.productCategoriesSource=new BehaviorSubject<any[]>([]);
		this.productCategoriesObservable=this.productCategoriesSource.asObservable().pipe(filter((items:any[]):boolean=>!!items));
		this.storesSource=new BehaviorSubject<any[]>([]);
		this.storesObservable=this.storesSource.asObservable().pipe(filter((items:any[]):boolean=>!!items));
		this.cartSource=new BehaviorSubject<any|undefined>(null);
		this.cartObservable=this.cartSource.asObservable().pipe(filter((item:any):boolean=>item!==null));
		this.mediaMatcherXSmall=this.window.matchMedia(Breakpoints.XSmall);
		this.mediaMatcherSmall=this.window.matchMedia(Breakpoints.Small);
		this.mediaMatcherMedium=this.window.matchMedia(Breakpoints.Medium);
		this.mediaMatcherLarge=this.window.matchMedia(Breakpoints.Large);
		this.mediaMatcherXLarge=this.window.matchMedia(Breakpoints.XLarge);
		this.mediaMatcherDarkTheme=this.window.matchMedia('(prefers-color-scheme: dark)');
		this.mediaMatcherDarkTheme.addEventListener('change',this.checkDarkTheme.bind(this));
		this.checkDarkTheme();
		this.isHandsetObservable=this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isTabletObservable=this.breakpointObserver.observe(Breakpoints.Tablet)
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isMediumObservable=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small,Breakpoints.Medium])
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isSmallObservable=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		this.isXSmallObservable=this.breakpointObserver.observe([Breakpoints.XSmall])
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		
		paypal.then((paypal:PayPalNamespace):void=>{
			this.paypal=paypal;
		});
		
		googleMaps.then((googleMaps:google.maps.MapsLibrary):void=>{
			this.googleMaps=googleMaps;
		});
		
		googlePlaces.then((googlePlaces:google.maps.PlacesLibrary):void=>{
			this.googlePlaces=googlePlaces;
		});
		
		mercadoPago.then((mercadoPago:any):void=>{
			console.log('mercadoPago',mercadoPago)
			this.mercadoPago=mercadoPago;
		});
		
		this.isPlatformServer=isPlatformServer(platformId);
		
		this.isHandsetObservable=this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map((result:BreakpointState):boolean=>result.matches),
			shareReplay()
		);
		
		firstValueFrom(this.getUser()).then(console.log).catch(console.error);
	}
	
	checkDarkTheme():void{
		if(this.window.localStorage.getItem('darkTheme')==null){
			if(this.mediaMatcherDarkTheme.matches){
				this.darkTheme=true;
				this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
			}else{
				this.darkTheme=false;
			}
		}else{
			if(parseInt(this.window.localStorage.getItem('darkTheme')!)){
				this.darkTheme=true;
				this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
			}else{
				this.darkTheme=false;
			}
		}
	}
	
	setUser(data?:any):void{
		console.log('setUser',data);
		this.userSource.next(data);
	}
	
	setProductCategories(data:any[]):void{
		console.log('setProductCategories',data);
		this.productCategoriesSource.next(data);
	}
	
	setStores(data:any[]):void{
		console.log('setStores',data);
		this.storesSource.next(data);
	}
	
	setCart(data:any):void{
		console.log('setCart',data);
		this.cartSource.next(
			{
				...data,
				itemsCount:data.items?.reduce((previousValue:any,currentValue:any):number=>previousValue+currentValue.quantity,0)
			}
		);
		this.window.localStorage.setItem('cartId',data.id);
	}
	
	isScreenXSmall():boolean{
		return this.mediaMatcherXSmall.matches;
	}
	
	isScreenSmall():boolean{
		return this.mediaMatcherSmall.matches;
	}
	
	isScreenMedium():boolean{
		return this.mediaMatcherMedium.matches;
	}
	
	isScreenLarge():boolean{
		return this.mediaMatcherLarge.matches;
	}
	
	isScreenXLarge():boolean{
		return this.mediaMatcherXLarge.matches;
	}
	
	isDarkTheme():boolean{
		return this.mediaMatcherDarkTheme.matches;
	}
	
	changeTheme(themeName:string):void{
		let themeAsset:Partial<HTMLLinkElement>|null=this.window.document.getElementById('themeAsset');
		if(themeAsset) themeAsset.href=`/${themeName}.css`;
	}
	
	toggleDarkTheme():void{
		this.darkTheme= !this.darkTheme;
		if(!this.darkTheme){
			this.overlayContainer.getContainerElement().classList.remove('app-dark-theme');
			this.window.localStorage.setItem('darkTheme','0');
		}else{
			this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
			this.window.localStorage.setItem('darkTheme','1');
		}
	}
	
	updateSeo(title:string,description?:string,keywords?:string,photo?:string):void{
		this.title.setTitle(title);
		this.meta.updateTag({content:title},'property="og:title"');
		this.meta.updateTag({content:this.window.location.href},'property="og:url"');
		if(description){
			this.meta.updateTag({content:description},'name="description"');
			this.meta.updateTag({content:description},'property="og:description"');
		}
		if(keywords){
			this.meta.updateTag({content:keywords},'name="keywords"');
		}
		if(photo){
			this.meta.updateTag({content:photo},'name="twitter:image"');
			this.meta.updateTag({content:photo},'property="og:image"');
			this.meta.updateTag({content:photo},'property="og:image:alt"');
		}
	}
	
	async logout():Promise<void>{
		this.httpClient.post(`${environment.apiServer.url}/api/authentication/logout`,{},{withCredentials:true})
		.subscribe({next:():void=>{this.window.location.reload();},error:():void=>{this.window.location.reload();}});
	}
	
	refreshJwt():void{
		let reqData:object={};
		this.httpClient.post(`${environment.apiServer.url}/api/authentication/refresh-jwt`,reqData,{withCredentials:true})
		.subscribe({next:console.log,error:console.error});
	}
	
	getInitialData():void{
		this.apollo
		.query({
			variables:{cartId:this.window.localStorage.getItem('cartId')},
			query:gql`query getInitialData($cartId: String){
          getProductCategories {
              createdAt
              label
              id
              updatedAt
              slug
          }
          getCart(id:$cartId){
              createdAt
              id
              items {
                  product{
                      name,
                      quantity,
                      pictures {
                          small
                      }
                      price
                      discountPercent
                      id
                      slug
                  }
                  quantity
              }
              updatedAt
          }
          getStores {
              city
              country
              createdAt
              id
              line1
              line2
              location{
                  coordinates
                  type
              }
              name
              phone
              placeId
              state
              updatedAt
              zip
          }
      }`
		})
		.pipe(
			tap((response:ApolloQueryResult<any>):void=>{
				this.setCart(response.data?.getCart);
				this.setProductCategories(response.data?.getProductCategories);
				this.setStores(response.data?.getStores);
			}),
			catchError((error:any):Observable<never>=>{
				this.getCart();
				this.getProductCategories();
				this.getStores();
				return throwError(():any=>error);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
	
	getUser():Observable<ApolloQueryResult<any>>{
		return this.apollo
		.query({
			query:gql`query getUser{
          getUser {
              updatedAt
              id
              createdAt
              email
              firstName
              lastName
              password
              role {
                  id
                  label
              }
              phone
          }
      }`
		})
		.pipe(
			tap((response:ApolloQueryResult<any>):void=>{
				this.getInitialData();
				if(response.data?.getUser){
					this.setUser(response.data?.getUser);
					this.window.localStorage.setItem('userId',response.data.getUser.id);
				}else{
					this.setUser(undefined);
					this.window.localStorage.removeItem('userId');
				}
			}),
			catchError((error:any):Observable<never>=>{
				this.getInitialData();
				this.setUser(undefined);
				this.window.localStorage.removeItem('userId');
				return throwError(():any=>error);
			})
		);
	}
	
	getProductCategories():void{
		this.apollo
		.query({
			query:gql`query getProductCategories{
          getProductCategories {
              createdAt
              label
              id
              updatedAt
              slug
          }
      }`
		})
		.pipe(
			tap((response:ApolloQueryResult<any>):void=>{
				this.setProductCategories(response.data?.getProductCategories);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
	
	getCart():void{
		this.apollo
		.query({
			variables:{id:this.window.localStorage.getItem('cartId')},
			query:gql`query getCart($id: String){
          getCart(id:$id){
              createdAt
              id
              items {
                  product{
                      name,
                      quantity,
                      pictures {
                          small
                      }
                      price
                      discountPercent
                      id
                      slug
                  }
                  quantity
              }
              updatedAt
          }
      }`
		})
		.pipe(
			tap((response:ApolloQueryResult<any>):void=>{
				this.setCart(response.data?.getCart);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
	
	searchProducts(variables:SearchItemsRequestDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`
          query searchProducts($limit: Float!,$skip: Float!,$query: String!){
              searchProducts(limit: $limit, skip: $skip, query: $query) {
                  description
                  id
                  name
                  pictures {
                      large
                      small
                  }
                  price
                  slug
                  discountPercent
                  searchHighlights
              }
          }
			`
		});
	}
	
	createCartItem(variables:CreateCartItemRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation create($id: String!,$product: String!,$quantity: Float!){
              createCartItem(id: $id, product: $product, quantity: $quantity) {
                  createdAt
                  id
                  quantity
                  product {
                      id
                      name
                  }
                  updatedAt
              }
          }
			`
		})
		.pipe(
			tap(():void=>{
				this.matSnackBar.openFromComponent(CartItemCreatedSnackComponent);
			})
		);
	}
	
	getOrderStatuses():Observable<any>{
		return this.apollo
		.query({
			query:gql`query getOrderStatuses{
          getOrderStatuses{
              updatedAt
              id
              createdAt
              label
              icon
              order
              description
          }
      }`
		});
	}
	
	getStores():void{
		this.apollo
		.query({
			query:gql`query getStores{
          getStores {
              city
              country
              createdAt
              id
              line1
              line2
              location{
									coordinates
									type
							}
              name
              phone
              placeId
              state
              updatedAt
              zip
          }
      }`
		})
		.pipe(
			tap((response:ApolloQueryResult<any>):void=>{
				this.setStores(response.data?.getStores);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
	
}
