import {Component} from '@angular/core';
import {FooterService} from './footer.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';

@Component({
	selector:'bendita-footer',
	templateUrl:'./footer.component.html',
	styleUrls:['./footer.component.scss']
})
export class FooterComponent{
	private footerService:FooterService;
	private matSnackBar:MatSnackBar;
	environment:any;
	
	constructor(footerService:FooterService,matSnackBar:MatSnackBar){
		this.footerService=footerService;
		this.matSnackBar=matSnackBar;
		this.environment=environment;
		
	}
	
}
