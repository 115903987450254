<mat-card>
	<mat-card-header>
		<mat-card-title>
			EMAIL VERIFICADO
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div fxLayout="column" fxLayoutAlign="center center">
			<div fxFlex>
				<p>Tu email ha sido verificado, continua disfrutando de nuestros servicios.</p>
			</div>
			<button fxFlex="nogrow" mat-raised-button color="primary" class="buy-button" [routerLink]="['/products']">
				Ir a comprar
			</button>
		</div>
	</mat-card-content>
</mat-card>
