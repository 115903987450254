import {ChangeDetectorRef,Component,inject,signal,WritableSignal} from '@angular/core';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {AuthenticationService} from '../authentication.service';
import {AppService} from '../../app.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder,FormControl,Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {skip,take} from 'rxjs';
import {SnackMessageComponent} from '../../misc/components/snack-message/snack-message.component';
import {environment} from '../../../environments/environment';
import {PHONE_NUMBER_UTIL} from '../../shared/constants';
import {Auth,RecaptchaVerifier,signInWithPhoneNumber} from '@angular/fire/auth';
import {ConfirmationResult} from '@firebase/auth';
import {SharedModule} from '../../shared/shared.module';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';

@Component({
	selector:'app-login-phone-popup',
	imports:[
		SharedModule,
		FormMessageComponent,
		InputEmptyToNullDirective
	],
	templateUrl:'./login-phone-popup.component.html',
	styleUrl:'./login-phone-popup.component.scss'
})
export class LoginPhonePopupComponent extends PopupComponent{
	protected readonly environment=environment;
	private changeDetectorRef=inject(ChangeDetectorRef);
	private authenticationService=inject(AuthenticationService);
	readonly phoneNumberUtil=inject(PHONE_NUMBER_UTIL);
	private appService=inject(AppService);
	private matSnackBar=inject(MatSnackBar);
	readonly auth=inject(Auth);
	private formBuilder=inject(FormBuilder);
	form=this.formBuilder.group({
		phoneCountryCode:new FormControl<string>('+57',{validators:[Validators.required]}),
		phone:new FormControl<string|null>(null,{validators:[Validators.required]})
	});
	serverError=signal<any>(null);
	serverResponse=signal<any>(null);
	submitButtonActive=signal<boolean>(true);
	recaptchaVerifier?:RecaptchaVerifier;
	phoneConfirmationResult=signal<ConfirmationResult|null>(null);
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	sendOtp():void{
		if(this.submitButtonActive()){
			const {phoneCountryCode,phone}=this.form.getRawValue();
			const isNotValid=!phoneCountryCode || !phone;
			if(!this.form.valid || isNotValid){
				this.form.markAllAsTouched();
			}else{
				this.serverError.set(null);
				this.serverResponse.set(null);
				this.recaptchaVerifier=new RecaptchaVerifier(
					this.auth,
					'login-phone-recaptcha-container',
					{
						size:'normal',
						callback:()=>{
							signInWithPhoneNumber(this.auth,`+${phoneCountryCode}${phone}`,this.recaptchaVerifier)
							.then((response)=>{
								this.phoneConfirmationResult.set(response);
								this.recaptchaVerifier?.clear();
								this.recaptchaVerifier=undefined;
							})
							.catch((error)=>{
								this.serverError.set(error);
								this.recaptchaVerifier?.clear();
								this.recaptchaVerifier=undefined;
							});
						},
						'expired-callback':(error:any)=>{
							this.serverError.set(error);
						},
						'error-callback':(error:any)=>{
							this.serverError.set(error);
						}
					}
				);
				this.recaptchaVerifier.render()
				.catch((error)=>{
					this.serverError.set(error);
				});
			}
		}
	}
	
	login(code:string):void{
		const phoneConfirmationResult=this.phoneConfirmationResult();
		if(this.submitButtonActive() && phoneConfirmationResult){
			this.serverError.set(null);
			this.serverResponse.set(null);
			phoneConfirmationResult.confirm(code)
			.then(async(result)=>{
				this.submitButtonActive.set(false);
				this.authenticationService.loginPhone({token:await result.user.getIdToken()})
				.subscribe({
					next:()=>{
						this.submitButtonActive.set(true);
						this.phoneConfirmationResult.set(null);
						this.authenticationService.totpPopup.set(true)
						this.authenticationService.userObservable
						.pipe(
							skip(1),
							take(1)
						)
						.subscribe((user)=>{
							if(user){
								this.matSnackBar.openFromComponent(SnackMessageComponent,{
									data:{
										response:`Bienvenido/a ${user?.firstName || ''}`
									},
									duration:2000
								});
								this.appService.getCart({}).subscribe();
							}else{
								this.matSnackBar.openFromComponent(SnackMessageComponent,{data:{error:'user not loaded'}});
							}
						});
						this.authenticationService.reloadUser();
						if(this.router.url==='/login'){
							this.close(false,'/profile');
						}else{
							this.close();
						}
					},
					error:(error)=>{
						this.submitButtonActive.set(true);
						this.phoneConfirmationResult.set(null);
						this.serverError.set(error);
					}
				});
			})
			.catch((error)=>{
				this.serverError.set(error);
			});
		}
	}
}
