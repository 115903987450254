import {Component,Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
	selector:'app-snack-message',
	imports:[],
	templateUrl:'./snack-message.component.html',
	styleUrl:'./snack-message.component.scss'
})
export class SnackMessageComponent{
	data?:any;
	
	constructor(@Inject(MAT_SNACK_BAR_DATA) data:any){
		this.data=data;
	}
}
