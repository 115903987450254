import {ChangeDetectorRef,Component,Inject,Injector} from '@angular/core';
import {PopupComponent} from '../../shared/popups/popup.component';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {ProfileService} from '../profile.service';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {CreateAddressShippingRequestDto} from '../dto/create-address-shipping-request.dto';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackMessageComponent} from '../../shared/snack-message/snack-message.component';
import {UpdateAddressShippingRequestDto} from '../dto/update-address-shipping-request.dto';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {PHONE_NUMBER_UTIL} from '../../shared/constants';

@Component({
	selector:'bendita-add-address-popup',
	templateUrl:'./add-address-popup.component.html',
	styleUrls:['./add-address-popup.component.scss']
})
export class AddAddressPopupComponent extends PopupComponent{
	private matDialogRef:MatDialogRef<AddAddressPopupComponent>;
	private matSnackBar:MatSnackBar;
	private readonly profileService:ProfileService;
	private readonly formBuilder:FormBuilder;
	place?:any;
	form:FormGroup;
	submitButtonActive:boolean;
	mapOptions:google.maps.MapOptions;
	private readonly changeDetectorRef:ChangeDetectorRef;
	public data:any;
	readonly phoneNumberUtil:PhoneNumberUtil;
	
	constructor(
		injector:Injector,
		matDialogRef:MatDialogRef<AddAddressPopupComponent>,
		matSnackBar:MatSnackBar,
		profileService:ProfileService,
		formBuilder:FormBuilder,
		changeDetectorRef:ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) data:any,
		@Inject(PHONE_NUMBER_UTIL) phoneNumberUtil:PhoneNumberUtil
	){
		super(injector);
		this.matDialogRef=matDialogRef;
		this.matSnackBar=matSnackBar;
		this.profileService=profileService;
		this.place=undefined;
		this.formBuilder=formBuilder;
		this.changeDetectorRef=changeDetectorRef;
		this.phoneNumberUtil=phoneNumberUtil;
		this.submitButtonActive=true;
		this.form=this.formBuilder.group({
			name:[data.address?.name,[Validators.required]],
			phone:[
				data.address?.phone ? this.phoneNumberUtil.parseAndKeepRawInput(data.address?.phone).getNationalNumber()?.toString() : undefined,
				[Validators.required]
			],
			phoneCountryCode:[
				data.address?.phone ? '+'+this.phoneNumberUtil.parseAndKeepRawInput(data.address?.phone).getCountryCode()?.toString() : undefined,
				[Validators.required]
			],
			line2:[data.address?.line2,[]]
		});
		this.mapOptions={
			clickableIcons:false,
			gestureHandling:'none',
			keyboardShortcuts:false,
			streetViewControl:false,
			fullscreenControl:false
		};
		this.data=data;
		
		this.matDialogRef.backdropClick().subscribe(():void=>{
			this.close();
		});
		
	}
	
	override close():void{
		this.matDialogRef.close();
	}
	
	getAddress(event:any):void{
		if(this.data.address) this.data.address.location=undefined;
		this.place=undefined;
		if(!event.types.some((element:string):boolean=>(element==='premise' || element==='street_address'))){
			this.form.markAllAsTouched();
			this.matSnackBar.openFromComponent(SnackMessageComponent,{
				data:{
					serverErrorInput:'Error: seleccione una direccion de envio valida'
				}
			});
			this.changeDetectorRef.detectChanges();
		}else{
			this.place=event;
			this.form.get('phoneCountryCode')?.setValue('+'+this.phoneNumberUtil.getCountryCodeForRegion(event.address_components.find((element:any):boolean=>element.types[0]==='country')?.short_name));
			this.changeDetectorRef.detectChanges();
		}
	}
	
	createAddress():void{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:'Error: datos incompletos'
					}
				});
			}else if(!this.place){
				this.form.markAllAsTouched();
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:'Error: seleccione una direccion valida de la lista'
					}
				});
			}else{
				this.submitButtonActive=false;
				let data:CreateAddressShippingRequestDto={
					name:this.form.get('name')?.value,
					phone:`${this.form.get('phoneCountryCode')?.value}${this.form.get('phone')?.value}`,
					line1:this.place.name,
					city:this.place.address_components.find((element:any):boolean=>element.types[0]==='locality')?.long_name,
					state:this.place.address_components.find((element:any):boolean=>element.types[0]==='administrative_area_level_1')?.long_name,
					country:this.place.address_components.find((element:any):boolean=>element.types[0]==='country')?.long_name,
					zip:this.place.address_components.find((element:any):boolean=>element.types[0]==='postal_code')?.long_name,
					location:{
						longitude:this.place.geometry.location.lng(),
						latitude:this.place.geometry.location.lat()
					}
				};
				if(this.form.get('line2')?.value) data.line2=this.form.get('line2')?.value;
				this.profileService.createAddress(data)
				.subscribe({
					next:():void=>{
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverResponseInput:'Direccion guardada'
							}
						});
						this.close();
					},
					error:(error:any):void=>{
						this.submitButtonActive=true;
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverErrorInput:error
							}
						});
					}
				});
			}
		}
	}
	
	updateAddress():void{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:'Error: datos incompletos'
					}
				});
			}else if(!this.place){
				this.form.markAllAsTouched();
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:'Error: seleccione una direccion valida de la lista'
					}
				});
			}else{
				this.submitButtonActive=false;
				let data:UpdateAddressShippingRequestDto={
					id:this.data.address.id,
					name:this.form.get('name')?.value,
					line1:this.place.name,
					city:this.place.address_components.find((element:any):boolean=>element.types[0]==='locality')?.long_name,
					phone:`${this.form.get('phoneCountryCode')?.value}${this.form.get('phone')?.value}`,
					country:this.place.address_components.find((element:any):boolean=>element.types[0]==='country')?.long_name,
					zip:this.place.address_components.find((element:any):boolean=>element.types[0]==='postal_code')?.long_name,
					state:this.place.address_components.find((element:any):boolean=>element.types[0]==='administrative_area_level_1')?.long_name,
					location:{
						longitude:this.place.geometry.location.lng(),
						latitude:this.place.geometry.location.lat()
					}
				};
				if(this.form.get('line2')?.value) data.line2=this.form.get('line2')?.value;
				this.profileService.updateAddress(data)
				.subscribe({
					next:():void=>{
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverResponseInput:'Direccion guardada'
							}
						});
						this.close();
					},
					error:(error:any):void=>{
						this.submitButtonActive=true;
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverErrorInput:error
							}
						});
					}
				});
			}
		}
	}
	
}
