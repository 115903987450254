import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AppService} from '../app.service';
import {Observable,switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoginRequestDto} from './dto/login-request.dto';
import {PasswordResetTokenDto} from './dto/password-reset-token.dto';
import {SignUpRequestDto} from './dto/sign-up-request.dto';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn:'root'
})
export class AuthenticationService{
	private httpClient:HttpClient;
	private router:Router;
	private appService:AppService;
	
	
	constructor(
		httpClient:HttpClient,
		router:Router,
		appService:AppService
	){
		this.httpClient=httpClient;
		this.router=router;
		this.appService=appService;
		
	}
	
	login(data:LoginRequestDto):Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/login`,data,{withCredentials:true})
		.pipe(
			switchMap(this.appService.getUser.bind(this.appService)),
			map((response:any):any=>response.data?.getUser)
		);
	}
	
	signUp(data:SignUpRequestDto):Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/signup`,data,{withCredentials:true})
		.pipe(
			switchMap(this.appService.getUser.bind(this.appService))
		);
	}
	
	passwordResetToken(data:PasswordResetTokenDto):Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/password-reset-token`,data,{withCredentials:true});
	}
	
	
}
