import {Injectable,Pipe,PipeTransform} from '@angular/core';

@Pipe({
	name:'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform{
	transform(items?:any[],field?:string,value?:string):any[]{
		if(!items) return [];
		else if(!field) return items;
		else if(!value || value.length==0) return items;
		else return items.filter((element:any):boolean=>element[field]===value);
	}
}