import {Component,ElementRef,OnInit,ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {TrackingService} from './tracking.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {ActivatedRoute,Params,Router} from '@angular/router';
import {BehaviorSubject,combineLatest,firstValueFrom,Observable,scan,tap} from 'rxjs';
import {GetOrderRequestDto} from '../check-out/dto/get-order-request.dto';
import {SnackMessageComponent} from '../shared/snack-message/snack-message.component';
import {map,switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {TokenOrderPickUpPopupComponent} from '../shared/popups/token-order-pick-up-popup/token-order-pick-up-popup.component';
import {ORDER_STATUS} from '../common/enums/order-status.enum';
import { PAYMENT_TYPE } from '../common/enums/payment-type.enum';
import {SHIPPING_TYPE} from '../common/enums/shipping-type.enum';

@Component({
	selector:'bendita-tracking',
	templateUrl:'./tracking.component.html',
	styleUrls:['./tracking.component.scss']
})
export class TrackingComponent implements OnInit{
	ORDER_STATUS:typeof ORDER_STATUS;
	PAYMENT_TYPE:typeof PAYMENT_TYPE;
	SHIPPING_TYPE:typeof SHIPPING_TYPE;
	title:string;
	appService:AppService;
	private readonly trackingService:TrackingService;
	private matSnackBar:MatSnackBar;
	private matDialog:MatDialog;
	order:Observable<any>;
	public files:NgxFileDropEntry[];
	private router:Router;
	private activatedRoute:ActivatedRoute;
	private reloadOrder:BehaviorSubject<void>;
	itemsPerPage:number;
	orderEvents?:Observable<any[]>;
	private resetOrderEvents:BehaviorSubject<void>;
	private fetchOrderEvents:BehaviorSubject<void>;
	@ViewChild('autoScrollElement') autoScrollElement?:ElementRef;
	protected readonly environment=environment;
	tokenOrderPickUp:any;
	
	constructor(
		appService:AppService,
		trackingService:TrackingService,
		matSnackBar:MatSnackBar,
		matDialog:MatDialog,
		router:Router,
		activatedRoute:ActivatedRoute
	){
		this.ORDER_STATUS=ORDER_STATUS;
		this.PAYMENT_TYPE=PAYMENT_TYPE;
		this.SHIPPING_TYPE=SHIPPING_TYPE;
		this.title='Bendita - Rastreo de orden';
		this.appService=appService;
		this.trackingService=trackingService;
		this.matSnackBar=matSnackBar;
		this.matDialog=matDialog;
		this.files=[];
		this.router=router;
		this.activatedRoute=activatedRoute;
		this.reloadOrder=new BehaviorSubject<void>(undefined);
		this.order=combineLatest([
			this.activatedRoute.params.pipe(
				map((params:Params):string=>params['id'])
			),
			this.activatedRoute.queryParams.pipe(
				map((params:Params):string=>params['token'])
			),
			this.reloadOrder
		])
		.pipe(
			switchMap(([id,guestToken]:any[]):Observable<any>=>{
				let data:GetOrderRequestDto={id};
				if(guestToken) data.guestToken=guestToken;
				return this.trackingService.getOrder(data);
			}),
			tap((order:any):void=>{
				if(!order) this.router.navigate(['/error'],{fragment:'Error_Order not found'});
				else{
					this.appService.updateSeo(
						`Bendita - Orden ${order.slug}`,
						'Plantas & Semillas Abierto las 24 horas',
						'bendita, grow, shop, semillas, plantas',
						'https://www.benditagrow.com/assets/icons/icon-512x512.png'
					);
				}
			})
		);
		this.itemsPerPage=20;
		this.resetOrderEvents=new BehaviorSubject<void>(undefined);
		this.fetchOrderEvents=new BehaviorSubject<void>(undefined);
		this.orderEvents=undefined;
		this.tokenOrderPickUp=undefined;
		
		this.getTokenOrderPickUp();
		
	}
	
	
	async ngOnInit():Promise<void>{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
		
		const orderId:string=(await firstValueFrom(this.activatedRoute.params))['id'];
		const guestToken:string=(await firstValueFrom(this.activatedRoute.queryParams))['token'];
		this.orderEvents=this.resetOrderEvents.pipe(
			switchMap(():Observable<any>=>this.fetchOrderEvents.pipe(
				switchMap(
					(currentValue:void,index:number):Observable<any[]>=>this.trackingService.getOrderEvents(
						{
							id:orderId,
							guestToken,
							limit:this.itemsPerPage,
							skip:index*this.itemsPerPage
						}
					)
				),
				scan((all:any[],page:any[]):any[]=>{
					return all.concat(page);
				},[])
			))
		);
	}
	
	onFileDrop(files:NgxFileDropEntry[]):void{
		this.files=files;
	}
	
	async uploadProofOfPayment():Promise<void>{
		if(this.files[0]){
			const fileEntry:FileSystemFileEntry=this.files[0].fileEntry as FileSystemFileEntry;
			fileEntry.file(async(file:File):Promise<void>=>{
				let next=():void=>{
					this.reloadOrder.next();
				};
				let error=(error:string):void=>{
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverErrorInput:error
						}
					});
				};
				this.trackingService.createProofOfPayment((await firstValueFrom(this.activatedRoute.params))['id'],file,(await firstValueFrom(this.activatedRoute.queryParams))['token'])
				.subscribe({next,error});
			});
		}
	}
	
	
	async getTokenOrderPickUp():Promise<void>{
		this.tokenOrderPickUp=await firstValueFrom(this.trackingService.getTokenOrderPickUp({
			id:(await firstValueFrom(this.activatedRoute.params))['id'],
			guestToken:(await firstValueFrom(this.activatedRoute.queryParams))['token']
		}));
	}
	
	async openQR():Promise<void>{
		this.matDialog.open(
			TokenOrderPickUpPopupComponent,
			{
				panelClass:'dialog-responsive',
				data:{
					order:(await firstValueFrom(this.order)),
					tokenOrderPickUp:this.tokenOrderPickUp
				},
				width:'100%',
				height:'100%'
			}
		);
	}
	
	
}
