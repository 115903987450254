import {NgModule} from '@angular/core';
import {CommonModule,NgSwitchCase,NgTemplateOutlet} from '@angular/common';

import {CheckOutRoutingModule} from './check-out-routing.module';
import {CheckOutComponent} from './check-out.component';
import {SharedModule} from '../shared/shared.module';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {FlexModule} from '@angular/flex-layout';


@NgModule({
	declarations:[
		CheckOutComponent,
		ConfirmationComponent
	],
	imports:[
		CommonModule,
		CheckOutRoutingModule,
		SharedModule,
		NgSwitchCase,
		NgTemplateOutlet,
		FlexModule
	]
})
export class CheckOutModule{}
