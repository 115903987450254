<mat-card>
	<form [formGroup]="form">
		<mat-card-content>
			<div fxLayout="column">
				<div #preProductFilters></div>
				<div #productFilters fxFlex fxLayout="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px">
					<div fxFlex fxLayout="row" fxLayoutAlign="start center">
						<mat-chip-listbox fxFlex fxFlex aria-label="Bread crumbs">
							<ng-container *ngIf="category && (category|async);else noCategorySelectedTemplate">
								<mat-chip [matMenuTriggerFor]="menu" class="app-pointer">
									<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
										<mat-icon fxFlex>filter_list</mat-icon>
										<span fxFlex>{{(category|async).label}}</span>
									</div>
								</mat-chip>
							</ng-container>
							<ng-template #noCategorySelectedTemplate>
								<mat-chip [matMenuTriggerFor]="menu" class="app-pointer">
									<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
										<mat-icon fxFlex style="padding-right: 15px">filter_list</mat-icon>
										<span fxFlex>Filtrar...</span>
									</div>
								</mat-chip>
							</ng-template>
						</mat-chip-listbox>
						<div *ngIf="productsFilterIsFixed()" fxFlex="nogrow">
							<button fxFlex="nogrow" fxFlexAlign="center" mat-button matRipple class="app-primary" (click)="appService.window.scrollTo(0,0)">
								<mat-icon>arrow_upward</mat-icon>
								<span *ngIf="!(appService.isHandsetObservable | async)">Ir arriba</span>
							</button>
						</div>
					</div>
					<mat-menu #menu="matMenu" fxFlex="grow" fxFlexAlign="baseline">
						<mat-nav-list>
							<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:null}" [queryParamsHandling]="'merge'">
								<span style="width: 100%;" class="app-14">Todos los productos</span>
							</a>
							<ng-container *ngFor="let category of appService.productCategoriesObservable | async">
								<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [queryParamsHandling]="'merge'">
									<span style="width: 100%;">{{category.label}}</span>
								</a>
							</ng-container>
						</mat-nav-list>
					</mat-menu>
					<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
						<mat-form-field fxFlex="nogrow" floatLabel="auto">
							<mat-icon matPrefix style="padding-right: 15px">swap_vert</mat-icon>
							<mat-select #filterSelect value="CREATED_AT" formControlName="sort" [required]="true">
								<mat-option [value]="GetProductsSortFactor.CREATED_AT+'*-1'">Nuevos productos</mat-option>
								<mat-option [value]="GetProductsSortFactor.NAME+'*1'">Alfabeticamente: A-Z</mat-option>
								<mat-option [value]="GetProductsSortFactor.NAME+'*-1'">Alfabeticamente: Z-A</mat-option>
								<mat-option [value]="GetProductsSortFactor.PRICE+'*1'">Precio: Menor primero</mat-option>
								<mat-option [value]="GetProductsSortFactor.PRICE+'*-1'">Precio: Mayor primero</mat-option>
								<mat-option [value]="GetProductsSortFactor.RECOMMENDED+'*1'">Recomendados</mat-option>
							</mat-select>
						</mat-form-field>
						<div fxFlex fxLayout="row" formGroupName="filter" fxLayoutGap="10px" fxLayoutAlign="start start">
							<mat-slide-toggle fxFlex="nogrow" formControlName="freeShipping" color="primary">Envio Gratis</mat-slide-toggle>
							<mat-slide-toggle fxFlex="nogrow" formControlName="discount" color="primary">Descuentos</mat-slide-toggle>
						</div>
					</div>
				</div>
				<bendita-products-list *ngIf="products" fxFlex [productsInput]="(products | async)!" fxFlexOffset="15px"></bendita-products-list>
			</div>
		</mat-card-content>
	</form>
</mat-card>
<div #autoFetchElement></div>
