import {Component,Inject,Injector} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {PopupComponent} from '../popup.component';

@Component({
	selector:'bendita-token-order-pick-up-popup',
	templateUrl:'./token-order-pick-up-popup.component.html',
	styleUrls:['./token-order-pick-up-popup.component.scss']
})
export class TokenOrderPickUpPopupComponent extends PopupComponent{
	private matDialogRef:MatDialogRef<TokenOrderPickUpPopupComponent>;
	public data:any;
	public qrWidth:number;
	
	constructor(
		injector:Injector,
		matDialogRef:MatDialogRef<TokenOrderPickUpPopupComponent>,
		@Inject(MAT_DIALOG_DATA) data:any
	){
		super(injector);
		this.matDialogRef=matDialogRef;
		this.data=data;
		
		if(this.appService.window.innerWidth<this.appService.window.innerHeight) this.qrWidth=this.appService.window.innerWidth-100;
		else this.qrWidth=this.appService.window.innerHeight-150;
		
		this.matDialogRef.backdropClick().subscribe(():void=>{
			this.close();
		});
	}
	
	override close():void{
		this.matDialogRef.close();
	}
	
}
