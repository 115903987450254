export enum GetProductsSortFactor{
	CREATED_AT="CREATED_AT",
	NAME="NAME",
	PRICE="PRICE",
	RECOMMENDED="RECOMMENDED",
}
export class GetProductsFilter{
	discount?:boolean;
	freeShipping?:boolean;
}

export interface GetProductsDto{
	limit:number;
	skip:number;
	category?:string;
	sortFactor:GetProductsSortFactor;
	sortOrder:number;
	filter:GetProductsFilter;
}