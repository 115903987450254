import {Component,Injector} from '@angular/core';
import {PopupComponent} from '../../shared/popups/popup.component';
import {ActivatedRoute,Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '../authentication.service';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {firstValueFrom} from 'rxjs';

@Component({
	selector:'bendita-signup-popup',
	templateUrl:'./signup-popup.component.html',
	styleUrls:['./signup-popup.component.scss']
})
export class SignupPopupComponent extends PopupComponent{
	private activatedRoute:ActivatedRoute;
	private router:Router;
	private matDialogRef:MatDialogRef<SignupPopupComponent>;
	private authenticationService:AuthenticationService;
	email:FormControl;
	password:FormControl;
	firstName:FormControl;
	lastName:FormControl;
	form:FormGroup;
	serverError?:string;
	serverResponse?:string;
	submitButtonActive:boolean;
	
	constructor(
		injector:Injector,
		activatedRoute:ActivatedRoute,
		router:Router,
		matDialogRef:MatDialogRef<SignupPopupComponent>,
		authenticationService:AuthenticationService
	){
		super(injector);
		this.activatedRoute=activatedRoute;
		this.router=router;
		this.matDialogRef=matDialogRef;
		this.authenticationService=authenticationService;
		this.submitButtonActive=true;
		this.serverError=undefined;
		this.serverResponse=undefined;
		this.email=new FormControl(undefined,[
			Validators.required,
			Validators.email
		]);
		this.password=new FormControl(undefined,[
			Validators.required
		]);
		this.firstName=new FormControl(undefined,[
			Validators.required
		]);
		this.lastName=new FormControl(undefined,[
			Validators.required
		]);
		this.form=new FormGroup({
			email:this.email,
			password:this.password,
			firstName:this.firstName,
			lastName:this.lastName
		});
		
		this.matDialogRef.backdropClick().subscribe(():void=>{
			this.close();
		});
	}
	
	override async close(timeout?:boolean):Promise<void>{
		let redirectUrl:string|undefined;
		if(!timeout)
			if(this.activatedRoute.firstChild)
				if((await firstValueFrom(this.activatedRoute.firstChild.url))[0]?.path==='signup') redirectUrl='/';
		if(timeout) setTimeout(():void=>this.matDialogRef.close({redirectUrl}),1000);
		else this.matDialogRef.close({redirectUrl});
	}
	
	signUp():void{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive=false;
				this.serverError=undefined;
				this.serverResponse=undefined;
				let next=():void=>{
					this.submitButtonActive=true;
					this.matDialogRef.close({redirectUrl:'/profile'});
				};
				let error=(error:string):void=>{
					this.submitButtonActive=true;
					this.serverError=error;
				};
				this.authenticationService.signUp({
					email:this.form.get('email')?.value,
					password:this.form.get('password')?.value,
					firstName:this.form.get('firstName')?.value,
					lastName:this.form.get('lastName')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
}
