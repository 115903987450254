import {Component,OnInit} from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {ResetPasswordService} from './reset-password.service';
import {firstValueFrom} from 'rxjs';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {PasswordChangedPopupComponent} from './password-changed-popup/password-changed-popup.component';
import {AppService} from '../app.service';

@Component({
	selector:'bendita-reset-password',
	templateUrl:'./reset-password.component.html',
	styleUrls:['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit{
	private activatedRoute:ActivatedRoute;
	private router:Router;
	form:FormGroup;
	private readonly formBuilder:FormBuilder;
	serverError?:string;
	serverResponse?:string;
	submitButtonActive:boolean;
	private readonly resetPasswordService:ResetPasswordService;
	private matDialog:MatDialog;
	appService:AppService;
	title:string;
	
	constructor(
		activatedRoute:ActivatedRoute,
		router:Router,
		formBuilder:FormBuilder,
		resetPasswordService:ResetPasswordService,
		appService:AppService,
		matDialog:MatDialog
	){
		this.title='Bendita - Reestablecer contraseña';
		this.appService=appService;
		this.activatedRoute=activatedRoute;
		this.router=router;
		this.formBuilder=formBuilder;
		this.form=this.formBuilder.group({
			password:[undefined,[Validators.required]],
			password2:[undefined,[Validators.required]]
		});
		this.submitButtonActive=true;
		this.serverError=undefined;
		this.serverResponse=undefined;
		this.resetPasswordService=resetPasswordService;
		this.matDialog=matDialog;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
	}
	
	async resetPassword():Promise<void>{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				if(this.form.get('password')?.value!==this.form.get('password2')?.value){
					this.serverError='Las contraseñas no coinciden';
				}else{
					this.submitButtonActive=false;
					this.serverError=undefined;
					this.serverResponse=undefined;
					let next=(response:any):void=>{
						this.submitButtonActive=true;
						if(response.result===0) this.serverError='Datos invalidos';
						else{
							let dialogRef:MatDialogRef<PasswordChangedPopupComponent>=this.matDialog.open(
								PasswordChangedPopupComponent,
								{
									panelClass:'dialog-responsive',
									data:{}
								}
							);
							dialogRef.afterClosed().subscribe((data:any):void=>{
								this.router.navigate(['/login']);
							});
						}
					};
					let error=(error:string):void=>{
						this.submitButtonActive=true;
						this.serverError=error;
					};
					this.resetPasswordService.resetPassword({
						token:(await firstValueFrom(this.activatedRoute.params))['p1'],
						password:this.form.get('password')?.value
					})
					.subscribe({next,error});
				}
			}
		}
	}
	
}
