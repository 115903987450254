import {Component,inject,signal} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {FormBuilder,FormControl,Validators} from '@angular/forms';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';

@Component({
	selector:'app-forgot-password-popup',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./forgot-password-popup.component.html',
	styleUrl:'./forgot-password-popup.component.scss'
})
export class ForgotPasswordPopupComponent extends PopupComponent{
	private authenticationService=inject(AuthenticationService);
	private formBuilder=inject(FormBuilder);
	form=this.formBuilder.group({
		email:new FormControl<string|null>(null,{validators:[Validators.required,Validators.email]})
	});
	serverError=signal<any>(null);
	serverResponse=signal<any>(null);
	submitButtonActive=signal<boolean>(true);
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	passwordResetToken():void{
		if(this.submitButtonActive()){
			const {email}=this.form.getRawValue();
			if(!this.form.valid || !email){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				this.authenticationService.passwordResetToken({email})
				.subscribe({
					next:()=>{
						this.submitButtonActive.set(true);
						this.serverResponse.set('Se ha enviado un email para reestablecer la contraseña.');
					},
					error:(error)=>{
						this.submitButtonActive.set(true);
						this.serverError.set(error);
					}
				});
			}
		}
	}
}
