import {BrowserModule} from '@angular/platform-browser';
import {APP_ID,Inject,Injector,NgModule,PLATFORM_ID} from '@angular/core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS,HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {SharedModule} from './shared/shared.module';
import {IndexModule} from './index/index.module';
import {SessionInterceptor} from './authentication/session.interceptor';
import {HeaderComponent} from './misc/header/header.component';
import {DrawerComponent} from './misc/drawer/drawer.component';
import {GraphQLModule} from './graphql.module';
import {AuthenticationModule} from './authentication/authentication.module';
import {FooterModule} from './misc/footer/footer.module';
import {CartModule} from './cart/cart.module';
import {getApp,initializeApp,provideFirebaseApp} from '@angular/fire/app';
import {provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService} from '@angular/fire/analytics';
import {provideAuth,getAuth} from '@angular/fire/auth';
import {providePerformance,getPerformance} from '@angular/fire/performance';
import {initializeAppCheck,provideAppCheck,ReCaptchaV3Provider,CustomProvider} from '@angular/fire/app-check';
import {isPlatformBrowser,isPlatformServer} from '@angular/common';
import {AppCheckToken} from '@firebase/app-check';

// import {RedirectInterceptor} from './common/redirect.interceptor';
// import {MatCarouselModule} from '@ngbmodule/material-carousel';

@NgModule({
	declarations:[
		AppComponent,
		HeaderComponent,
		DrawerComponent
	],
	imports:[
		BrowserModule.withServerTransition({appId:'serverApp'}),
		ServiceWorkerModule.register('ngsw-worker.js',{
			enabled:environment.production,
			registrationStrategy:'registerWhenStable:30000'
		}),
		BrowserAnimationsModule,
		HttpClientModule,
		GraphQLModule,
		SharedModule,
		IndexModule,
		AppRoutingModule,
		AuthenticationModule,
		// MatCarouselModule.forRoot()
		FooterModule,
		CartModule,
		provideFirebaseApp(()=>initializeApp(environment.firebase,'bendita')),
		provideAnalytics(()=>getAnalytics(getApp('bendita'))),
		provideAuth(()=>getAuth(getApp('bendita'))),
		providePerformance(()=>getPerformance(getApp('bendita'))),
		provideAppCheck((injector:Injector)=>{
			let provider:any;
			if(isPlatformServer(injector.get(PLATFORM_ID))){
				provider=new CustomProvider({
					getToken:():Promise<AppCheckToken>=>new Promise(():void=>{ })
				});
				return initializeAppCheck(getApp('bendita'),{provider,isTokenAutoRefreshEnabled:false});

			}else{
				provider=new ReCaptchaV3Provider(environment.google.recaptcha3SiteKey);
				return initializeAppCheck(getApp('bendita'),{provider,isTokenAutoRefreshEnabled:true});
			}
		})
	],
	providers:[
		CookieService,
		// {provide:HTTP_INTERCEPTORS,useClass:RedirectInterceptor,multi:true},
		{provide:HTTP_INTERCEPTORS,useClass:SessionInterceptor,multi:true},
		ScreenTrackingService,UserTrackingService
	],
	bootstrap:[AppComponent]
})
export class AppModule{
	constructor(
		@Inject(PLATFORM_ID) private platformId:any,
		@Inject(APP_ID) private appId:string
	){
		console.debug(`Running ${isPlatformBrowser(platformId) ? 'in the browser' : 'on the server'} with appId=${appId}`);
	}
}
