<h2 mat-dialog-title>
	Conectarme a mi cuenta
	<button mat-icon-button class="close-button" (click)="close()">
		<mat-icon>cancel</mat-icon>
	</button>
</h2>
<mat-dialog-content>
	<div>
		<div>
			<p>Para continuar ingresa tu e-mail y contraseña.</p>
		</div>
		<div class="form-container">
			<form [formGroup]="form" (ngSubmit)="login()">
				<mat-form-field floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" appInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field floatLabel="auto">
					<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" appInputEmptyToNull required>
				</mat-form-field>
				<div>
					<app-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></app-form-message>
				</div>
				<div>
					<button type="submit" mat-button class="app-primary">Log in</button>
				</div>
			</form>
		</div>
		<br>
		<mat-divider></mat-divider>
		<br>
		<div>
			<p>Nuevo cliente? <a [routerLink]="['/signup']" (click)="close(true)">Crear cuenta</a></p>
			<p>Contraseña olvidada? <a [routerLink]="['/forgot-password']" (click)="close(true)">Recuperar contraseña</a></p>
		</div>
	</div>
</mat-dialog-content>
