import {AfterViewInit,Component,ElementRef,OnInit,ViewChild} from '@angular/core';
import {ProfileService} from '../profile.service';
import {AppService} from '../../app.service';
import {BehaviorSubject,Observable,scan} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Component({
	selector:'bendita-orders',
	templateUrl:'./orders.component.html',
	styleUrls:['./orders.component.scss']
})
export class OrdersComponent implements OnInit,AfterViewInit{
	title:string;
	orders:Observable<any[]>;
	private readonly profileService:ProfileService;
	readonly appService:AppService;
	private fetchOrders:BehaviorSubject<void>;
	@ViewChild('autoScrollElement') autoScrollElement?:ElementRef;
	itemsPerPage:number;
	
	constructor(
		profileService:ProfileService,
		appService:AppService
	){
		this.title='Bendita - Mis ordenes';
		this.profileService=profileService;
		this.appService=appService;
		this.fetchOrders=new BehaviorSubject<void>(undefined);
		this.itemsPerPage=10;
		this.orders=this.fetchOrders.pipe(
			switchMap((currentValue:void,index:number):Observable<any>=>this.profileService.getOrders({limit:this.itemsPerPage,skip:index*this.itemsPerPage})),
			scan((all:any[],page:any[]):any[]=>{
				if(page.length===0 || page.length!==this.itemsPerPage) this.fetchOrders.complete();
				return all.concat(page);
			},[])
		);
		
	}
	
	ngOnInit():void{

	}
	
	getOrderItemsCount(order:any):number{
		return order.items.reduce((accumulator:number,element:any):number=>accumulator+element.quantity,0);
	}
	
	ngAfterViewInit():void{
		if(this.autoScrollElement){
			const observer:IntersectionObserver=new IntersectionObserver(
				(entries:IntersectionObserverEntry[]):void=>{
					for(let entry of entries){
						if(entry.isIntersecting){
							this.fetchMore().then();
							break;
						}
					}
				},
				{threshold:1}
			);
			setTimeout(():void=>{observer.observe(this.autoScrollElement?.nativeElement);},3000);
		}
	}
	
	async fetchMore():Promise<void>{
		this.fetchOrders.next();
	}
	
}
