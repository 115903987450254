import {Component,EventEmitter,Input,OnInit,Output,Renderer2,ViewChild} from '@angular/core';
import {AppService} from '../../app.service';

// @ts-ignore
import {} from '@types/google.maps';

@Component({
	selector:'bendita-google-places-autocomplete',
	templateUrl:'./google-places-autocomplete.component.html',
	styleUrls:['./google-places-autocomplete.component.scss']
})
export class GooglePlacesAutocompleteComponent implements OnInit{
	@Input() addressType:string;
	@Input() addressExisting?:string;
	@Output() setAddress:EventEmitter<any>=new EventEmitter();
	@ViewChild('addresstext') addresstext:any;
	private renderer:Renderer2;
	autocompleteInput?:string;
	queryWait?:boolean;
	
	constructor(appService:AppService,renderer:Renderer2){
		this.addressType='address';
		this.renderer=renderer;
	}
	
	ngOnInit():void{
		if(this.addressExisting){
			this.autocompleteInput=this.addressExisting;
			this.addresstext?.nativeElement.addClass('ng-invalid');
		}
	}
	
	ngAfterViewInit():void{
		this.getPlaceAutocomplete();
		if(this.addressExisting){
			this.autocompleteInput=this.addressExisting;
			// this.addresstext?.nativeElement.addClass('ng-invalid')
			this.renderer.addClass(this.addresstext?.nativeElement,'ng-invalid');
		}
	}
	
	private getPlaceAutocomplete():void{
		const autocomplete:google.maps.places.Autocomplete=new google.maps.places.Autocomplete(this.addresstext.nativeElement,
			{
				componentRestrictions:{country:'CO'},
				types:[this.addressType]
			});
		google.maps.event.addListener(autocomplete,'place_changed',():void=>{
			const place:google.maps.places.PlaceResult=autocomplete.getPlace();
			this.invokeEvent(place);
		});
	}
	
	invokeEvent(place:any):void{
		this.setAddress.emit(place);
	}
}
