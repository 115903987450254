import {Injectable} from '@angular/core';
import {
	CanActivate,
	CanLoad,
	Route,
	UrlSegment,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router
} from '@angular/router';
import {Observable,of} from 'rxjs';
import {catchError,filter,map} from 'rxjs/operators';
import {AppService} from '../app.service';

@Injectable({
	providedIn:'root'
})
export class AuthenticationGuard implements CanActivate,CanLoad{
	private router:Router;
	private appService:AppService;
	
	constructor(router:Router,appService:AppService){
		this.router=router;
		this.appService=appService;
	}
	
	canActivate(
		next:ActivatedRouteSnapshot,
		state:RouterStateSnapshot
	):Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree{
		return this.appService.userObservable.pipe(
			filter((user:any):boolean=>user!==null),
			map((user:any):boolean=>{
				console.log('user',user);
				if(user){
					this.router.navigate(['/profile']);
					return false;
				}else{
					return true;
				}
			}),
			catchError((error:any):Observable<boolean>=>{
				if(error.status===401){
					return of(true);
				}else{
					return of(true);
				}
			})
		);
	}
	
	canLoad(
		route:Route,
		segments:UrlSegment[]
	):Observable<boolean>|Promise<boolean>|boolean{
		return this.appService.userObservable.pipe(
			filter((user:any):boolean=>user!==null),
			map((user:any):boolean=>{
				console.log('user',user);
				if(user){
					this.router.navigate(['/profile']);
					return false;
				}else{
					return true;
				}
			}),
			catchError((error:any):Observable<boolean>=>{
				if(error.status===401){
					return of(true);
				}else{
					return of(true);
				}
			})
		);
	}
	
	
}
