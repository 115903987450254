<ng-container *ngIf="appService.isPlatformServer;else galleryBrowserTemplate">
	<div style="height:900px;"></div>
	<div style="height:500px;overflow: scroll">
		<ng-container *ngFor="let picture of pictures">
			<ng-container *ngIf="picture.type==='image'">
				<img [src]="picture.data.src" alt="lyo medical">
			</ng-container>
			<ng-container *ngIf="picture.type==='video'">
				<video [src]="picture.data.src" [poster]="picture.data.poster"></video>
			</ng-container>
		</ng-container>
	</div>
</ng-container>
<ng-template #galleryBrowserTemplate>
	<gallery [items]="pictures" id="homeGallery" [autoPlay]="true" [playerInterval]="20*1000">
		<div *galleryItemDef="let item; let type = type" >
<!--			<h1>{{type}}</h1>-->
<!--			<div *ngIf="type === 'customImage'">-->
<!--				<img [src]="item.src" [alt]="item.alt">-->
<!--			</div>-->
<!--			<div *ngIf="type === 'customVideo'">-->
<!--				<video [src]="item.src" muted></video>-->
<!--			</div>-->
		</div>
	</gallery>
</ng-template>
<mat-card>
	<mat-card-content fxLayout="column">

		<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="center stretch">
			<mat-card fxFlex="40" fxFlex.lt-md="auto">
				<mat-card-header>
					<mat-card-title class="app-primary-color">Preguntas frecuentes sobre cultivo</mat-card-title>
				</mat-card-header>
				<mat-card-content fxLayout="column" >
					<p fxFlex>Conoce las inquietudes y características más comunes sobre el cultivo y las prácticas más efectivas.</p>
					<a fxFlex [routerLink]="['/blog']">Aprender más</a>
				</mat-card-content>
			</mat-card>
			<mat-card fxFlex="40" fxFlex.lt-md="auto">
				<mat-card-header>
					<mat-card-title class="app-primary-color">Reservar una asesoría personalizada</mat-card-title>
				</mat-card-header>
				<mat-card-content fxLayout="column" >
					<p fxFlex>Agenda tu asesoría online para que logres todas tus expectativas evitando los errores más comunes del cultivo.</p>
					<a fxFlex href="https://wa.me/{{environment.contact.phone.replace('+','')}}" target="_blank">Reservar ahora</a>
				</mat-card-content>
			</mat-card>
		</div>

		<mat-card fxFlex fxLayout="column" class="mat-elevation-z0 home-specialty-card" fxFlexOffset="20px">
			<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="60px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" style="overflow: auto">
				<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center">
					<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center">
						<mat-icon fxFlex="nogrow" class="app-48 home-specialty-icon" color="primary">inventory_2</mat-icon>
						<h3 fxFlex class="home-specialty-title" fxFlexOffset="10px"><b>ENVÍOS A TODO COLOMBIA</b></h3>
					</div>
					<span fxFlex="nogrow" class="home-specialty-description">Hacemos envíos a todo el país y ofrecemos todos los métodos de pago online</span>
				</div>
				<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center">
					<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center">
						<mat-icon fxFlex="nogrow" class="app-48 home-specialty-icon" color="primary">shopping_cart_checkout</mat-icon>
						<h3 fxFlex class="home-specialty-title" fxFlexOffset="10px"><b>POLÍTICAS DE GARANTÍA</b></h3>
					</div>
					<span fxFlex="nogrow" class="home-specialty-description">Realiza tu proceso de garantía para darte una solución satisfactoria</span>
				</div>
				<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center">
					<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center">
						<mat-icon fxFlex="nogrow" class="app-48 home-specialty-icon" color="primary">support_agent</mat-icon>
						<h3 fxFlex class="home-specialty-title" fxFlexOffset="10px"><b>ASESORÍAS CON EXPERTOS</b></h3>
					</div>
					<span fxFlex="nogrow" class="home-specialty-description">Solucionamos tus dudas y te ayudamos a tener mejores resultados</span>
				</div>
				<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center">
					<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center center">
						<mat-icon fxFlex="nogrow" class="app-48 home-specialty-icon" color="primary">credit_score</mat-icon>
						<h3 fxFlex class="home-specialty-title" fxFlexOffset="10px"><b>PAGOS ONLINE INMEDIATOS Y SEGUROS</b></h3>
					</div>
					<span fxFlex="nogrow" class="home-specialty-description">No almacenamos tus datos de pago, nos respalda Mercado Pago</span>
				</div>
			</div>
		</mat-card>


	</mat-card-content>
</mat-card>
