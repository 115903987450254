<h2 mat-dialog-title>
	Recuperar contraseña
	<button mat-icon-button class="close-button" (click)="close()">
		<mat-icon>cancel</mat-icon>
	</button>
</h2>
<mat-dialog-content>
	<div>
		<div>
			<p>Introduce tu email:</p>
		</div>
		<div class="form-container">
			<form [formGroup]="form" (ngSubmit)="passwordResetToken()">
				<mat-form-field floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" appInputEmptyToNull required>
				</mat-form-field>
				<div>
					<app-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></app-form-message>
				</div>
				<div>
					<button type="submit" mat-button class="app-primary">Enviar</button>
				</div>
			</form>
		</div>
		<div>
			<p>Nuevo cliente? <a [routerLink]="['/signup']" (click)="close(true)">Crear cuenta</a></p>
			<p>Ya tienes una cuenta? <a [routerLink]="['/login']" (click)="close(true)">Entra aqui</a></p>
		</div>
	</div>
</mat-dialog-content>
