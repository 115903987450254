import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EnviosRoutingModule} from './envios-routing.module';
import {EnviosComponent} from './envios.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
	declarations:[
		EnviosComponent
	],
	imports:[
		CommonModule,
		EnviosRoutingModule,
		SharedModule
	]
})
export class EnviosModule{}
