<mat-sidenav-container class="sidenav-container" [ngClass]="{'app-dark-theme':appService.darkTheme}">
	<mat-sidenav #drawer class="sidenav" fixedInViewport role="dialog" mode="over">
		<bendita-drawer [drawerInput]="drawer" (drawerClosed)="drawer.close()"></bendita-drawer>
	</mat-sidenav>
	<mat-sidenav-content>
		<bendita-header [drawerInput]="drawer"></bendita-header>
		<router-outlet></router-outlet>
		<bendita-footer></bendita-footer>
	</mat-sidenav-content>
</mat-sidenav-container>
