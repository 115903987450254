import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {ProfileComponent} from './profile.component';
import {ProfileGuard} from './profile-guard.service';
import {AccountComponent} from './account/account.component';
import {AddressesComponent} from './addresses/addresses.component';
import {OrdersComponent} from './orders/orders.component';
import {PaymentComponent} from './payment/payment.component';
import {OrderDetailComponent} from './order-detail/order-detail.component';

const routes:Routes=[
	{
		path:'',
		component:ProfileComponent,
		canActivate:[ProfileGuard],
		children:[
			{
				path:'',
				pathMatch:'full',
				component:AccountComponent,
				canActivate:[ProfileGuard],
				canLoad:[ProfileGuard]
			},
			{
				path:'account',
				component:AccountComponent,
				canActivate:[ProfileGuard],
				canLoad:[ProfileGuard]
			},
			{
				path:'addresses',
				component:AddressesComponent,
				canActivate:[ProfileGuard],
				canLoad:[ProfileGuard]
			},
			{
				path:'payment',
				component:PaymentComponent,
				canActivate:[ProfileGuard],
				canLoad:[ProfileGuard]
			},
			{
				path:'orders',
				component:OrdersComponent,
				canActivate:[ProfileGuard],
				canLoad:[ProfileGuard]
			},
			{
				path:'orders/:id',
				component:OrderDetailComponent,
				canActivate:[ProfileGuard],
				canLoad:[ProfileGuard]
			}
		]
	}
];

@NgModule({
	imports:[RouterModule.forChild(routes)],
	exports:[RouterModule]
})
export class ProfileRoutingModule{}
