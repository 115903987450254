import {CanActivateFn,UrlTree} from '@angular/router';
import {CanMatchFn,Router} from '@angular/router';
import {CanActivateChildFn} from '@angular/router';
import {inject} from '@angular/core';
import {catchError,filter,firstValueFrom,map,Observable,of} from 'rxjs';
import {UserResponseDto} from './dto/user-response.dto';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';

export const authenticationGuardActivate:CanActivateFn=async():Promise<boolean|UrlTree>=>{
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(user){
				return inject(Router).parseUrl('/profile');
			}else{
				return true;
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const authenticationGuardMatch:CanMatchFn=async():Promise<boolean|UrlTree>=>{
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(user){
				return inject(Router).parseUrl('/profile');
			}else{
				return true;
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const authenticationGuardActivateChild:CanActivateChildFn=async():Promise<boolean|UrlTree>=>{
	return true;
};
