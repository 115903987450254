import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {CheckOutComponent} from './check-out.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';

const routes:Routes=[
	{path:'',component:CheckOutComponent},
	{path:':p1',component:ConfirmationComponent}
];

@NgModule({
	imports:[RouterModule.forChild(routes)],
	exports:[RouterModule]
})
export class CheckOutRoutingModule{}
