import {Injectable,Injector} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,HttpClient
} from '@angular/common/http';
import {catchError,concatMap,filter,switchMap,take} from 'rxjs/operators';
import {BehaviorSubject,Observable,throwError} from 'rxjs';
import {ApolloError} from '@apollo/client/core';
import {CookieService} from 'ngx-cookie-service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {environment} from '../../environments/environment';

@Injectable()
export class SessionInterceptor implements HttpInterceptor{
	private injector:Injector;
	private httpClient:HttpClient;
	private cookieService:CookieService;
	private jwtHelperService:JwtHelperService;
	private isRefreshing:boolean;
	refreshSubject:BehaviorSubject<any|undefined>;
	
	constructor(
		injector:Injector,
		httpClient:HttpClient,
		cookieService:CookieService
	){
		this.injector=injector;
		this.httpClient=httpClient;
		this.cookieService=cookieService;
		this.jwtHelperService=new JwtHelperService();
		this.isRefreshing=false;
		this.refreshSubject=new BehaviorSubject<any|undefined>(null);
		
	}
	
	sendError(error:any):Observable<never>{
		console.log('sendError',JSON.stringify(error),typeof error.error,error.error instanceof ProgressEvent,error.error instanceof ErrorEvent);
		if(error.error instanceof ProgressEvent){
			return throwError(():Error=>new Error(error.message));
		}else if(error.error instanceof ErrorEvent){
			return throwError(():Error=>new Error(error.message));
		}else{
			let graphqlError:any=error.graphQLErrors?.slice()?.shift()?.extensions?.response?.message;
			if(graphqlError){
				return throwError(():Error=>new Error(graphqlError));
			}else if(error.error){
				return throwError(():Error=>new Error(error.error.message || error.error));
			}else{
				return throwError(():any=>error);
			}
		}
	}
	
	intercept(request:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
		// console.log(
		// 	request.url,
		// 	window.localStorage.getItem('userId'),
		// 	this.cookieService.check('session'),
		// 	this.jwtHelperService.decodeToken(this.cookieService.get('session')),
		// 	this.jwtHelperService.getTokenExpirationDate(this.cookieService.get('session')),
		// 	this.jwtHelperService.isTokenExpired(this.cookieService.get('session'))
		// );
		const pipeline:any[]=[
			filter((e:HttpEvent<any>):boolean=>e.type!==0),
			// concatMap(async(response:HttpEvent<any>)=>{
			// 	// console.log('response',response);
			// 	if(response instanceof HttpResponse){
			// 		let errors=(<any>response)?.body?.errors;
			// 		if(errors){
			// 			let unauthorizedError:any=errors.find((element:any)=>element?.extensions?.response?.statusCode===401);
			// 			if(unauthorizedError){
			// 				throw {error:{message:unauthorizedError.extensions?.response?.message || unauthorizedError.message},response:response.body?.data};
			// 			}
			// 		}
			// 	}
			// 	return Promise.resolve(response);
			// }),
			catchError((error:any):Observable<never>=>{
				return this.sendError(error);
			})
			// catchError((error:any,restart:Observable<any>)=>{
			// 	console.error('catchError',error);
			// 	let refreshPath:string='/api/authentication/refresh-jwt';
			// 	if(error instanceof HttpErrorResponse){
			// 		if(error.url && new RegExp(refreshPath).test(error.url)){
			// 			console.log('throwError normal');
			// 			return this.sendError(error);
			// 		}else{
			// 			if(error.status===401){
			// 				console.log('concat');
			// 				return concat(
			// 					this.httpClient.post(refreshPath,{}),
			// 					restart
			// 				);
			// 				// .pipe(catchError((error2)=>throwError(()=>{
			// 				// 	console.log('catch2',error2)
			// 				// 	return {
			// 				// 		error:error2,
			// 				// 		// @ts-ignore
			// 				// 		response:error.error?.error?.respose
			// 				// 	}
			// 				// })));
			// 			}else{
			// 				console.log('throwError normal');
			// 				return this.sendError(error);
			// 			}
			// 		}
			// 	}else{
			// 		if(error.error?.error?.statusCode===401){
			// 			console.log('concat');
			// 			return concat(
			// 				this.httpClient.post(refreshPath,{}),
			// 				restart
			// 			);
			// 			// .pipe(catchError((error2)=>throwError(()=>{
			// 			// 	console.log('catch2',error2)
			// 			// 	return {
			// 			// 		error:error2,
			// 			// 		// @ts-ignore
			// 			// 		response:error.error?.error?.respose
			// 			// 	}
			// 			// })));
			// 		}else{
			// 			console.log('throwError normal');
			// 			return this.sendError(error);
			// 		}
			// 	}
			// })
		];
		let refreshPath:string=`${environment.apiServer.url}/api/authentication/refresh-jwt`;
		if(window.localStorage.getItem('userId') && !this.cookieService.check('session') && !new RegExp(refreshPath).test(request.url) && !new RegExp('.svg').test(request.url)){
			if(!this.isRefreshing){
				this.isRefreshing=true;
				this.refreshSubject.next(null);
				return this.httpClient.post(refreshPath,{},{withCredentials:true})
				.pipe(
					switchMap(():(Observable<HttpEvent<any>>)=>{
						this.isRefreshing=false;
						this.refreshSubject.next(true);
						return next.handle(request.clone())
						// @ts-ignore
						.pipe(...pipeline);
					}),
					catchError((error:any):Observable<never>=>{
						this.isRefreshing=false;
						this.refreshSubject.next(null);
						window.localStorage.removeItem('userId');
						return this.sendError(error);
					})
				);
			}else{
				return this.refreshSubject.asObservable()
				.pipe(
					filter((value:any):boolean=>value!==null),
					take(1),
					switchMap((value:any):(Observable<HttpEvent<any>>)=>{
						return next.handle(request.clone())
						// @ts-ignore
						.pipe(...pipeline);
					})
				);
			}
		}else{
			return next.handle(request.clone())
			// @ts-ignore
			.pipe(...pipeline);
		}
	}
	
}
