<mat-toolbar class="drawer-header">
	<button mat-icon-button type="button" (click)="closeMenu()" aria-label="Close">
		<mat-icon class="app-24">close</mat-icon>
	</button>
	<div class="spacer"></div>
	<button mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="(appService.theme | async)==='app-dark-theme' ? 'Light mode' : 'Dark mode'">
		<mat-icon>{{(appService.theme | async)==='app-dark-theme' ? 'light_mode' : 'dark_mode'}}</mat-icon>
	</button>
</mat-toolbar>
<div class="drawer-content">
	<mat-nav-list>
		<a mat-list-item [routerLink]="['/']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Inicio</span>
		</a>
		<a *ngIf="appService.user | async" mat-list-item [routerLink]="['/profile']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Mi perfil</span>
		</a>
		<a mat-list-item [routerLink]="['/blog']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Blog</span>
		</a>
		<mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<b>Productos</b>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-nav-list>
				<a mat-list-item [routerLink]="['/products']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()">
					<span class="app-14">Todos los productos</span>
				</a>
				@for (category of appService.productCategories | async; track category) {
					<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()">
						<span>{{category.label}}</span>
					</a>
				}
			</mat-nav-list>
		</mat-expansion-panel>
		<a mat-list-item [routerLink]="['/envios']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Politica de envios</span>
		</a>
		<a mat-list-item [routerLink]="['/contact']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Contacto</span>
		</a>
	</mat-nav-list>
	<div class="drawer-footer-container">
		<div class="drawer-footer">
			<a mat-icon-button class="drawer-footer-item mat-elevation-z1" href="https://wa.me/{{environment.contact.phone.replace('+','')}}" target="_blank" aria-label="Whatsapp">
				<mat-icon aria-label="whatsapp" [svgIcon]="'app-whatsapp'" class="drawer-footer-item-icon"></mat-icon>
			</a>
			<a mat-icon-button class="drawer-footer-item mat-elevation-z1" [href]="environment.contact.instagram" target="_blank" aria-label="Intagram">
				<mat-icon aria-label="instagram" [svgIcon]="'app-instagram'" class="drawer-footer-item-icon"></mat-icon>
			</a>
			<a mat-icon-button class="drawer-footer-item mat-elevation-z1" [href]="environment.contact.facebook" target="_blank" aria-label="Facebook">
				<mat-icon aria-label="facebook" [svgIcon]="'app-facebook'" class="drawer-footer-item-icon"></mat-icon>
			</a>
			<a mat-icon-button class="drawer-footer-item mat-elevation-z1" [href]="environment.contact.twitter" target="_blank" aria-label="Twitter">
				<mat-icon aria-label="twitter" [svgIcon]="'app-twitter'" class="drawer-footer-item-icon"></mat-icon>
			</a>
		</div>
	</div>
</div>
