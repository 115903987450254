import {Component} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {environment} from '../../../../environments/environment';

@Component({
	selector:'app-footer',
	imports:[
		SharedModule
	],
	templateUrl:'./footer.component.html',
	styleUrl:'./footer.component.scss'
})
export class FooterComponent{
	
	protected readonly environment=environment;
}
