import {Component,OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AppService} from './app.service';

@Component({
	selector:'bendita-root',
	templateUrl:'./app.component.html',
	styleUrls:['./app.component.scss']
})
export class AppComponent implements OnInit{
	private domSanitizer:DomSanitizer;
	private matIconRegistry:MatIconRegistry;
	appService:AppService;
	title:string;
	
	constructor(domSanitizer:DomSanitizer,matIconRegistry:MatIconRegistry,appService:AppService){
		this.domSanitizer=domSanitizer;
		this.matIconRegistry=matIconRegistry;
		this.appService=appService;
		this.title='Bendita';
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
		
		this.addIcons();
	}
	
	addIcons():void{
		this.matIconRegistry.addSvgIcon('app-facebook',this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-facebook.svg'));
		this.matIconRegistry.addSvgIcon('app-twitter',this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-twitter.svg'));
		this.matIconRegistry.addSvgIcon('app-instagram',this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-instagram.svg'));
		this.matIconRegistry.addSvgIcon('app-whatsapp',this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-whatsapp.svg'));
		
	}
	
}
