import {Component,DestroyRef,inject,input} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {environment} from '../../../../environments/environment';
import {BreakpointObserver,Breakpoints} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {DOCUMENT} from '@angular/common';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';

@Component({
	selector:'app-drawer',
	imports:[
		SharedModule
	],
	templateUrl:'./drawer.component.html',
	styleUrl:'./drawer.component.scss'
})
export class DrawerComponent{
	drawer=input.required<MatDrawer>();
	document=inject(DOCUMENT);
	destroyRef=inject(DestroyRef);
	breakpointObserver=inject(BreakpointObserver);
	appService=inject(AppService);
	authenticationService=inject(AuthenticationService);
	protected readonly environment=environment;
	isXSmallWindow=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	
	
}
