import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../../app.service';

@Injectable({
	providedIn:'root'
})
export class FooterService{
	private http:HttpClient;
	private appService:AppService;
	
	
	constructor(
		http:HttpClient,
		appService:AppService
	){
		this.http=http;
		this.appService=appService;
		
	}
	
}
