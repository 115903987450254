<div *ngIf="data?.serverErrorInput" class="form-message-text center error">
	<ng-container *ngIf="data?.serverErrorInput.graphQLErrors as errors;else normalError">
		<ng-container *ngIf="errors.slice()?.shift()?.extensions?.response as response;else messageError">
			Error: {{response.message?.toString()}}
		</ng-container>
		<ng-template #messageError>
			Error: {{errors.slice()?.shift()?.message}}
		</ng-template>
	</ng-container>
	<ng-template #normalError>
		{{data?.serverErrorInput?.toString()}}
	</ng-template>
</div>
<div *ngIf="data?.serverResponseInput" class="form-message-text center">
	{{data?.serverResponseInput?.toString()}}
</div>
