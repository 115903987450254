import {Component,Inject,OnInit} from '@angular/core';
import {FormGroup,Validators,FormBuilder} from '@angular/forms';
import {ProfileService} from '../profile.service';
import {AppService} from '../../app.service';
import {UpdateUserRequestDto} from '../dto/update-user-request.dto';
import {filter} from 'rxjs/operators';
import firebase from '@firebase/app';
import 'firebase/auth';
import {
	Auth,
	authState,
	signInAnonymously,
	signOut,
	User,
	GoogleAuthProvider,
	signInWithPopup,
	RecaptchaVerifier,
	signInWithPhoneNumber
} from '@angular/fire/auth';
import {ConfirmationResult,UserCredential} from '@firebase/auth';
import {firstValueFrom} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackMessageComponent} from '../../shared/snack-message/snack-message.component';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {PHONE_NUMBER_UTIL} from '../../shared/constants';


@Component({
	selector:'bendita-account',
	templateUrl:'./account.component.html',
	styleUrls:['./account.component.scss']
})
export class AccountComponent implements OnInit{
	title:string;
	readonly appService:AppService;
	readonly profileService:ProfileService;
	private readonly formBuilder:FormBuilder;
	private matSnackBar:MatSnackBar;
	resetPasswordForm:FormGroup;
	auth:Auth;
	submitButtonActive:boolean;
	updateUserForm:FormGroup;
	recaptchaVerifier?:RecaptchaVerifier;
	addPhoneForm:FormGroup;
	addPhoneServerError?:string;
	addPhoneServerResponse?:string;
	phoneConfirmationResult?:ConfirmationResult;
	updateEmailForm:FormGroup;
	updateEmailServerError?:string;
	updateEmailServerResponse?:string;
	readonly phoneNumberUtil:PhoneNumberUtil;
	emailVerificationToken:any;
	personalFile:any;
	
	constructor(
		appService:AppService,
		profileService:ProfileService,
		formBuilder:FormBuilder,
		auth:Auth,
		matSnackBar:MatSnackBar,
		@Inject(PHONE_NUMBER_UTIL) phoneNumberUtil:PhoneNumberUtil
	){
		this.title='Bendita - Mi perfil';
		this.appService=appService;
		this.profileService=profileService;
		this.formBuilder=formBuilder;
		this.auth=auth;
		this.matSnackBar=matSnackBar;
		this.auth.languageCode='es';
		this.submitButtonActive=true;
		this.resetPasswordForm=this.formBuilder.group({
			password:[undefined,[Validators.required]],
			newPassword:[undefined,[Validators.required]]
		});
		this.updateUserForm=this.formBuilder.group({
			firstName:[undefined,[Validators.required]],
			lastName:[undefined,[Validators.required]]
		});
		this.addPhoneForm=this.formBuilder.group({
			phoneCountryCode:['+57',[]],
			phone:[undefined,[]]
		});
		this.addPhoneServerError=undefined;
		this.addPhoneServerResponse=undefined;
		this.updateEmailForm=this.formBuilder.group({
			email:[undefined,[Validators.required,Validators.email]]
		});
		this.phoneNumberUtil=phoneNumberUtil;
		this.emailVerificationToken=undefined;
		this.personalFile=undefined;
		
		this.getEmailVerificationToken();
		this.getPersonalFile();
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'',
			''
		);
		this.appService.userObservable
		.pipe(filter((item:any):boolean=>item!==null))
		.subscribe({
			next:(user:any):void=>{
				this.updateUserForm.get('firstName')?.setValue(user.firstName);
				this.updateUserForm.get('lastName')?.setValue(user.lastName);
				this.updateEmailForm.get('email')?.setValue(user.email);
				if(user.phone){
					this.addPhoneForm.get('phoneCountryCode')?.setValue('+'+this.phoneNumberUtil.parseAndKeepRawInput(user.phone).getCountryCode()?.toString());
					this.addPhoneForm.get('phone')?.setValue(this.phoneNumberUtil.parseAndKeepRawInput(user.phone).getNationalNumber()?.toString());
				}
			}
		});
		
	}
	
	getOtp():void{
		this.recaptchaVerifier=new RecaptchaVerifier('verify-phone-recaptcha-container',{size:'normal'},this.auth);
		signInWithPhoneNumber(this.auth,`+${this.addPhoneForm.get('phoneCountryCode')?.value}${this.addPhoneForm.get('phone')?.value}`,this.recaptchaVerifier)
		.then((response:ConfirmationResult):void=>{
			console.log(response);
			this.phoneConfirmationResult=response;
			this.recaptchaVerifier?.clear();
			this.recaptchaVerifier=undefined;
		})
		.catch((error:any):void=>{
			this.addPhoneServerError=error;
			this.recaptchaVerifier?.clear();
			this.recaptchaVerifier=undefined;
		});
	}
	
	addPhone(code:string):void{
		console.log(code);
		this.phoneConfirmationResult?.confirm(code).then(async(result:UserCredential):Promise<void>=>{
			console.log(result);
			this.addPhoneServerError=undefined;
			this.addPhoneServerResponse=undefined;
			let next=(response:any):void=>{
				console.log(response);
				this.addPhoneServerResponse='Se ha verificado tu numero celular.';
				this.phoneConfirmationResult=undefined;
				this.recaptchaVerifier?.clear();
				this.recaptchaVerifier=undefined;
				this.addPhoneForm.get('phone')?.markAsPristine();
				firstValueFrom(this.appService.getUser()).then(console.log).catch(console.error);
			};
			let error=(error:string):void=>{
				this.recaptchaVerifier?.clear();
				this.recaptchaVerifier=undefined;
				this.addPhoneServerError=error;
			};
			this.profileService.addPhone({token:await result.user.getIdToken()})
			.subscribe({next,error});
		}).catch((error:any):void=>{
			this.addPhoneServerError=error;
		});
	}
	
	updateUser():void{
		if(this.submitButtonActive){
			if(!this.updateUserForm.valid){
				this.updateUserForm.markAllAsTouched();
			}else{
				this.submitButtonActive=false;
				let data:UpdateUserRequestDto={
					firstName:this.updateUserForm.get('firstName')?.value,
					lastName:this.updateUserForm.get('lastName')?.value
				};
				this.profileService.updateUser(data)
				.subscribe({
					next:(result:any):void=>{
						this.submitButtonActive=true;
						if(result.data?.updateUser) this.appService.setUser(result.data.updateUser);
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverResponseInput:'Datos guardados'
							}
						});
					},
					error:(error:any):void=>{
						this.submitButtonActive=true;
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverErrorInput:error
							}
						});
					}
				});
			}
		}
	}
	
	updatePassword():void{
		if(this.submitButtonActive){
			if(!this.resetPasswordForm.valid){
				this.resetPasswordForm.markAllAsTouched();
			}else{
				this.submitButtonActive=false;
				let next=():void=>{
					this.submitButtonActive=true;
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverResponseInput:'Datos guardados'
						}
					});
				};
				let error=(error:string):void=>{
					this.submitButtonActive=true;
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverErrorInput:error
						}
					});
				};
				this.profileService.updatePassword({
					password:this.resetPasswordForm.get('password')?.value,
					newPassword:this.resetPasswordForm.get('newPassword')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
	updateEmail():void{
		if(this.submitButtonActive){
			this.submitButtonActive=false;
			this.updateEmailServerError=undefined;
			this.updateEmailServerResponse=undefined;
			let next=():void=>{
				this.submitButtonActive=true;
				this.updateEmailServerResponse='Se ha enviado un email de verificacion.';
			};
			let error=(error:string):void=>{
				this.submitButtonActive=true;
				this.updateEmailServerError=error;
			};
			this.profileService.createEmailVerificationToken(this.updateEmailForm.get('email')?.value)
			.subscribe({next,error});
		}
	}
	
	async getEmailVerificationToken():Promise<void>{
		this.emailVerificationToken=await firstValueFrom(this.profileService.getEmailVerificationToken());
	}
	
	async deleteUser():Promise<void>{
		if(this.submitButtonActive && this.appService.window.confirm('Estas seguro de borrar tu cuenta?')){
			this.submitButtonActive=false;
			let next=():void=>{
				this.submitButtonActive=true;
				this.appService.window.location.reload();
			};
			let error=(error:string):void=>{
				this.submitButtonActive=true;
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:error
					}
				});
			};
			this.profileService.deleteUser()
			.subscribe({next,error});
		}
	}
	
	async getPersonalFile():Promise<void>{
		this.personalFile=await firstValueFrom(this.profileService.getPersonalFile());
	}
	
	async createPersonalFile():Promise<void>{
		await firstValueFrom(this.profileService.createPersonalFile()).catch(console.error);
		this.personalFile=await firstValueFrom(this.profileService.getPersonalFile());
	}
	
}
