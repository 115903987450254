import {Component,OnInit} from '@angular/core';
import {AppService} from '../app.service';

@Component({
	selector:'bendita-email-verified',
	templateUrl:'./email-verified.component.html',
	styleUrls:['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit{
	appService:AppService;
	title:string;
	
	constructor(appService:AppService){
		this.title='Bendita - Email verificado';
		this.appService=appService;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
	}
	
}
