import {NgModule} from '@angular/core';
import {CommonModule,DatePipe,NgIf,NgSwitchCase,NgTemplateOutlet} from '@angular/common';

import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileComponent} from './profile.component';
import {SharedModule} from '../shared/shared.module';
import {AccountComponent} from './account/account.component';
import {AddressesComponent} from './addresses/addresses.component';
import {OrdersComponent} from './orders/orders.component';
import {AddAddressPopupComponent} from './add-address-popup/add-address-popup.component';
import {PaymentComponent} from './payment/payment.component';
import {AddCardPopupComponent} from './payment/add-card-popup/add-card-popup.component';
import {OrderDetailComponent} from './order-detail/order-detail.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FlexModule} from '@angular/flex-layout';
import {MatDividerModule} from '@angular/material/divider';


@NgModule({
	declarations:[
		ProfileComponent,
		AccountComponent,
		AddressesComponent,
		OrdersComponent,
		AddAddressPopupComponent,
		PaymentComponent,
		AddCardPopupComponent,
		OrderDetailComponent
	],
	imports:[
		CommonModule,
		ProfileRoutingModule,
		SharedModule,
		MatButtonModule,
		MatCardModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		FlexModule,
		MatCardModule,
		MatIconModule,
		FlexModule,
		MatCardModule,
		FlexModule,
		MatCardModule,
		MatCardModule,
		FlexModule,
		MatCardModule,
		MatDividerModule,
		MatIconModule,
		NgIf,
		FlexModule,
		SharedModule,
		NgSwitchCase,
		NgTemplateOutlet,
		DatePipe,
		FlexModule,
		NgIf,
		FlexModule
	]
})
export class ProfileModule{}
