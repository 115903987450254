<mat-toolbar class="drawer-header">
	<div fxLayout="row" style="width:100%">
		<button fxFlex="noshrink" mat-icon-button type="button" (click)="closeMenu()" aria-label="Close">
			<mat-icon class="app-24">close</mat-icon>
		</button>
		<div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="" dir="rtl">
			<button fxFlex="nogrow" mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="appService.darkTheme ? 'Modo claro' : 'Modo oscuro'">
				<mat-icon>{{appService.darkTheme ? 'light_mode' : 'dark_mode'}}</mat-icon>
			</button>
<!--			<div fxFlex="nogrow" (click)="appService.changeTheme('green-theme')">-->
<!--				<mat-icon aria-label="Green theme" style="color: green" matTooltip="Green theme">developer_mode</mat-icon>-->
<!--			</div>-->
<!--			<div fxFlex="nogrow" (click)="appService.changeTheme('white-theme')">-->
<!--				<mat-icon aria-label="White theme" style="color: deepskyblue" matTooltip="White theme">developer_mode</mat-icon>-->
<!--			</div>-->
<!--			<div fxFlex="nogrow" (click)="appService.changeTheme('red-theme')">-->
<!--				<mat-icon aria-label="Red theme" style="color: orange" matTooltip="Red theme">developer_mode</mat-icon>-->
<!--			</div>-->
		</div>
	</div>
</mat-toolbar>
<div fxLayout="column">
	<mat-nav-list fxFlex fxLayout="column" fxLayoutGap="10">
		<a mat-list-item [routerLink]="['/']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Inicio</span>
		</a>
		<a *ngIf="appService.userObservable | async" mat-list-item [routerLink]="['/profile']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Mi perfil</span>
		</a>
		<a mat-list-item [routerLink]="['/blog']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Blog</span>
		</a>
		<mat-expansion-panel fxFlexFill class="mat-elevation-z0" [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<a><span>Productos</span></a>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-nav-list>
				<a mat-list-item [routerLink]="['/products']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()">
					<span class="app-14">Todos los productos</span>
				</a>
				<ng-container *ngFor="let category of appService.productCategoriesObservable | async">
					<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()">
						<span>{{category.label}}</span>
					</a>
				</ng-container>
			</mat-nav-list>
		</mat-expansion-panel>
		<a mat-list-item [routerLink]="['/envios']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Politica de envios</span>
		</a>
		<a mat-list-item [routerLink]="['/contact']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Contacto</span>
		</a>
	</mat-nav-list>
	<div fxFlexFill>
		<div style="min-height:150px;">
			<div fxLayout="row" fxLayoutAlign="center center" style="position: absolute;bottom: 0px;right: 0px;width: 100%;" dir="rtl" class="drawer-footer">
				<div fxFlex fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="10px" fxLayoutGap="10px" style="margin: 15px 0px 15px 15px;">
					<a fxFlex="nogrow" href="https://wa.me/{{environment.contact.phone.replace('+','')}}" fxFlexAlign="center" target="_blank" aria-label="Whatsapp">
						<mat-icon aria-label="whatsapp" [svgIcon]="'app-whatsapp'"></mat-icon>
					</a>
					<a fxFlex="nogrow" [href]="environment.contact.instagram" fxFlexAlign="center" target="_blank" aria-label="Intagram">
						<mat-icon aria-label="instagram" [svgIcon]="'app-instagram'"></mat-icon>
					</a>
					<a fxFlex="nogrow" [href]="environment.contact.facebook" fxFlexAlign="center" target="_blank" aria-label="Facebook">
						<mat-icon aria-label="facebook" [svgIcon]="'app-facebook'"></mat-icon>
					</a>
					<a *ngIf="false" fxFlex="nogrow" [href]="environment.contact.twitter" fxFlexAlign="center" target="_blank" aria-label="Twitter">
						<mat-icon aria-label="twitter" [svgIcon]="'app-twitter'"></mat-icon>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
