import {Component,inject,signal} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder,FormControl,Validators} from '@angular/forms';
import {SnackMessageComponent} from '../../misc/components/snack-message/snack-message.component';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';
import {skip,take} from 'rxjs';
import {AppService} from '../../app.service';

@Component({
	selector:'app-login-popup',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./login-popup.component.html',
	styleUrl:'./login-popup.component.scss'
})
export class LoginPopupComponent extends PopupComponent{
	private authenticationService=inject(AuthenticationService);
	private appService=inject(AppService);
	private matSnackBar=inject(MatSnackBar);
	private formBuilder=inject(FormBuilder);
	form=this.formBuilder.group({
		email:new FormControl<string|null>(null,{validators:[Validators.required,Validators.email]}),
		password:new FormControl<string|null>(null,{validators:[Validators.required]})
	});
	serverError=signal<any>(null);
	serverResponse=signal<any>(null);
	submitButtonActive=signal<boolean>(true);
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	login():void{
		if(this.submitButtonActive()){
			const {email,password}=this.form.getRawValue();
			const isNotValid=!email || !password;
			if(!this.form.valid || isNotValid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				this.authenticationService.login({email,password})
				.subscribe({
					next:async()=>{
						this.submitButtonActive.set(true);
						this.authenticationService.userObservable
						.pipe(
							skip(1),
							take(1)
						)
						.subscribe((user)=>{
							if(this.router.url==='/login'){
								this.close(false,'/profile');
							}else{
								this.matSnackBar.openFromComponent(SnackMessageComponent,{
									data:{
										serverResponseInput:'Bienvenido/a '+user?.firstName
									},
									duration:2000
								});
								this.close();
							}
							this.appService.getCart({}).subscribe();
						});
						this.authenticationService.reloadUser();
					},
					error:(error)=>{
						this.submitButtonActive.set(true);
						this.serverError.set(error);
					}
				});
			}
		}
	}
	
	
}
