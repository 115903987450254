import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../../environments/environment';
import {GOOGLE_MAPS} from './constants';
import {FactoryProvider} from '@angular/core';

export const GoogleMapsProvider:FactoryProvider={
	provide:GOOGLE_MAPS,
	useFactory:async():Promise<Loader>=>new Loader({
		apiKey:environment.google.apiKey
	})
};
