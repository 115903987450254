<mat-toolbar color="primary">
	<mat-toolbar-row fxFlex="nogrow" fxLayout="row">
		<div fxFlex="nogrow">
			<button mat-icon-button type="button" (click)="drawerInput?.toggle()" aria-label="Toggle sidenav">
				<mat-icon>menu</mat-icon>
			</button>
		</div>
		<div fxFlex="nogrow" style="height: 100%">
			<a [routerLink]="['/']" rel="bookmark">
				<img src="./assets/icons/favicon.png" alt="bendita" style="height:100%">
			</a>
		</div>
		<div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20" dir="rtl">
			<a *ngIf="appService.cartObservable | async" fxFlex="nogrow" fxFlexOffset="15px" fxLayout="column" class="app-pointer no-decoration" fxLayoutAlign="center center" [matBadge]="(appService.cartObservable | async).itemsCount" matBadgeColor="accent" matBadgePosition="above before" [routerLink]="['/cart']" aria-label="cart">
				<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center" matRipple>
					<mat-icon fxFlex="nogrow" aria-label="Cart" class="app-24">shopping_cart</mat-icon>
					<span *ngIf="!appService.isScreenXSmall()" fxFlex="nogrow" style="font-size: 12px;line-height: 14px;">Carrito</span>
				</div>
			</a>
			<ng-container>
				<ng-container *ngIf="appService.userObservable | async;else noUserTemplate">
					<div fxFlex="nogrow" fxLayout="column" class="app-pointer" fxLayoutAlign="center center" matRipple>
						<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center" [matMenuTriggerFor]="menuLogin">
							<mat-icon fxFlex="nogrow" aria-label="Profile" class="app-24">person</mat-icon>
							<span *ngIf="!appService.isScreenXSmall();else smallScreenTemplate" style="font-size: 12px;line-height: 14px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;max-width: 100px;direction: ltr;text-align:center;">Hola<br>{{(appService.userObservable | async)?.firstName}}</span>
							<ng-template #smallScreenTemplate>
								<span style="font-size: 10px;line-height: 12px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;max-width: 50px;direction: ltr;text-align:center;">{{(appService.userObservable | async)?.firstName}}</span>
							</ng-template>
						</div>
						<mat-menu #menuLogin="matMenu" fxFlex="noshrink" fxFlexAlign="baseline">
							<mat-nav-list>
								<a mat-list-item [routerLink]="['/profile','account']">
									<p>Mi perfil</p>
								</a>
								<a mat-list-item [routerLink]="['/profile','addresses']">
									<p>Mis direcciones</p>
								</a>
								<a mat-list-item [routerLink]="['/profile','orders']">
									<p>Mis ordenes</p>
								</a>
								<a mat-list-item (click)="appService.logout()" dir="ltr">
									Cerrar sesion &nbsp;
									<mat-icon>logout</mat-icon>
								</a>
							</mat-nav-list>
						</mat-menu>
					</div>
				</ng-container>
				<ng-template #noUserTemplate>
					<div fxFlex="nogrow" fxLayout="column" class="app-pointer" fxLayoutAlign="center center" matRipple>
						<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center" [matMenuTriggerFor]="menuLogin">
							<mat-icon fxFlex="nogrow" aria-label="Profile" class="app-24">person</mat-icon>
							<span *ngIf="!appService.isScreenXSmall()" fxFlex="nogrow" style="font-size: 12px;line-height: 14px;">Iniciar sesion / Registro</span>
						</div>
						<mat-menu #menuLogin="matMenu" fxFlex="noshrink" fxFlexAlign="baseline">
							<mat-nav-list>
								<a mat-list-item (click)="openLogin()">
									<p>Login</p>
								</a>
								<a mat-list-item (click)="openSignup()">
									<p>Crear Cuenta</p>
								</a>
								<a mat-list-item (click)="openForgotPassword()">
									<p>Recuperar password</p>
								</a>
							</mat-nav-list>
						</mat-menu>
					</div>
				</ng-template>
			</ng-container>
			<a fxFlex="nogrow" mat-icon-button type="button" [matMenuTriggerFor]="menuSearch" (click)="searchInput.focus()" aria-label="Search" role="search" id="anchor-header-search">
				<mat-icon class="app-24">search</mat-icon>
			</a>
			<mat-menu xPosition="after" fxLayout="column" fxLayoutAlign="center none" #menuSearch="matMenu" class="search-dropdown">
				<mat-form-field fxFlexFill (click)="$event.stopPropagation()" dir="ltr" style="text-align: left !important;" appearance="fill" placeholder="">
					<input #searchInput matInput type="search" placeholder="Busqueda" name="searchQuery" [(ngModel)]="searchQuery" benditaInputEmptyToNull (keyup)="searchProducts()" (search)="navigateToSearch()" autofocus autocomplete="off">
					<button *ngIf="searchQuery" matSuffix mat-icon-button aria-label="Clear" (click)="searchQuery=''">
						<mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<ng-container *ngFor="let product of products">
					<div mat-menu-item fxFlexFill fxLayout="row" [routerLink]="['/products',product.slug]" dir="ltr" style="text-align: left !important;">
						<div fxFlex="100px" style="height: 85px;max-width:100px;background-position: center; background-size: contain;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+product.pictures[0].small+')'}"></div>
						<div fxFlex="grow" fxLayout="column">
							<h3 fxFlex="nogrow" style="line-height: 28px!important;margin:0px!important;"><b>{{product.name}}</b></h3>
							<div fxFlex="nogrow" style="line-height: 28px!important;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{product.searchHighlights}}</div>
						</div>
					</div>
				</ng-container>
			</mat-menu>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
<mat-toolbar *ngIf="!appService.isScreenXSmall()" color="accent">
	<mat-toolbar-row>
		<mat-nav-list fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10" style="text-align: center">
			<a fxFlex="nogrow" [matMenuTriggerFor]="menu" mat-list-item [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:false}" id="anchor-header-products-menu-trigger" role="button">
				<span style="width: 100%;">Productos</span>
				<mat-menu #menu="matMenu" fxFlex="grow" fxFlexAlign="baseline">
					<mat-nav-list>
						<a mat-list-item [routerLink]="['/products']">
							<span style="width: 100%;" class="app-14">Todos los productos</span>
						</a>
						<ng-container *ngFor="let category of appService.productCategoriesObservable | async">
							<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
								<span style="width: 100%;">{{category.label}}</span>
							</a>
						</ng-container>
					</mat-nav-list>
				</mat-menu>
			</a>
			<mat-divider vertical style="height: 30px"></mat-divider>
			<a fxFlex="nogrow" mat-list-item [routerLink]="['/contact']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
				<span style="width: 100%;">Contacto</span>
			</a>
			<mat-divider vertical style="height: 30px"></mat-divider>
			<a fxFlex="nogrow" mat-list-item [routerLink]="['/envios']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
				<span style="width: 100%;">Politica de envios</span>
			</a>
			<mat-divider vertical style="height: 30px"></mat-divider>
			<a fxFlex="nogrow" mat-list-item [routerLink]="['/blog']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
				<span style="width: 100%;">Blog</span>
			</a>
		</mat-nav-list>
	</mat-toolbar-row>
</mat-toolbar>
