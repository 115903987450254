import {Component} from '@angular/core';

@Component({
	selector:'app-blog',
	imports:[],
	templateUrl:'./blog.component.html',
	styleUrl:'./blog.component.scss'
})
export class BlogComponent{

}
