import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientJsonpModule,HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GALLERY_CONFIG,GalleryConfig,GalleryModule} from 'ng-gallery';
import {LIGHTBOX_CONFIG,LightboxConfig,LightboxModule} from 'ng-gallery/lightbox';
import {CustomMaterialModule} from './custom-material.module';
import {FormMessageComponent} from './form-message/form-message.component';
import {InputEmptyToNullDirective} from './directives/input-empty-to-null.directive';
import {PopupComponent} from './popups/popup.component';
import {GooglePlacesAutocompleteComponent} from './google-places-autocomplete/google-places-autocomplete.component';
import {SnackMessageComponent} from './snack-message/snack-message.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {InputGreaterThanDirective} from './directives/input-greater-than.directive';
import {NgxFileDropModule} from 'ngx-file-drop';
import {CartItemCreatedSnackComponent} from './cart-item-created-snack/cart-item-created-snack.component';
import {OrderStatusTrackerComponent} from './order-status-tracker/order-status-tracker.component';
import {InputLessThanDirective} from './directives/input-less-than.directive';
import {PhoneNumberUtilProvider} from './phone-number-util.provider';
import {FilterPipe} from './pipes/filter.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import {TokenOrderPickUpPopupComponent} from './popups/token-order-pick-up-popup/token-order-pick-up-popup.component';
import {paypalProvider} from './paypal.provider';
import {GoogleMapsProvider} from './google-maps.provider';
import {GooglePlacesProvider} from './google-places.provider';
import {mercadoPagoProvider} from './mercado-pago.provider';

@NgModule({
	declarations:[
		FormMessageComponent,
		InputEmptyToNullDirective,
		PopupComponent,
		GooglePlacesAutocompleteComponent,
		SnackMessageComponent,
		InputGreaterThanDirective,
		InputLessThanDirective,
		CartItemCreatedSnackComponent,
		OrderStatusTrackerComponent,
		FilterPipe,
		TokenOrderPickUpPopupComponent
	],
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FlexLayoutModule.withConfig({addOrientationBps:true,useColumnBasisZero:false}),
		CustomMaterialModule,
		GalleryModule.withConfig({
			loop:true,
			thumb:false,
			autoPlay:false,
			playerInterval:10*1000,
			counter:false,
			dots:true
		}),
		LightboxModule.withConfig({
			keyboardShortcuts:true
		}),
		GoogleMapsModule,
		NgxFileDropModule,
		QRCodeModule
	],
	exports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FlexLayoutModule,
		CustomMaterialModule,
		FormMessageComponent,
		InputEmptyToNullDirective,
		InputLessThanDirective,
		GalleryModule,
		LightboxModule,
		PopupComponent,
		GooglePlacesAutocompleteComponent,
		SnackMessageComponent,
		GoogleMapsModule,
		InputGreaterThanDirective,
		NgxFileDropModule,
		CartItemCreatedSnackComponent,
		OrderStatusTrackerComponent,
		FilterPipe,
		QRCodeModule,
		TokenOrderPickUpPopupComponent
	],
	providers:[
		// {
		// 	provide: GALLERY_CONFIG,
		// 	useValue: {
		// 		loop:true,
		// 		thumb:false,
		// 		autoPlay:false,
		// 		playerInterval:10*1000,
		// 		counter:false,
		// 		dots:true
		// 	} as GalleryConfig
		// },
		// {
		// 	provide: LIGHTBOX_CONFIG,
		// 	useValue: {
		// 		keyboardShortcuts:true
		//
		// 	} as LightboxConfig
		// },
		PhoneNumberUtilProvider,
		paypalProvider,
		GoogleMapsProvider,
		GooglePlacesProvider,
		mercadoPagoProvider
	]
})
export class SharedModule{}
