<mat-card>
	<mat-card-header>
		<mat-card-title class="app-primary-color">
			Bienvenidos al Blog de Bendita Grow Shop
		</mat-card-title>
		<mat-card-subtitle>
			Bienvenidos al blog de Bendita Grow Shop con el manual de cultivo de marihuana y todo tipo de noticias cannábicas
		</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content fxLayout="column" fxLayoutGap="20px">
		<ng-container *ngFor="let blogPost of (blogPosts | async)">
			<mat-card fxFlex class="mat-elevation-z2" style="min-height: 300px;" mat-ripple>
				<mat-card-header [routerLink]="['/blog',blogPost.id]" class="app-pointer">
					<mat-card-title-group>
						<mat-card-title>
							{{blogPost.title}}
						</mat-card-title>
						<mat-card-subtitle>
							<span>{{blogPost.author}} - {{blogPost.createdAt | date:'d MMM y'}}</span>
							<span *ngIf="blogPost.updatedAt!==blogPost.createdAt"> - Actualizado: {{blogPost.updatedAt | date:'d MMM y'}}</span>
						</mat-card-subtitle>
						<img *ngIf="!(appService.isHandsetObservable | async)" mat-card-lg-image [src]="blogPost.cover.small" [alt]="blogPost.title">
					</mat-card-title-group>
				</mat-card-header>
				<img *ngIf="appService.isHandsetObservable | async" mat-card-image [src]="blogPost.cover.small" [alt]="blogPost.title" style="max-height: 300px;object-fit: cover;" [routerLink]="['/blog',blogPost.id]" mat-ripple>
				<mat-card-content>
					<h3>{{blogPost.intro}}</h3>
				</mat-card-content>
			</mat-card>
		</ng-container>
	</mat-card-content>
</mat-card>
<div #autoScrollElement></div>