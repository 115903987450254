<div *ngIf="data?.serverErrorInput;else noErrorTemplate" class="form-message-text center error">
	<ng-container *ngIf="data?.serverErrorInput.graphQLErrors as errors;else normalError">
		<ng-container *ngIf="errors.slice()?.shift()?.extensions?.response as response;else messageError">
			Error: {{response.message?.toString()}}
		</ng-container>
		<ng-template #messageError>
			Error: {{errors.slice()?.shift()?.message}}
		</ng-template>
	</ng-container>
	<ng-template #normalError>
		{{data?.serverErrorInput?.toString()}}
	</ng-template>
</div>
<ng-template #noErrorTemplate>
	<div fxLayout="row" class="form-message-text center">
		<span fxFlex fxFlexAlign="center">Articulo agregado</span>
		<a fxFlex fxFlexAlign="center" [routerLink]="['/cart']"><button mat-button class="app-primary">Ver carrito</button></a>
	</div>
</ng-template>
