import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../../environments/environment';
import {GOOGLE_PLACES} from './constants';
import {FactoryProvider} from '@angular/core';

export const GooglePlacesProvider:FactoryProvider={
	provide:GOOGLE_PLACES,
	useFactory:async():Promise<Loader>=>new Loader({
		apiKey:environment.google.apiKey
	})
};