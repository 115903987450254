import {Component,OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {CartService} from './cart.service';
import {firstValueFrom,Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UpdateCartItemRequestDto} from './dto/update-cart-item-request.dto';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackMessageComponent} from '../shared/snack-message/snack-message.component';
import {DeleteCartItemRequestDto} from './dto/delete-cart-item-request.dto';

@Component({
	selector:'bendita-cart',
	templateUrl:'./cart.component.html',
	styleUrls:['./cart.component.scss']
})
export class CartComponent implements OnInit{
	appService:AppService;
	private readonly cartService:CartService;
	submitButtonActive:boolean;
	private matSnackBar:MatSnackBar;
	title:string;
	
	
	constructor(
		appService:AppService,
		cartService:CartService,
		matSnackBar:MatSnackBar
	){
		this.title='Bendita - Carrito';
		this.appService=appService;
		this.cartService=cartService;
		this.submitButtonActive=true;
		this.matSnackBar=matSnackBar;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
	}
	
	getCartSubtotal():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number,currentValue:any):number=>{
					return previousValue+(currentValue.quantity*currentValue.product.price);
				},0);
			})
		);
	}
	
	getCartDiscount():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number,currentValue:any):number=>{
					if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price*(currentValue.product.discountPercent/100)));
					else return previousValue;
				},0);
			})
		);
	}
	
	getCartTotal():Observable<number>{
		return this.appService.cartObservable
		.pipe(
			map((cart:any):number=>{
				return cart.items.reduce((previousValue:number,currentValue:any):number=>{
					if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price-(currentValue.product.price*(currentValue.product.discountPercent/100))));
					else return previousValue+(currentValue.quantity*currentValue.product.price);
				},0);
			})
		);
	}
	
	async updateCartItem(product:string,quantity:string):Promise<void>{
		if(this.submitButtonActive){
			this.submitButtonActive=false;
			let data:UpdateCartItemRequestDto={
				id:(await firstValueFrom(this.appService.cartObservable)).id,
				product:product,
				quantity:Number(quantity)
			};
			this.cartService.updateCartItem(data)
			.subscribe({
				next:():void=>{
					this.submitButtonActive=true;
					this.appService.getCart();
				},
				error:(error:any):void=>{
					this.submitButtonActive=true;
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverErrorInput:error
						}
					});
				}
			});
		}
	}
	
	async deleteCartItem(product:string):Promise<void>{
		if(this.submitButtonActive && this.appService.window.confirm('Desea borrar el producto?')){
			this.submitButtonActive=false;
			let data:DeleteCartItemRequestDto={
				id:(await firstValueFrom(this.appService.cartObservable)).id,
				product:product
			};
			this.cartService.deleteCartItem(data)
			.subscribe({
				next:():void=>{
					this.submitButtonActive=true;
					this.appService.getCart();
				},
				error:(error:any):void=>{
					this.submitButtonActive=true;
					this.matSnackBar.openFromComponent(SnackMessageComponent,{
						data:{
							serverErrorInput:error
						}
					});
				}
			});
		}
	}
	
}
