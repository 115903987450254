@if (data?.serverErrorInput; as errors) {
	<div class="form-message-text error">
		@if (data?.serverErrorInput.graphQLErrors; as errors) {
			@if (errors.slice()?.shift()?.extensions?.response; as response) {
				Error: {{response.message?.toString()}}
			} @else {
				Error: {{errors.slice()?.shift()?.message}}
			}
		} @else if (data?.serverErrorInput?.error) {
			@if (data?.serverErrorInput?.error?.message) {
				Error: {{data?.serverErrorInput?.error?.message}}
			} @else {
				{{data?.serverErrorInput?.error.toString()}}
			}
		} @else {
			{{data?.serverErrorInput?.toString()}}
		}
	</div>
}
@if (data?.serverResponseInput) {
	<div class="form-message-text center">
		{{data?.serverResponseInput?.toString()}}
	</div>
}
