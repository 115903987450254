<mat-card>
	<mat-card-content fxLayout="column" fxLayoutGap="20px">
		<ng-container *ngIf="(orders | async) as orders">
			<ng-container *ngIf="orders.length===0;else hasOrdersTemplate">
				<div fxLayout="column" fxLayoutAlign="center center">
					<h3 fxFlex="nogrow">Todavia no tienes ordenes. Compra tus productos ahora</h3>
					<button fxFlex="nogrow" mat-raised-button color="primary" class="buy-button" [routerLink]="['/products']">
						Ir a comprar
					</button>
				</div>
			</ng-container>
			<ng-template #hasOrdersTemplate>
				<mat-nav-list  fxLayout="column" fxLayoutGap="20px" style="min-height: 400px">
					<ng-container *ngFor="let order of orders">
						<a mat-list-item [routerLink]="['/profile','orders',order.slug]" style="height: unset" class="mat-elevation-z3">
							<div fxFlexFill fxLayout="row">
								<div fxFlex fxLayout="column">
									<p fxFlex fxFlexOffset="10px">Referencia: {{order.slug}}</p>
									<p fxFlex="nogrow" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
										<span fxFlex="nogrow">Estado: </span>
										<mat-icon fxFlex="nogrow" class="app-24" color="primary">{{order.status.icon}}</mat-icon>
										<span fxFlex="nogrow">{{order.status.label}}</span>
									</p>
									<p fxFlex>Fecha: {{order.createdAt | date:'d MMM y, h:mm a'}}</p>
									<p fxFlex>Total: {{order.total.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</p>
									<p fxFlex>{{getOrderItemsCount(order)}} Producto{{getOrderItemsCount(order)===1 ? '' : 's'}}</p>
								</div>
								<ng-container *ngIf="appService.isScreenLarge() || appService.isScreenXLarge()">
									<div fxFlex="50" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
										<ng-container *ngFor="let item of order.items">
											<div fxFlex="nogrow" style="height: 50px;width:50px;background-position: center; background-size: contain;background-repeat: no-repeat" [ngStyle]="{'background-image':'url('+item.product.picture+')'}" matBadge="{{item.quantity}}" matBadgeColor="primary" matBadgePosition="above after"></div>
										</ng-container>
									</div>
								</ng-container>
							</div>
						</a>
					</ng-container>
				</mat-nav-list>
			</ng-template>
		</ng-container>
	</mat-card-content>
</mat-card>
<div #autoScrollElement></div>
