import {inject,Injectable} from '@angular/core';
import {GetProductsGQL} from './graphql/get-products.generated';
import {GetProductsQuery,GetProductsQueryVariables} from './graphql/get-products.generated';
import {map,Observable} from 'rxjs';

@Injectable({
	providedIn:'root'
})
export class IndexService{
	private readonly getProductsGQL=inject(GetProductsGQL);
	
	getProducts(variables:GetProductsQueryVariables):Observable<GetProductsQuery['getProducts']>{
		return this.getProductsGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result)=>{
					if(!result.data?.getProducts) throw new Error('data not found');
					return result.data.getProducts;
				}
			)
		);
	}
}
