import {afterNextRender,Component,Inject,OnInit} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {HeaderComponent} from './misc/components/header/header.component';
import {DrawerComponent} from './misc/components/drawer/drawer.component';
import {AppService} from './app.service';
import {DOCUMENT} from '@angular/common';
import {MatSidenav,MatSidenavContainer,MatSidenavContent} from '@angular/material/sidenav';
import {RouterOutlet} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {FooterComponent} from './misc/components/footer/footer.component';

@Component({
	selector:'app-root',
	imports:[
		SharedModule,
		HeaderComponent,
		DrawerComponent,
		DrawerComponent,
		HeaderComponent,
		MatSidenav,
		MatSidenavContainer,
		MatSidenavContent,
		RouterOutlet,
		FooterComponent
	],
	templateUrl:'./app.component.html',
	styleUrl:'./app.component.scss'
})
export class AppComponent implements OnInit{
	title:string;
	appService:AppService;
	private matIconRegistry:MatIconRegistry;
	private domSanitizer:DomSanitizer;
	
	constructor(
		appService:AppService,
		@Inject(DOCUMENT) document:Document,
		matIconRegistry:MatIconRegistry,
		domSanitizer:DomSanitizer
	){
		this.title='Bendita';
		this.appService=appService;
		this.matIconRegistry=matIconRegistry;
		this.domSanitizer=domSanitizer;
		
		afterNextRender(():void=>{
			document.defaultView?.scrollTo(0,0);
		});
	}
	
	ngOnInit():void{
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/icons/icon-512x512.png'
		);
		
		this.matIconRegistry.addSvgIcon('app-facebook',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-facebook.svg'));
		this.matIconRegistry.addSvgIcon('app-twitter',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-twitter.svg'));
		this.matIconRegistry.addSvgIcon('app-instagram',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-instagram.svg'));
		this.matIconRegistry.addSvgIcon('app-whatsapp',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-whatsapp.svg'));
		
	}
	
}
