import {Injectable} from '@angular/core';
import {Apollo,gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {GetOrderRequestDto} from '../check-out/dto/get-order-request.dto';
import {HttpClient} from '@angular/common/http';
import {map,take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {GetOrderEventsRequestDto} from './dto/get-order-events-request.dto';

@Injectable({
	providedIn:'root'
})
export class TrackingService{
	private readonly apollo:Apollo;
	private httpClient:HttpClient;
	
	constructor(apollo:Apollo,httpClient:HttpClient){
		this.apollo=apollo;
		this.httpClient=httpClient;
		
	}
	
	getShippingTypes():Observable<any>{
		return this.apollo
		.query({
			query:gql`query getShippingTypes{
          getShippingTypes{
              updatedAt
              id
              createdAt
              label
              icon
          }
      }`
		});
	}
	
	getPaymentTypes():Observable<any>{
		return this.apollo
		.query({
			query:gql`query getPaymentTypes{
          getPaymentTypes{
              updatedAt
              id
              createdAt
              label
              icon
              description
          }
      }`
		});
	}
	
	getOrder(variables:GetOrderRequestDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`query getOrder($id:String!,$guestToken:String){
          getOrder(id:$id,guestToken:$guestToken){
              updatedAt
              id
              createdAt
              slug
              status {
                  id
                  label
                  description
                  icon
                  order
              }
              paymentType {
                  id
                  label
                  icon
                  description
                  descriptionLong
									paymentAccount
              }
              shippingType {
                  label
                  id
                  icon
              }
              email
              name
              shippingAddress {
                  name
                  city
                  country
                  line2
                  phone
                  state
                  zip
                  line1
              }
              subtotal
              discount
              shipping
              total
              items {
                  product {
                      name
                      id
                      picture
                      discountPercent
                      price
                  }
                  quantity
              }
              user {
                  id
              }
              phone
              proofOfPayment
              store {
                  id
                  city
                  country
                  createdAt
                  line1
                  line2
                  location {
                      coordinates
                  }
                  name
                  phone
                  placeId
                  state
                  updatedAt
                  zip
              }
							transactionId
              openNodeCharge {
                  address
                  amount
                  auto_settle
                  chain_invoice {
                      address
                      settled_at
                      tx
                  }
                  created_at
                  currency
                  description
                  expires_at
                  fiat_value
                  id
                  metadata {
                      name
                      email
                      invoice_id
                  }
                  missing_amt
                  order_id
                  source_fiat_value
                  status
                  transactions {
                      status
                      created_at
                      tx
                      settled_at
                      address
                      amount
                  }
                  fee
              }
              mercadoPagoPayment {
                  id
                  status
                  description
                  status_detail
                  payer {
                      id
                      address {
                          street_name
                          street_number
                          zip_code
                      }
                      email
                      entity_type
                      first_name
                      identification {
                          number
                          type
                      }
                      last_name
                      phone {
                          number
                          area_code
                          extension
                      }
                  }
                  captured
                  card {
                      id
                      cardholder {
                          identification {
                              number
                              type
                          }
                          name
                      }
                      expiration_month
                      expiration_year
                      last_four_digits
                  }
                  currency_id
                  net_amount
                  payment_method {
                      type
                      id

                  }
                  transaction_details {
                      installment_amount
                      net_received_amount
                      overpaid_amount
                      total_paid_amount
                      transaction_id

                  }
              }
              paypalOrder {
                  status
                  id
                  intent
                  payer {
                      name {
                          given_name
                          surname
                      }
                      email_address
                      payer_id
                  }
                  payment_source {
                      paypal {
                          email_address
                          name {
                              surname
                              given_name
                          }
                          account_id
                          account_status
                      }
                  }
              }
              mercadoPagoPreference {
                  id
                  description
                  date_created
                  date_of_expiration
                  purpose
              }
              guestToken
          }
      }`
		}).pipe(map((result:any):any=>result.data?.getOrder));
	}
	
	createProofOfPayment(id:string,file:File,guestToken?:string):Observable<any>{
		const data:FormData=new FormData();
		data.append('file',file);
		data.append('id',id);
		if(guestToken) data.append('guestToken',guestToken);
		return this.httpClient.post(`${environment.apiServer.url}/api/order/proof-of-payment`,data,{withCredentials:true});
	}
	
	getOrderEvents(variables:GetOrderEventsRequestDto):Observable<any[]>{
		return this.apollo
		.query({
			variables,
			query:gql`query getOrderEvents(
          $id: String!
          $guestToken: String!
          $limit: Float!
          $skip: Float!
      ){
          getOrderEvents (
              id: $id
              guestToken: $guestToken
              limit: $limit
              skip: $skip
          ){
              updatedAt
              id
              createdAt
              description
          }
      }`
		}).pipe(map((result:any):any=>result.data?.getOrderEvents));
	}
	
	getTokenOrderPickUp(data:GetOrderRequestDto):Observable<any>{
		return this.httpClient.get(`${environment.apiServer.url}/api/authentication/token-order-pick-up?id=${data.id}&guestToken=${data.guestToken}`,{withCredentials:true}).pipe(take(1));
	}
	
}
