import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Apollo,gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {GetProductsDto} from './dto/get-products.dto';
import {GetProductDto} from './dto/get-product.dto';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn:'root'
})
export class ProductsService{
	private httpClient:HttpClient;
	private readonly apollo:Apollo;
	
	constructor(httpClient:HttpClient,apollo:Apollo){
		this.httpClient=httpClient;
		this.apollo=apollo;
		
	}
	
	getProducts(variables:GetProductsDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`
          query getProducts(
              $limit:Float!,
              $skip:Float!,
              $sortOrder:Float!,
              $category:String,
              $filter:GetProductsFilter!,
              $sortFactor:GetProductsSortFactor!,
					){
              getProducts(
                  category: $category
                  filter: $filter
                  limit:$limit
                  skip:$skip
                  sortFactor: $sortFactor
                  sortOrder: $sortOrder
							) {
                  createdAt
                  description
                  id
                  name
                  pictures {
                      large
                      small
                  }
                  price
                  quantity
                  slug
                  updatedAt
                  discountPercent
              }
          }
			`
		}).pipe(map((result:any):any[]=>result.data?.getProducts));
	}
	
	getProduct(variables:GetProductDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`
          query getProduct($id: String!){
              getProduct(id: $id) {
                  createdAt
                  description
                  id
                  name
                  pictures {
                      large
                      small
                  }
                  price
                  quantity
                  slug
                  updatedAt
                  category {
                      createdAt
                      id
                      label
                      slug
                      updatedAt
                  }
                  discountPercent
									descriptionSeo
									keywordsSeo
									nameSeo
                  videos {
                      original
                  }
              }
          }
			`
		}).pipe(map((result:any):any=>result.data?.getProduct));
	}
	
	
}
