import {Component,Inject,OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
	selector:'bendita-snack-message',
	templateUrl:'./snack-message.component.html',
	styleUrls:['./snack-message.component.scss']
})
export class SnackMessageComponent implements OnInit{
	data?:any;
	
	constructor(@Inject(MAT_SNACK_BAR_DATA) data:any){
		this.data=data;
	}
	
	ngOnInit():void{
	}
	
}
