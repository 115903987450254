import {Directive,ElementRef,HostListener,Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
	selector:'[benditaInputGreaterThan]'
})
export class InputGreaterThanDirective{
	@Input() benditaInputGreaterThan?:string;
	private elementRef:ElementRef;
	
	constructor(elementRef:ElementRef){
		this.elementRef=elementRef;
	}
	
	@HostListener('input',['$event.target'])
	onEvent(target:HTMLInputElement):void{
		this.putValue(target);
	}
	
	@HostListener('blur',['$event.target'])
	onEvent2(target:HTMLInputElement):void{
		this.putValue(target);
	}
	
	@HostListener('change',['$event.target'])
	onEvent3(target:HTMLInputElement):void{
		this.putValue(target);
	}
	
	putValue(target:HTMLInputElement):void{
		const value=!!this.benditaInputGreaterThan ? Number(this.benditaInputGreaterThan) : 0;
		this.elementRef.nativeElement.value=Number(target.value)>value ? target.value : this.benditaInputGreaterThan;
	}
}
