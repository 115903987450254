<div mat-dialog-title class="app-dialog-title">
	Recuperar contraseña
	<button mat-icon-button class="close-button" (click)="close()">
		<mat-icon>cancel</mat-icon>
	</button>
</div>
<mat-dialog-content>
	<p class="app-dialog-content-title">
		Introduce tu email:
	</p>
	<form [formGroup]="form" (ngSubmit)="passwordResetToken()">
		<mat-form-field floatLabel="auto">
			<input matInput type="email" placeholder="Email" name="email" formControlName="email" appInputEmptyToNull required>
		</mat-form-field>
		<div>
			<app-form-message [serverError]="serverError()" [serverResponse]="serverResponse()"></app-form-message>
		</div>
		<div>
			<button type="submit" mat-raised-button [disabled]="!submitButtonActive()">Enviar</button>
		</div>
	</form>
	<br>
	<mat-divider></mat-divider>
	<br>
	<p>Nuevo cliente? <a [routerLink]="['/signup']" (click)="close(true)">Crear cuenta</a></p>
	<p>Ya tienes una cuenta? <a [routerLink]="['/login']" (click)="close(true)">Entra aqui</a></p>
</mat-dialog-content>
