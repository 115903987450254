import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BlogRoutingModule} from './blog-routing.module';
import {BlogComponent} from './blog.component';
import {BlogPostDetailComponent} from './blog-post-detail/blog-post-detail.component';
import {SharedModule} from '../shared/shared.module';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
	declarations:[
		BlogComponent,
		BlogPostDetailComponent
	],
	imports:[
		CommonModule,
		BlogRoutingModule,
		SharedModule,
		MatCardModule,
		MatButtonModule,
		MatCardModule,
		MatIconModule,
		MatCardModule
	]
})
export class BlogModule{}
