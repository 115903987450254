import {Component,DestroyRef,ElementRef,Input,ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LoginPopupComponent} from '../../../authentication/login-popup/login-popup.component';
import {SignupPopupComponent} from '../../../authentication/signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from '../../../authentication/forgot-password-popup/forgot-password-popup.component';
import {debounceTime,distinctUntilChanged,Observable,switchMap} from 'rxjs';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {InputEmptyToNullDirective} from '../../directives/input-empty-to-null/input-empty-to-null.directive';
import {FormControl} from '@angular/forms';
import {SearchProductsQuery} from '../../../common/graphql/search-products.generated';

@Component({
	selector:'app-header',
	imports:[
		SharedModule,
		InputEmptyToNullDirective
	],
	templateUrl:'./header.component.html',
	styleUrl:'./header.component.scss'
})
export class HeaderComponent{
	private matDialog:MatDialog;
	private router:Router;
	private readonly breakpointObserver:BreakpointObserver;
	@ViewChild('searchFormField') private searchFormField!:ElementRef;
	@ViewChild('searchInput') private searchInput!:ElementRef;
	@Input() drawerInput?:MatDrawer;
	appService:AppService;
	authenticationService:AuthenticationService;
	isSmallGrid:Observable<BreakpointState>;
	searchQuery?:string;
	search=new FormControl();
	filteredProducts:Observable<SearchProductsQuery['searchProducts']>;
	
	constructor(
		destroyRef:DestroyRef,
		matDialog:MatDialog,
		router:Router,
		breakpointObserver:BreakpointObserver,
		appService:AppService,
		authenticationService:AuthenticationService
	){
		this.matDialog=matDialog;
		this.router=router;
		this.breakpointObserver=breakpointObserver;
		this.appService=appService;
		this.authenticationService=authenticationService;
		this.drawerInput=undefined;
		
		this.isSmallGrid=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
		.pipe(
			takeUntilDestroyed(destroyRef)
		);
		this.filteredProducts=this.search.valueChanges.pipe(
			debounceTime(400),
			distinctUntilChanged(),
			switchMap((value)=>{
				return this.appService.searchProducts({limit:5,skip:0,query:value});
			})
		);
	}
	
	openLogin():void{
		const dialogRef:MatDialogRef<LoginPopupComponent>=this.matDialog.open(
			LoginPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openSignup():void{
		const dialogRef:MatDialogRef<SignupPopupComponent>=this.matDialog.open(
			SignupPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openForgotPassword():void{
		const dialogRef:MatDialogRef<ForgotPasswordPopupComponent>=this.matDialog.open(
			ForgotPasswordPopupComponent,
			{
				panelClass:'dialog-responsive',
				height:'90%',
				data:{}
			}
		);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	toggleSearch(){
		if(this.searchFormField.nativeElement.hidden){
			this.searchFormField.nativeElement.hidden=false;
			this.searchInput.nativeElement.focus();
		}else{
			this.searchFormField.nativeElement.hidden=true;
		}
	}
	
	focusoutSearch(){
		setTimeout(()=>{
			if(!this.searchFormField.nativeElement.hidden) this.searchFormField.nativeElement.hidden=true;
		},200);
	}
	
	navigateSearch(event:Event){
		const query:string=(event.target as HTMLInputElement)?.value;
		if(query) this.router.navigate(['/search',query]);
	}
	
}
