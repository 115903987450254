import {afterNextRender,ChangeDetectorRef,Component,DestroyRef,Inject,OnInit,PLATFORM_ID} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AppService} from '../app.service';
import {GalleryItem,ImageItem,VideoItem} from 'ng-gallery';
import {DOCUMENT,isPlatformServer} from '@angular/common';
import {ActivatedRoute,Router,UrlSegment} from '@angular/router';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {takeUntil,tap} from 'rxjs';
import {ComponentType} from '@angular/cdk/portal';
import {LoginPopupComponent} from '../authentication/login-popup/login-popup.component';
import {SignupPopupComponent} from '../authentication/signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from '../authentication/forgot-password-popup/forgot-password-popup.component';
import {environment} from '../../environments/environment';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
	selector:'app-index',
	imports:[
		SharedModule
	],
	templateUrl:'./index.component.html',
	styleUrl:'./index.component.scss'
})
export class IndexComponent implements OnInit{
	protected readonly environment=environment;
	private activatedRoute:ActivatedRoute;
	private matDialog:MatDialog;
	private router:Router;
	private readonly changeDetectorRef:ChangeDetectorRef;
	private readonly destroyRef:DestroyRef;
	appService:AppService;
	title:string;
	pictures:GalleryItem[]&VideoItem[];
	isPlatformServer:boolean;
	
	constructor(
		activatedRoute:ActivatedRoute,
		matDialog:MatDialog,
		router:Router,
		changeDetectorRef:ChangeDetectorRef,
		destroyRef:DestroyRef,
		appService:AppService,
		@Inject(DOCUMENT) document:Document,
		@Inject(PLATFORM_ID) private platformId:object
	){
		this.activatedRoute=activatedRoute;
		this.matDialog=matDialog;
		this.router=router;
		this.changeDetectorRef=changeDetectorRef;
		this.destroyRef=destroyRef;
		this.appService=appService;
		this.title='Bendita';
		this.pictures=[
			new ImageItem({
				src:'/images/home1.png',
				thumb:'/images/home1.png',
				alt:'Bendita'
			}),
			new ImageItem({
				src:'/images/home2.png',
				thumb:'/images/home2.png',
				alt:'Bendita'
			}),
			new ImageItem({
				src:'/images/home3.png',
				thumb:'/images/home3.png',
				alt:'Bendita'
			}),
			new ImageItem({
				src:'/images/home4.png',
				thumb:'/images/home4.png',
				alt:'Bendita'
			}),
			new VideoItem({
				src:'/videos/home1.mp4',
				type:'video/mp4',
				poster:'/videos/home1.png',
				controls:false,
				mute:true,
				autoplay:true,
				loop:true
			})
		];
		this.isPlatformServer=isPlatformServer(platformId);
		
		afterNextRender(():void=>{
			document.defaultView?.scrollTo(0,0);
		});
	}
	
	ngOnInit():void{
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/icons/icon-512x512.png'
		);
		
		if(!this.isPlatformServer){
			this.activatedRoute.url.pipe(
				tap((urlSegments:UrlSegment[]):void=>{
					let component:ComponentType<any>|undefined;
					switch(urlSegments[0]?.path){
						case('login'):{
							component=LoginPopupComponent;
							break;
						}
						case('signup'):{
							component=SignupPopupComponent;
							break;
						}
						case('forgot-password'):{
							component=ForgotPasswordPopupComponent;
							break;
						}
					}
					if(component){
						this.matDialog.open(
							component,
							{
								panelClass:'dialog-responsive',
								height:'90%',
								data:{}
							}
						)
						.afterClosed()
						.subscribe((data:any):void=>{
							if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
							this.changeDetectorRef.detectChanges();
						});
					}
				}),
				takeUntilDestroyed(this.destroyRef)
			).subscribe();
		}
		
	}
	
	
}
