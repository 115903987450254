<h2 mat-dialog-title>
	Crear mi cuenta
	<button mat-icon-button class="close-button" (click)="close()">
		<mat-icon>cancel</mat-icon>
	</button>
</h2>
<mat-dialog-content>
	<div>
		<div>
			<p>Por favor complete la información a continuación:</p>
		</div>
		<div class="form-container">
			<form [formGroup]="form" (ngSubmit)="signUp()">
				<mat-form-field floatLabel="auto">
					<input matInput type="text" placeholder="Nombre" name="firstName" formControlName="firstName" appInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field floatLabel="auto">
					<input matInput type="text" placeholder="Apellido" name="lastName" formControlName="lastName" appInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" appInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field floatLabel="auto">
					<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" appInputEmptyToNull required>
				</mat-form-field>
				<div>
					<app-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></app-form-message>
				</div>
				<div>
					<button type="submit" mat-button class="app-primary">Crear cuenta</button>
				</div>
			</form>
		</div>
		<div>
			<p>Ya tienes una cuenta? <a [routerLink]="['/login']" (click)="close(true)">Entra aqui</a></p>
			<p>Contraseña olvidada? <a [routerLink]="['/forgot-password']" (click)="close(true)">Recuperar contraseña</a></p>
		</div>
	</div>
</mat-dialog-content>
