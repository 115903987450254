import {Component} from '@angular/core';

@Component({
	selector:'app-envios',
	imports:[],
	templateUrl:'./envios.component.html',
	styleUrl:'./envios.component.scss'
})
export class EnviosComponent{

}
