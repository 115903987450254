<mat-toolbar color="primary" role="heading">
	<mat-toolbar-row class="header-content">
		<button mat-icon-button class="menu-button" (click)="drawerInput?.toggle()" aria-label="Toggle sidenav">
			<mat-icon>menu</mat-icon>
		</button>
		<a [routerLink]="['/']" rel="bookmark" class="logo-home">
			<img ngSrc="/icons/favicon.png" alt="home" [height]="70" [width]="70" priority>
		</a>
		<div class="spacer"></div>
		<button mat-icon-button aria-label="Search" class="search-button app-pointer" (click)="toggleSearch()">
			<mat-icon class="app-24">search</mat-icon>
		</button>
		@if (appService.user | async) {
			<a class="login-button-container app-pointer" aria-label="profile" [matMenuTriggerFor]="menuLogin">
				<button mat-icon-button class="login-button">
					<mat-icon aria-label="Profile" class="app-24">person</mat-icon>
				</button>
				<span *ngIf="!(isSmallGrid | async)?.matches" class="button-item app-12">{{(appService.user | async)?.firstName}}</span>
				<mat-menu #menuLogin="matMenu">
					<mat-nav-list>
						<a mat-list-item [routerLink]="['/profile','account']" aria-label="profile" role="listitem">
							<p>Mi perfil</p>
						</a>
						<a mat-list-item [routerLink]="['/profile','addresses']" aria-label="addresses" role="listitem">
							<p>Mis direcciones</p>
						</a>
						<a mat-list-item [routerLink]="['/profile','orders']" aria-label="orders" role="listitem">
							<p>Mis ordenes</p>
						</a>
						<a mat-list-item (click)="authenticationService.logout()" (keydown)="authenticationService.logout()" dir="ltr" aria-label="login" role="listitem">
							Log out &nbsp;
							<mat-icon>logout</mat-icon>
						</a>
					</mat-nav-list>
				</mat-menu>
			</a>
		} @else {
			<a class="login-button-container app-pointer" aria-label="login" [matMenuTriggerFor]="menuLogin">
				<button mat-icon-button class="login-button">
					<mat-icon aria-label="Login" class="app-24">person</mat-icon>
				</button>
				<span *ngIf="!(isSmallGrid | async)?.matches" class="button-item app-12">Iniciar sesion / Registro</span>
				<mat-menu #menuLogin="matMenu">
					<mat-nav-list>
						<a mat-list-item (click)="openLogin()" (keydown)="openLogin()" aria-label="login" role="listitem">
							<p>Login</p>
						</a>
						<a mat-list-item (click)="openSignup()" (keydown)="openSignup()" aria-label="login" role="listitem">
							<p>Crear Cuenta</p>
						</a>
						<a mat-list-item (click)="openForgotPassword()" (keydown)="openForgotPassword()" aria-label="login" role="listitem">
							<p>Recuperar password</p>
						</a>
					</mat-nav-list>
				</mat-menu>
			</a>
		}
		<a *ngIf="(appService.cart | async) as cart" class="cart-button-container app-pointer" aria-label="cart">
			<button mat-icon-button class="cart-button" [routerLink]="['/cart']">
				<mat-icon aria-label="Cart" class="button-item app-24" [matBadge]="cart.itemsCount" matBadgeSize="large" matBadgePosition="above after">shopping_cart</mat-icon>
			</button>
			<span *ngIf="!(isSmallGrid | async)?.matches" class="button-item app-12">Carrito</span>
		</a>
	</mat-toolbar-row>
</mat-toolbar>
<div #searchFormField class="search-autocomplete" (focusout)="focusoutSearch()" [hidden]="true">
	<mat-form-field class="search-autocomplete-form-field">
		<input #searchInput type="search" placeholder="Busqueda" aria-label="search" matInput [formControl]="search" [matAutocomplete]="auto" (search)="navigateSearch($event)" appInputEmptyToNull>
		<mat-autocomplete #auto="matAutocomplete">
			@for (product of filteredProducts | async; track product) {
				<mat-option [value]="product.name" class="search-option" [routerLink]="['/products',product.slug]">
					<img class="search-option-item" [ngSrc]="product.pictures[0].small" [width]="30" [height]="30" [alt]="product.name">
					<div class="search-option-text">
						<p class="search-option-item">{{product.name}}</p>
						<span class="search-option-item search-option-highlights app-12">{{product.searchHighlights}}</span>
					</div>
				</mat-option>
			}
		</mat-autocomplete>
	</mat-form-field>
</div>
<mat-toolbar *ngIf="!(isSmallGrid | async)?.matches">
	<mat-toolbar-row>
		<mat-nav-list class="menu-toolbar">
			<a [matMenuTriggerFor]="menuProducts" mat-list-item [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:false}" role="button">
				<span>Productos</span>
				<mat-menu #menuProducts="matMenu">
					<mat-nav-list>
						<a mat-list-item [routerLink]="['/products']">
							<span class="app-14">Todos los productos</span>
						</a>
						@for (category of appService.productCategories | async; track category) {
							<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
								<span>{{category.label}}</span>
							</a>
						}
					</mat-nav-list>
				</mat-menu>
			</a>
			<mat-divider vertical class="menu-toolbar-divider"></mat-divider>
			<a mat-list-item [routerLink]="['/contact']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
				<span>Contacto</span>
			</a>
			<mat-divider vertical class="menu-toolbar-divider"></mat-divider>
			<a mat-list-item [routerLink]="['/envios']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
				<span>Politica de envios</span>
			</a>
			<mat-divider vertical class="menu-toolbar-divider"></mat-divider>
			<a mat-list-item [routerLink]="['/blog']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}">
				<span>Blog</span>
			</a>
		</mat-nav-list>
	</mat-toolbar-row>
</mat-toolbar>

