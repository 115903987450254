<div mat-dialog-title fxLayout="row">
	<h1 fxFlex>Recuperar contraseña</h1>
	<div fxFlex="nogrow">
		<mat-icon (click)="close()" class="app-pointer">cancel</mat-icon>
	</div>
</div>
<mat-dialog-content>
	<div fxLayout="column">
		<div fxFlex>
			<p>Introduce tu email:</p>
		</div>
		<div fxFlex class="form-container" fxFlexOffset="30px">
			<form [formGroup]="form" (ngSubmit)="passwordResetToken()" fxLayout="column">
				<mat-form-field fxFlex floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" benditaInputEmptyToNull required>
				</mat-form-field>
				<div fxFlex>
					<bendita-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></bendita-form-message>
				</div>
				<div fxFlex fxLayout="column" fxLayoutAlign="center center">
					<button fxFlex type="submit" mat-button class="app-primary">Enviar</button>
				</div>
			</form>
		</div>
		<div fxFlex fxLayout="column" fxFlexAlign="center" fxFlexOffset="30px">
			<p fxFlex fxFlexAlign="center">Nuevo cliente? <a [routerLink]="['/signup']" (click)="close(true)">Crear cuenta</a></p>
			<p fxFlex fxFlexAlign="center">Ya tienes una cuenta? <a [routerLink]="['/login']" (click)="close(true)">Entra aqui</a></p>
		</div>
	</div>
</mat-dialog-content>
