<mat-card *ngIf="(product|async) as product">
	<div fxLayout="column" fxLayoutGap="15px">
		<mat-chip-listbox fxFlex aria-label="Bread crumbs">
			<mat-chip class="app-pointer"><a [routerLink]="['/products']" class="no-decoration">Productos</a></mat-chip>
			<mat-icon fxFlexAlign="center">navigate_next</mat-icon>
			<mat-chip class="app-pointer"><a [routerLink]="['/products']" [queryParams]="{category:product.category.slug}" class="no-decoration">{{product.category.label}}</a></mat-chip>
			<mat-icon fxFlexAlign="center">navigate_next</mat-icon>
			<mat-chip color="primary" class="app-pointer"><b>{{product.name}}</b></mat-chip>
		</mat-chip-listbox>
		<mat-card fxFlex fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
			<div fxFlex="70">
				<gallery gallerize [items]="product.pictures.concat(product.videos)" [thumb]="true" thumbPosition="bottom" [dots]="false"></gallery>
			</div>
			<div fxFlex fxLayout="column" fxLayoutGap="15px">
				<div fxFlex="nogrow">
					<h1>{{product.name}}</h1>
					<mat-divider style="position: unset;"></mat-divider>
				</div>
				<div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="space-around start">
					<ng-container *ngIf="product.discountPercent;else noDiscountTemplate">
						<h2 fxFlex matBadge="Ahorra: {{product.discountPercent}}%" [matBadgeHidden]="!product.discountPercent" matBadgeColor="warn" matBadgePosition="above after" class="stretch-badge product-price-badge">
							Precio: {{(product.price-(product.price*(product.discountPercent/100))).toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}
						</h2>
						<h3 fxFlex>Antes: <span class="discount-chip">{{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</span></h3>
					</ng-container>
					<ng-template #noDiscountTemplate>
						<h2 fxFlex>Precio: {{product.price.toLocaleString("en-US",{style:"currency",currency:"USD",minimumFractionDigits:0,maximumFractionDigits:0})}}</h2>
					</ng-template>
					<p fxFlex>Impuestos incluidos. <a [routerLink]="['/envios']">Gastos de envío</a> calculados en la caja</p>
					<p *ngIf="product.quantity<40" fxFlex><b>{{product.quantity}}</b> unidad{{product.quantity===1 ? '' : 'es'}} disponible{{product.quantity===1 ? '' : 's'}}</p>
					<p *ngIf="product.quantity<20" fxFlex class="app-warn-color"><b>Pocas unidade</b></p>
				</div>
				<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center">
					<div fxFlex="nogrow" style="text-align: center;">Cantidad:</div>
					<mat-form-field fxFlex="nogrow" class="quantity-form-field" fxFlexOffset="5px">
						<input #productQuantity matInput type="number" min="1" value="1" benditaInputGreaterThan="1" [max]="product.quantity" benditaInputLessThan="{{product.quantity}}" aria-label="Quantity">
						<button mat-icon-button matSuffix (click)="productQuantity.stepUp(1)" aria-label="Increase quantity">
							<mat-icon>add</mat-icon>
						</button>
						<button mat-icon-button matPrefix (click)="productQuantity.stepDown(1)" aria-label="Decrease quantity">
							<mat-icon>remove</mat-icon>
						</button>
					</mat-form-field>
				</div>
				<button fxFlex="nogrow" mat-raised-button color="primary" class="buy-button" (click)="createCartItem(productQuantity.value)">Agregar al carrito</button>
			</div>
		</mat-card>
		<div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
			<mat-card fxFlex="60" fxFlex.lt-md>
				<div fxFlex [innerHtml]="product.descriptionSanitized" class="ck-content"></div>
			</mat-card>
			<mat-card fxFlex>
				<mat-card-header style="justify-content: space-between">
					<mat-icon>lock</mat-icon>
					<mat-card-title>
						<h2>Pagos y seguridad</h2>
					</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					Su información de pago se procesa de forma segura. No almacenamos los detalles de la tarjeta de crédito ni tenemos acceso a la información de su tarjeta de crédito.
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</mat-card>
