<div mat-dialog-title fxLayout="row">
	<h1 fxFlex>Tu contraseña ha sido cambiada</h1>
	<div fxFlex="nogrow">
		<mat-icon (click)="close()" class="app-pointer">cancel</mat-icon>
	</div>
</div>
<div mat-dialog-content>
	<div fxLayout="column">
		<p fxFlex>Ingresa tu nueva contraseña al hacer login.</p>
	</div>
</div>
<div mat-dialog-actions align="end">
	<button mat-button matRipple class="app-primary" (click)="close()">
		Aceptar
	</button>
</div>