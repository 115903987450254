@if (serverErrorInput; as errors) {
	<div class="form-message-text error">
		@if (serverErrorInput.graphQLErrors; as errors) {
			@if (errors.slice()?.shift()?.extensions?.response; as response) {
				Error: {{response.message?.toString()}}
			} @else {
				Error: {{errors.slice()?.shift().message}}
			}
		} @else if (serverErrorInput?.error) {
			@if (serverErrorInput?.error?.message) {
				Error: {{serverErrorInput?.error?.message}}
			} @else {
				{{serverErrorInput?.error.toString()}}
			}
		} @else {
			{{serverErrorInput?.toString()}}
		}
	</div>
}
@if (serverResponseInput) {
	<div class="form-message-text center">
		{{serverResponseInput?.toString()}}
	</div>
}
