import {Component} from '@angular/core';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {SnackMessageComponent} from '../../shared/snack-message/snack-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppService} from '../../app.service';
import {ContactService} from '../contact.service';
import {CreatePqrRequestRequestDto} from '../dto/create-pqr-request-request.dto';
import {Auth,RecaptchaVerifier} from '@angular/fire/auth';
import {AppCheck,getToken} from '@angular/fire/app-check';
import {AppCheckTokenResult} from '@firebase/app-check';

@Component({
	selector:'bendita-contact-address-form',
	templateUrl:'./contact-address-form.component.html',
	styleUrls:['./contact-address-form.component.scss']
})
export class ContactAddressFormComponent{
	private matSnackBar:MatSnackBar;
	appService:AppService;
	formBuilder:FormBuilder;
	form:FormGroup;
	private readonly contactService:ContactService;
	submitButtonActive:boolean;
	recaptchaVerifier?:RecaptchaVerifier;
	auth:Auth;
	appCheck:AppCheck;
	
	constructor(
		formBuilder:FormBuilder,
		appService:AppService,
		matSnackBar:MatSnackBar,
		contactService:ContactService,
		auth:Auth,
		appCheck:AppCheck
	){
		this.appService=appService;
		this.contactService=contactService;
		this.matSnackBar=matSnackBar;
		this.formBuilder=formBuilder;
		this.auth=auth;
		this.auth.languageCode='es';
		this.appCheck=appCheck;
		console.log('this.appCheck',this.appCheck);
		this.form=this.formBuilder.group({
			name:[null,Validators.required],
			city:[null,Validators.required],
			phone:[null,Validators.required],
			email:[null,Validators.required],
			message:[null,Validators.required]
		});
		this.submitButtonActive=true;
		
	}
	
	onSubmit():void{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				
				getToken(this.appCheck,true)
				
				// let options:any={size:'invisible'};
				// // let options:any={sitekey:environment.google.recaptcha3SiteKey};
				// this.recaptchaVerifier=new RecaptchaVerifier('recaptcha-container',options,this.auth);
				// console.log('this.recaptchaVerifier',this.recaptchaVerifier)
				// this.recaptchaVerifier.verify()
				
				.then((res:AppCheckTokenResult):void=>{
					this.submitButtonActive=false;
					let data:CreatePqrRequestRequestDto={
						...this.form.getRawValue(),
						recaptchaToken:res.token
					};
					this.contactService.createPqrRequest(data)
					.subscribe({
						next:():void=>{
							this.submitButtonActive=true;
							this.recaptchaVerifier?.clear();
							this.recaptchaVerifier=undefined;
							this.form.disable();
							this.matSnackBar.openFromComponent(SnackMessageComponent,{
								data:{
									serverResponseInput:'Tu mensaje ha sido enviado con éxito'
								}
							});
						},
						error:(error:any):void=>{
							this.submitButtonActive=true;
							this.recaptchaVerifier?.clear();
							this.recaptchaVerifier=undefined;
							this.matSnackBar.openFromComponent(SnackMessageComponent,{
								data:{
									serverErrorInput:error
								}
							});
						}
					});
				})
				.catch(this.appService.window.alert);
			}
		}
	}
	
}
