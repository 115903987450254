import {CanActivateFn,GuardResult} from '@angular/router';
import {CanMatchFn,Router} from '@angular/router';
import {CanActivateChildFn} from '@angular/router';
import {inject} from '@angular/core';
import {filter,firstValueFrom,map} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {toObservable} from '@angular/core/rxjs-interop';

export const authenticationGuardActivate:CanActivateFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user)=>user!==undefined),
		map((user)=>user ? router.parseUrl('/profile') : true)
	));
};

export const authenticationGuardMatch:CanMatchFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user)=>user!==undefined),
		map((user)=>user ? router.parseUrl('/profile') : true)
	));
};

export const authenticationGuardActivateChild:CanActivateChildFn=async():Promise<GuardResult>=>{
	return true;
};
