import {ChangeDetectorRef,Component,Inject,OnInit,PLATFORM_ID} from '@angular/core';
import {tap} from 'rxjs';
import {Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {AppService} from '../app.service';
import {ActivatedRoute,Router,UrlSegment} from '@angular/router';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {LoginPopupComponent} from '../authentication/login-popup/login-popup.component';
import {ForgotPasswordPopupComponent} from '../authentication/forgot-password-popup/forgot-password-popup.component';
import {ComponentType} from '@angular/cdk/overlay';
import {SignupPopupComponent} from '../authentication/signup-popup/signup-popup.component';
import {Gallery,GalleryConfig,GalleryItem,ImageItem,VideoItem} from 'ng-gallery';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';

@Component({
	selector:'bendita-index',
	templateUrl:'./index.component.html',
	styleUrls:['./index.component.scss']
})
export class IndexComponent implements OnInit{
	private activatedRoute:ActivatedRoute;
	private matDialog:MatDialog;
	private router:Router;
	private readonly changeDetectorRef:ChangeDetectorRef;
	appService:AppService;
	title:string;
	pictures:GalleryItem[]&VideoItem[];
	private gallery:Gallery;
	private readonly platformId:any;
	environment:any;
	
	constructor(
		activatedRoute:ActivatedRoute,
		matDialog:MatDialog,
		router:Router,
		appService:AppService,
		changeDetectorRef:ChangeDetectorRef,
		gallery:Gallery,
		@Inject(PLATFORM_ID) platformId:any
	){
		this.activatedRoute=activatedRoute;
		this.matDialog=matDialog;
		this.router=router;
		this.appService=appService;
		this.environment=environment;
		this.changeDetectorRef=changeDetectorRef;
		this.gallery=gallery;
		this.title='Bendita';
		this.pictures=[
			// {
			// 	type: 'customImage',
			// 	data: {
			// 		src:'/assets/images/home4.png',
			// 		thumb:'/assets/images/home4.png'
			// 	}
			// },
			// {
			// 	type: 'customImage',
			// 	data: {
			// 		src:'/assets/images/home1.png',
			// 		thumb:'/assets/images/home1.png'
			// 	}
			// },
			// {
			// 	type: 'customImage',
			// 	data: {
			// 		src:'/assets/images/home2.png',
			// 		thumb:'/assets/images/home2.png'
			// 	}
			// },
			// {
			// 	type: 'customImage',
			// 	data: {
			// 		src:'/assets/images/home3.png',
			// 		thumb:'/assets/images/home3.png'
			// 	}
			// },
			// {
			// 	type: 'customVideo',
			// 	data: {
			// 		src:'/assets/videos/home1.mp4',
			// 		type:'video/mp4',
			// 		poster:'/assets/videos/home1.png',
			// 		controls:true,
			// 		mute:true,
			// 		autoplay:true,
			// 		loop:true,
			// 		// @ts-ignore
			// 		controlsList:'nodownload nofullscreen noremoteplayback noplaybackrate'
			// 	}
			// },
			new ImageItem({
				src:'/assets/images/home4.png',
				thumb:'/assets/images/home4.png'
			}),
			new ImageItem({
				src:'/assets/images/home1.png',
				thumb:'/assets/images/home1.png'
			}),
			new ImageItem({
				src:'/assets/images/home2.png',
				thumb:'/assets/images/home2.png'
			}),
			new ImageItem({
				src:'/assets/images/home3.png',
				thumb:'/assets/images/home3.png'
			}),
			// new VideoItem({
			// 	src:'/assets/videos/home1.mp4',
			// 	type:'video/mp4',
			// 	poster:'/assets/videos/home1.png',
			// 	controls:true,
			// 	mute:true,
			// 	autoplay:true,
			// 	loop:true,
			// 	// @ts-ignore
			// 	controlsList:'nodownload nofullscreen noremoteplayback noplaybackrate'
			// })
		];
		this.platformId=platformId;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
		
		this.appService.breakpointObserver.observe(Breakpoints.Handset).pipe(
			tap((state:BreakpointState):void=>{
				console.log(state);
			})
		);
		
		if(isPlatformBrowser(this.platformId)) this.activatedRoute.url.pipe(
			tap((urlSegments:UrlSegment[]):void=>{
				let component:ComponentType<any>|undefined;
				switch(urlSegments[0]?.path){
					case('login'):{
						component=LoginPopupComponent;
						break;
					}
					case('signup'):{
						component=SignupPopupComponent;
						break;
					}
					case('forgot-password'):{
						component=ForgotPasswordPopupComponent;
						break;
					}
				}
				if(component){
					let dialogRef:MatDialogRef<(ComponentType<any>)>=this.matDialog.open(
						component,
						{
							panelClass:'dialog-responsive',
							height:'90%',
							data:{}
						}
					);
					dialogRef.afterClosed().subscribe((data:any):void=>{
						if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
						this.changeDetectorRef.detectChanges();
					});
				}
			})
		).subscribe();
		
		const config: GalleryConfig = {
			loop:true,
			thumb:false,
			autoPlay:true,
			playerInterval:10*1000,
			counter:false,
			dots:true
		};
		// const galleryRef = this.gallery.ref('homeGallery');
		// galleryRef.setConfig(config);
		this.gallery.ref('homeGallery', config).load(this.pictures);
	}
	
	ngOnViewInit():void{
		const config: GalleryConfig = {
			loop:true,
			thumb:false,
			autoPlay:true,
			playerInterval:10*1000,
			counter:false,
			dots:true
		};
		// const galleryRef = this.gallery.ref('homeGallery');
		// galleryRef.setConfig(config);
		this.gallery.ref('homeGallery', config).load(this.pictures);
		
	}
	
	
}
