import {Component,OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {environment} from '../../environments/environment';

@Component({
	selector:'bendita-contact',
	templateUrl:'./contact.component.html',
	styleUrls:['./contact.component.scss']
})
export class ContactComponent implements OnInit{
	appService:AppService;
	title:string;
	mapOptions:google.maps.MapOptions;
	environment:any;
	
	constructor(appService:AppService){
		this.appService=appService;
		this.title='Bendita - Contacto';
		this.mapOptions={
			clickableIcons:false,
			gestureHandling:'none',
			keyboardShortcuts:false,
			streetViewControl:false,
			fullscreenControl:false,
			center:{lat:4.439474,lng:-75.217536},
			zoom:20
		};
		this.environment=environment;
		
	}
	
	ngOnInit():void{
		this.appService.window.scrollTo(0,0);
		this.appService.updateSeo(
			this.title,
			'Plantas & Semillas Abierto las 24 horas',
			'bendita, grow, shop, semillas, plantas',
			'https://www.benditagrow.com/assets/icons/icon-512x512.png'
		);
	}
	
}
