<mat-card>
	<mat-card-header>
		<mat-card-title>
			<h1>Política de envío</h1>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div fxLayout="column" fxLayoutGap="15px" class="policy-content">
			<div fxFlex>
				<p>ENVÍOS LOCALES</p>
			</div>
			<div fxFlex>
				<p>Entregamos el mismo día si realizaste tu pedido antes de las 4:00 pm, dándole prioridad al orden de llegada de los pedidos pagos.</p>
			</div>
			<div fxFlex>
				<p>Por compras superiores de $30.000 los domicilios en Medellín son gratis hasta los 10km de recorrido desde la tienda física hasta el destino, por compras de valor inferior el valor es de $5.000.</p>
			</div>
			<div fxFlex>
				<p>Si su ubicación supera los 10km tiene un costo de $5.000. Si su ubicación supera los 12km el costo del domicilio varia entre $10.000 y $12.000 por lo que recomendamos solicitar el envío intermunicipal que tiene una duración de 1 a 2 días hábiles en llegar.</p>
			</div>
			<div fxFlex>
				<p>Si usted necesita que su pedido sea entregado de manera inmediata puede solicitarlo en el momento de la compra o comunicandose a línea de atención al cliente +{{appService.phoneNumberUtil.parse(environment.contact.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(environment.contact.phone).getNationalNumber()}}, este se le enviará bajo su responsabilidad por medio de App de envios como Picap o Rappi.</p>
			</div>
		</div>
	</mat-card-content>
</mat-card>
