import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ContactRoutingModule} from './contact-routing.module';
import {ContactComponent} from './contact.component';
import {SharedModule} from '../shared/shared.module';
import {ContactAddressFormComponent} from './contact-address-form/contact-address-form.component';

@NgModule({
	declarations:[ContactComponent,ContactAddressFormComponent],
	imports:[
		CommonModule,
		ContactRoutingModule,
		SharedModule
	]
})
export class ContactModule{}
