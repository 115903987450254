import {afterNextRender,Component,DestroyRef,ElementRef,inject,input,viewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LoginPopupComponent} from '../../../authentication/login-popup/login-popup.component';
import {SignupPopupComponent} from '../../../authentication/signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from '../../../authentication/forgot-password-popup/forgot-password-popup.component';
import {debounceTime,distinctUntilChanged,of,skip,switchMap} from 'rxjs';
import {BreakpointObserver,Breakpoints} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {InputEmptyToNullDirective} from '../../directives/input-empty-to-null/input-empty-to-null.directive';
import {FormControl} from '@angular/forms';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';
import {SearchService} from '../../../search/search.service';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {GetProductsSortFactor} from '../../../common/graphql/types.generated';

@Component({
	selector:'app-header',
	imports:[
		SharedModule,
		InputEmptyToNullDirective
	],
	templateUrl:'./header.component.html',
	styleUrl:'./header.component.scss'
})
export class HeaderComponent{
	drawer=input.required<MatDrawer>();
	private matDialog=inject(MatDialog);
	private router=inject(Router);
	destroyRef=inject(DestroyRef);
	breakpointObserver=inject(BreakpointObserver);
	appService=inject(AppService);
	searchService=inject(SearchService);
	authenticationService=inject(AuthenticationService);
	searchContainer=viewChild<ElementRef>('searchContainer');
	searchInputHeader=viewChild<ElementRef>('searchInputHeader');
	searchAutocompleteHeaderTrigger=viewChild<MatAutocompleteTrigger>('searchAutocompleteHeaderTrigger');
	isXSmallWindow=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	search=new FormControl<string|null>(null);
	filteredProducts=this.search.valueChanges.pipe(
		debounceTime(400),
		distinctUntilChanged(),
		switchMap((value)=>{
			return value ? this.searchService.searchProducts({
				limit:3,
				skip:0,
				sortFactor:GetProductsSortFactor.CreatedAt,
				sortOrder:1,
				filter:{
					discount:false,
					freeShipping:false
				},
				query:value
			}) : of([]);
		})
	);
	
	constructor(){
		afterNextRender(()=>{
			this.router.events.pipe(skip(1)).subscribe(()=>{
				this.searchAutocompleteHeaderTrigger()?.closePanel();
				const searchContainer=this.searchContainer();
				if(searchContainer) searchContainer.nativeElement.hidden=true;
			});
		});
		
	}
	
	openLogin():void{
		const dialogRef=this.matDialog.open(LoginPopupComponent);
		dialogRef.afterClosed().subscribe((data)=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openSignup():void{
		const dialogRef=this.matDialog.open(SignupPopupComponent);
		dialogRef.afterClosed().subscribe((data)=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openForgotPassword():void{
		const dialogRef=this.matDialog.open(ForgotPasswordPopupComponent);
		dialogRef.afterClosed().subscribe((data)=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	toggleSearch(){
		const searchContainer=this.searchContainer();
		if(searchContainer){
			if(searchContainer.nativeElement.hidden){
				searchContainer.nativeElement.hidden=false;
				setTimeout(()=>this.searchInputHeader()?.nativeElement.focus(),200);
			}else{
				searchContainer.nativeElement.hidden=true;
			}
		}
	}
	
	focusoutSearch(){
		setTimeout(()=>{
			const searchContainer=this.searchContainer();
			if(searchContainer) searchContainer.nativeElement.hidden=true;
		},200);
	}
	
	navigateSearch(query?:string){
		if(query) this.router.navigate(['/search',query]);
	}
	
}
