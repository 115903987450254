import {NgModule} from '@angular/core';
import {CommonModule,DatePipe,NgForOf,NgIf,NgSwitchCase,NgTemplateOutlet} from '@angular/common';

import {TrackingRoutingModule} from './tracking-routing.module';
import {TrackingComponent} from './tracking.component';
import {SharedModule} from '../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {FlexModule} from '@angular/flex-layout';


@NgModule({
	declarations:[
		TrackingComponent
	],
	imports:[
		CommonModule,
		TrackingRoutingModule,
		SharedModule,
		MatButtonModule,
		MatCardModule,
		MatIconModule,
		DatePipe,
		FlexModule,
		MatCardModule,
		NgForOf,
		NgIf,
		NgSwitchCase,
		NgTemplateOutlet,
		DatePipe,
		FlexModule,
		NgIf,
		FlexModule,
		NgIf,
		NgIf
	]
})
export class TrackingModule{}
