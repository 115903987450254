import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {TrackingComponent} from './tracking.component';

const routes:Routes=[
	{path:':id',component:TrackingComponent},
	{path:'',redirectTo:'/error',pathMatch:'full'}
];

@NgModule({
	imports:[RouterModule.forChild(routes)],
	exports:[RouterModule]
})
export class TrackingRoutingModule{}
