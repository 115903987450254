import {Component,Input,OnInit} from '@angular/core';
import { SHIPPING_TYPE } from 'src/app/common/enums/shipping-type.enum';
import {AppService} from '../../app.service';
import {ORDER_STATUS} from '../../common/enums/order-status.enum';

@Component({
	selector:'bendita-order-status-tracker',
	templateUrl:'./order-status-tracker.component.html',
	styleUrls:['./order-status-tracker.component.scss']
})
export class OrderStatusTrackerComponent implements OnInit{
	ORDER_STATUS:typeof ORDER_STATUS;
	@Input() statusInput?:string;
	@Input() shippingTypeInput?:string;
	appService:AppService;
	orderStatuses?:any[];
	
	constructor(
		appService:AppService
	){
		this.ORDER_STATUS=ORDER_STATUS;
		this.appService=appService;
		this.orderStatuses=undefined;
		
	}
	
	ngOnInit():void{
		this.getOrderStatuses();
	}
	
	getIconActive(order:number):boolean{
		let currentStatus:any=this.orderStatuses?.find((element:any):boolean=>element.id===this.statusInput);
		if(!currentStatus){
			return false;
		}else{
			return currentStatus.order>=order;
		}
	}
	
	getOrderStatuses():void{
		this.appService.getOrderStatuses()
		.subscribe((result:any):void=>{
			if(result.data?.getOrderStatuses){
				if(this.statusInput===ORDER_STATUS.Cancelled){
					this.orderStatuses=result.data.getOrderStatuses.filter((element:any):boolean=>element.id===ORDER_STATUS.Cancelled);
				}else{
					this.orderStatuses=result.data.getOrderStatuses.filter((element:any):boolean=>element.id!==ORDER_STATUS.Cancelled);
					if(this.shippingTypeInput===SHIPPING_TYPE.Delivery){
						this.orderStatuses=this.orderStatuses?.filter((element:any):boolean=>element.id!==ORDER_STATUS.AwaitingPickUp);
					}else{
						this.orderStatuses=this.orderStatuses?.filter((element:any):boolean=>element.id!==ORDER_STATUS.Shipped);
					}
				}
			}
		});
	}
	
}
