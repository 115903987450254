<ng-container *ngIf="shippingTypeInput && statusInput && orderStatuses">
	<div fxFlex fxLayout="row" fxLayoutAlign="start" style="overflow: auto;text-align:center;padding: 15px;" fxLayoutGap="15px">
		<ng-container *ngIf="statusInput===ORDER_STATUS.Cancelled;else notCancelledTemplate">
			<div fxFlex fxFlexAlign="stretch" fxLayout="column" class="status-box app-warn-light">
				<mat-icon fxFlex="nogrow" fxFlexAlign="center" class="app-24" color="warn">{{orderStatuses[0]?.icon}}</mat-icon>
				<h2 fxFlex="nogrow" fxFlexAlign="center" class="app-warn-color">{{orderStatuses[0]?.label}}</h2>
				<span fxFlex="nogrow" fxFlexAlign="center">{{orderStatuses[0]?.description}}</span>
			</div>
		</ng-container>
		<ng-template #notCancelledTemplate>
			<ng-container *ngFor="let status of orderStatuses; let index = index">
				<ng-container *ngIf="index>0">
					<mat-icon fxFlex="nogrow" fxFlexAlign="center" class="app-24" [color]="getIconActive(status.order) ? 'primary' : undefined">double_arrow</mat-icon>
				</ng-container>
				<div fxFlex fxFlexAlign="stretch" fxLayout="column" fxLayoutAlign="center center" class="status-box app-accent mat-elevation-z1" [ngClass]="{'order-status-primary-light':getIconActive(status.order),'mat-elevation-z8':status.id===statusInput}">
					<mat-icon fxFlex="nogrow" fxFlexAlign="center" class="app-24">{{status.icon}}</mat-icon>
					<h3 fxFlex="nogrow" fxFlexAlign="center" [ngStyle]="{'font-weight':status.id===statusInput ? 'bolder' : undefined,'text-decoration':status.id===statusInput ? 'underline' : undefined}">{{status.label}}</h3>
				</div>
			</ng-container>
		</ng-template>
		
	</div>
</ng-container>
