import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from '@angular/common/http';
import {Apollo,gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {UpdateUserRequestDto} from './dto/update-user-request.dto';
import {UpdatePasswordRequestDto} from './dto/update-password-request.dto';
import {CreateAddressShippingRequestDto} from './dto/create-address-shipping-request.dto';
import {GetAddressesShippingRequestDto} from './dto/get-addresses-shipping-request.dto';
import {UpdateAddressShippingRequestDto} from './dto/update-address-shipping-request.dto';
import {DeleteItemRequestDto} from '../common/dto/delete-item-request.dto';
import {GetItemsRequestDto} from '../common/dto/get-items-request.dto';
import {AddPhoneRequestDto} from './dto/add-phone-request.dto';
import {GetOrderRequestDto} from './dto/get-order-request.dto';
import {map,take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {GetOrderEventsRequestDto} from '../tracking/dto/get-order-events-request.dto';
import {GetItemsByIdRequestDto} from '../common/dto/get-items-by-id-request.dto';

@Injectable({
	providedIn:'root'
})
export class ProfileService{
	private httpClient:HttpClient;
	private readonly apollo:Apollo;
	
	constructor(httpClient:HttpClient,apollo:Apollo){
		this.httpClient=httpClient;
		this.apollo=apollo;
		
	}
	
	updateUser(variables:UpdateUserRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation updateUser(
              $lastName: String,
              $firstName: String
          ){
              updateUser(
                  lastName: $lastName
                  firstName: $firstName
              ) {
                  updatedAt
                  id
                  createdAt
                  email
                  firstName
                  lastName
                  password
                  role {
                      id
                      label
                  }
                  phone
              }
          }
			`
		});
	}
	
	updatePassword(data:UpdatePasswordRequestDto):Observable<any>{
		return this.httpClient.put(`${environment.apiServer.url}/api/authentication/password`,data,{withCredentials:true});
	}
	
	createAddress(variables:CreateAddressShippingRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation createAddress(
              $city: String!
              $country: String!
              $name: String!
              $line1: String!
              $line2: String
              $location: LocationInput!
              $phone: String!
              $state: String!
              $zip: String!
          ){
              createAddress(
                  city: $city
                  country: $country
                  name: $name
                  line1: $line1
                  line2: $line2
                  location: $location
                  phone: $phone
                  state: $state
                  zip: $zip
              ) {
                  updatedAt
                  id
                  createdAt
                  zip
                  state
                  phone
                  location {
                      coordinates
                      type
                  }
                  line2
                  line1
                  name
                  country
                  city
              }
          }
			`
		});
	}
	
	getAddressesShipping(variables:GetAddressesShippingRequestDto):Observable<any[]>{
		return this.apollo
		.query({
			variables,
			query:gql`query getAddressesShipping(
          $limit: Float!
          $skip: Float!
      ){
          getAddressesShipping (
              limit: $limit
              skip: $skip
          ){
              updatedAt
              id
              createdAt
              zip
              state
              phone
              location {
                  coordinates
                  type
              }
              line2
              line1
              name
              country
              city
          }
      }`
		}).pipe(map((result:any):any[]=>result.data?.getAddressesShipping));
	}
	
	updateAddress(variables:UpdateAddressShippingRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation updateAddress(
              $id: String!
              $city: String!
              $country: String!
              $name: String!
              $line1: String!
              $line2: String
              $location: LocationInput!
              $phone: String!
              $state: String!
              $zip: String!
          ){
              updateAddress(
                  id: $id
                  city: $city
                  country: $country
                  name: $name
                  line1: $line1
                  line2: $line2
                  location: $location
                  phone: $phone
                  state: $state
                  zip: $zip
              ) {
                  updatedAt
                  id
                  createdAt
                  zip
                  state
                  phone
                  location {
                      coordinates
                      type
                  }
                  line2
                  line1
                  name
                  country
                  city
              }
          }
			`
		});
	}
	
	deleteAddress(variables:DeleteItemRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation deleteAddress(
              $id: String!
          ){
              deleteAddress(
                  id: $id
              ) {
                  updatedAt
                  id
                  createdAt
                  zip
                  state
                  phone
                  location {
                      coordinates
                      type
                  }
                  line2
                  line1
                  name
                  country
                  city
              }
          }
			`
		});
	}
	
	getOrders(variables:GetItemsRequestDto):Observable<any[]>{
		return this.apollo
		.query({
			variables,
			query:gql`
          query getOrders(
              $skip: Float!
              $limit: Float!
          ){
              getOrders(
                  skip: $skip
                  limit: $limit
              ) {
                  id
                  createdAt
                  slug
                  status {
                      id
                      label
                      icon
                  }
                  shippingType {
                      label
                      id
                      icon
                  }
                  total
                  items {
                      quantity
                      product {
                          picture
                      }
                  }
              }
          }
			`
		}).pipe(map((result:any):any[]=>result.data?.getOrders));
	}
	
	getOrder(variables:GetOrderRequestDto):Observable<any>{
		return this.apollo
		.query({
			variables,
			query:gql`query getOrder($id:String!){
          getOrder(id:$id){
              updatedAt
              id
              createdAt
              slug
              status {
                  id
                  label
                  description
                  icon
                  order
              }
              paymentType {
                  id
                  label
                  icon
                  description
                  descriptionLong
                  paymentAccount
              }
              shippingType {
                  label
                  id
                  icon
              }
              email
              name
              shippingAddress {
                  name
                  city
                  country
                  line2
                  phone
                  state
                  zip
                  line1
              }
              subtotal
              discount
              shipping
              total
              items {
                  product {
                      name
                      id
                      picture
                      discountPercent
                      price
                  }
                  quantity
              }
              user {
                  id
              }
              phone
              proofOfPayment
              store {
                  id
                  city
                  country
                  createdAt
                  line1
                  line2
                  location {
                      coordinates
                  }
                  name
                  phone
                  placeId
                  state
                  updatedAt
                  zip
              }
              transactionId
              openNodeCharge {
                  address
                  amount
                  auto_settle
                  chain_invoice {
                      address
                      settled_at
                      tx
                  }
                  created_at
                  currency
                  description
                  expires_at
                  fiat_value
                  id
                  metadata {
                      name
                      email
                      invoice_id
                  }
                  missing_amt
                  order_id
                  source_fiat_value
                  status
                  transactions {
                      status
                      created_at
                      tx
                      settled_at
                      address
                      amount
                  }
                  fee
              }
              mercadoPagoPayment {
                  id
                  status
                  description
                  status_detail
                  payer {
                      id
                      address {
                          street_name
                          street_number
                          zip_code
                      }
                      email
                      entity_type
                      first_name
                      identification {
                          number
                          type
                      }
                      last_name
                      phone {
                          number
                          area_code
                          extension
                      }
                  }
                  captured
                  card {
                      id
                      cardholder {
                          identification {
                              number
                              type
                          }
                          name
                      }
                      expiration_month
                      expiration_year
                      last_four_digits
                  }
                  currency_id
                  net_amount
                  payment_method {
                      type
                      id

                  }
                  transaction_details {
                      installment_amount
                      net_received_amount
                      overpaid_amount
                      total_paid_amount
                      transaction_id

                  }
              }
              paypalOrder {
                  status
                  id
                  intent
                  payer {
                      name {
                          given_name
                          surname
                      }
                      email_address
                      payer_id
                  }
                  payment_source {
                      paypal {
                          email_address
                          name {
                              surname
                              given_name
                          }
                          account_id
                          account_status
                      }
                  }
              }
              mercadoPagoPreference {
                  id
                  description
                  date_created
                  date_of_expiration
                  purpose

              }
              guestToken
          }
      }`
		}).pipe(map((result:any):any=>result.data?.getOrder));
	}
	
	createEmailVerificationToken(email:string):Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/email-verification-token`,{email},{withCredentials:true});
	}
	
	addPhone(data:AddPhoneRequestDto):Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/add-phone`,data,{withCredentials:true});
	}
	
	createProofOfPayment(id:string,file:File):Observable<any>{
		const data:FormData=new FormData();
		data.append('file',file);
		data.append('id',id);
		return this.httpClient.post(`${environment.apiServer.url}/api/order/proof-of-payment`,data,{withCredentials:true});
	}
	
	getOrderEvents(variables:GetItemsByIdRequestDto):Observable<any[]>{
		return this.apollo
		.query({
			variables,
			query:gql`query getOrderEvents(
          $id: String!
          $limit: Float!
          $skip: Float!
      ){
          getOrderEvents (
              id: $id
              limit: $limit
              skip: $skip
          ){
              updatedAt
              id
              createdAt
              description
          }
      }`
		}).pipe(map((result:any):any=>result.data?.getOrderEvents));
	}
	
	getEmailVerificationToken():Observable<any>{
		return this.httpClient.get(`${environment.apiServer.url}/api/authentication/email-verification-token`,{withCredentials:true}).pipe(take(1));
	}
	
	deleteUser():Observable<any>{
		return this.httpClient.delete(`${environment.apiServer.url}/api/authentication/user`,{withCredentials:true});
	}
	
	getPersonalFile():Observable<any>{
		return this.httpClient.get(`${environment.apiServer.url}/api/authentication/personal-file`,{withCredentials:true}).pipe(take(1));
	}
	
	createPersonalFile():Observable<any>{
		return this.httpClient.post(`${environment.apiServer.url}/api/authentication/personal-file`,{},{withCredentials:true}).pipe(take(1));
	}
	
	getTokenOrderPickUp(data:GetOrderRequestDto):Observable<any>{
		return this.httpClient.get(`${environment.apiServer.url}/api/authentication/token-order-pick-up?id=${data.id}`,{withCredentials:true}).pipe(take(1));
	}
	
	
}
